import React, { useState, useEffect } from "react";

import { Divider, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';


import { ReactComponent as PlusIcon } from "../assets/images/icons/plus-circle.svg";
import { ReactComponent as MinusIcon } from "../assets/images/icons/minus-circle.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as HeartIcon } from "../assets/images/icons/heart.svg";
import { ReactComponent as ShareIcon } from "../assets/images/icons/share.svg";
import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/mobilecart.svg";
import TopCircle from "../components/top-circle";
import BodyCircle from "../components/body-circle";
import NavDisplay from "../components/nav";

import Image1 from "../assets/images/ewedu.png";
import TopCard from "../components/top-card";
import FooterDisplay from "../components/footer";
import AllProducts from "../components/products";

const ProductDetailPage = () => {

    const Navigate = useNavigate();
    const [cartQuantity, setCartQuantity] = useState(1);
    const [fixedNav, setFixed] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            }
            else {
                setFixed(false);
            }
        })
    }, [])

    return (
        <div className="homepage product-detail">
            <div className="display-main-conten ">
                <div className="product-image">
                    <img src={Image1} alt="ewedu" />
                    <div className={`top-bar ${fixedNav ? 'fixed' : ''} contain`}>
                        <div className="detail-action-button left">
                            <div
                                onClick={() => Navigate(-1)}
                                className="detail-action-cover">
                                <ArrowLeftIcon />
                            </div>
                        </div>
                        <div className="detail-action-button right">
                            <div className="grid-flex">
                                <div className="detail-action-cover">
                                    <ShareIcon className="medium" />
                                </div>
                                <div className="detail-action-cover">
                                    <HeartIcon className="small" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body-content contain">
                    <div className="product-name-detail">
                        <div>
                            <h3>Beetroot Red (Local Shop)</h3>
                            <p className="unimportant">500gm</p>
                        </div>
                        <div>
                            <div className="fav-img">
                                <HeartIcon />
                            </div>
                        </div>
                    </div>
                    <div className="product-name-detail pricing">
                        <div>
                            <h3><span className="currency">NGN</span>15,000.00</h3>
                        </div>
                        <div>
                            <p><ion-icon name="pricetags-outline"></ion-icon> Available on fast delivery</p>
                        </div>
                    </div>
                    <Divider />
                    {/* <div className="product-name-detail pricing">
                        <div className="detail-cover">
                            <div className="detail-icon-cover _1">
                                <ion-icon name="body-outline"></ion-icon>
                            </div>
                            <div className="detail-icon-cover _2">
                                <ion-icon name="bicycle-outline"></ion-icon>
                            </div>
                            <div className="detail-icon-cover _3">
                                <ion-icon name="car-outline"></ion-icon>
                            </div>
                        </div>
                        <div>
                            <p><ion-icon name="star-half-outline"></ion-icon> 4.5 Rating</p>
                        </div>
                    </div>
                    <Divider /> */}
                    <div className="product-description">
                        <p>Yams are starchy tubers that can be used in a variety of ways. You can boil them, fry
                            them or mash them into porridge. You can also blend the yam into flour for use
                            in baking or making of Amala (a Nigerian swallow). Yams contain a unique compound
                            called diosgenin, which has been found to promote neuron growth and enhance brain
                            function. In essence, regularly eating yam helps to preserve and even enhance
                            your cognitive abilities.</p>
                    </div>
                    <div className="mt_4">
                        <button className="btn-base">Add Item to Cart</button>
                    </div>
                    <div className="mt_4">
                        <AllProducts />
                    </div>
                </div>
                <FooterDisplay />
            </div>
        </div>
    )
}

export default ProductDetailPage;