import React, { useState } from "react";

import { Link } from "react-router-dom";

import { ReactComponent as EyeIcon } from "../assets/images/icons/eye.svg";
import { ReactComponent as EyesShutIcon } from "../assets/images/icons/eye-off.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/images/icons/arrow-right-circle.svg";

const TopCircle = props => {
    // const [hideWalletBalance, setHideWalletBalance] = useState(false);

    // const changeWalletBalanceVisibility = () => setHideWalletBalance(!hideWalletBalance);

    return (
        <div className={`top-circle-cover
        ${props.displayWalletHistory ? "long-height" : "short"}
        ${props.short ? "short" : ""}`}>
            <div className="top-circle">
                <div className="top-display">
                    <div className="circle-header-icons">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopCircle;