import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";

import Diamonds from "../assets/images/illustrations/Diamonds.png";
import _404 from "../assets/images/illustrations/404.png";
import Calendar from "../assets/images/illustrations/Calendar.png";

import VendorImg from "../assets/images/signup/vendor.PNG";
import ExtraImg from "../assets/images/signup/extra.PNG";
import ShopImg from "../assets/images/signup/shop.PNG";


import { Link } from "react-router-dom";

const WelcomePage = () => {
    const blockLink1 = useRef();
    const [currentDisplay, setCurrentDisplay] = useState(0);
    useEffect(() => {
        let newIndex = +currentDisplay + 1;
        const displayInterval = setInterval(() => {
            setCurrentDisplay(newIndex); if (newIndex > 2) { setCurrentDisplay(0) } else { setCurrentDisplay(newIndex); }
        }, 5000)
        return () => clearInterval(displayInterval)
    }, [currentDisplay])
    return (<div className="welcome-display" >
        <div className="welcome-box" >
            <div className="welcome-central-message" >
                {currentDisplay === 0 ?
                    <div className="welcome-spacing" >
                        <div>
                            <img src={ShopImg} className="signup_img" alt="vendor" />
                            <h3> Shop,pay in bits <br /> and enjoy free delivery. </h3>
                            <p> Buy bulk items at wholesale process, pay in bits or when we deliver and enjoy free delivery all on konnect. </p>
                        </div>
                    </div>
                    :
                    currentDisplay === 1 ?
                        <div className="welcome-spacing">
                            <img src={ExtraImg} className="signup_img" alt="wise9ja" />
                            <h3>Get richer everyday < br /> with our Wise9ja plans</h3>
                            <p> Let Wise9ja spoil you a bit to live life on your own terms! Refer friends andget richer each day.</p>
                        </div > :
                        <div className="welcome-spacing" >
                            <img src={VendorImg} className="signup_img" alt="vendor" />
                            <h3> Become a Support Manager < br /> Manage food vendors and earn weekly. </h3>
                            <p> Konnect sales team, support food vendors to enjoy a convenient shopping experience.Join us today and be glad. </p>
                        </div>
                }
            </div>
        </div>
        <div className="welcome-buttons">
            <div className="welcome-buttons-content">
                <div className="carousel-message-display">
                    <ul>
                        <li className={`${currentDisplay === 0 ? "active" : ""}`} ></li>
                        <li className={`${currentDisplay === 1 ? "active" : ""}`} ></li>
                        <li className={`${currentDisplay === 2 ? "active" : ""}`} > </li>
                        {/* <li className = { `${currentDisplay === 3 ? "active" : ""}` } > </li> */}
                    </ul>
                </div>
                <Link className="btn-white" to="/signin"> Log in to your account </Link>
                <Link className="border-white" to="/signup"> Create a free account </Link>
            </div>
        </div>
    </div>
    )
}
export default WelcomePage;