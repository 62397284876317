import React, { useEffect, useState } from "react";

import { Divider, Input, Select, Spin, Tabs, notification } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from 'react-number-format';
import * as yup from "yup";

import 'swiper/css';

import _1 from "../../../assets/_1.jpg"

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";
import { _complete_bank_transfer, _complete_user_paybill, _confirm_user_transfer_pin, _fetch_bank_list, _fetch_bill_options, _fetch_user_data_options, _fund_user_wallet, _verify_user_bank_account } from "../../../common/axios_services";
import AppRoutes from "../../../common/routes";
import FooterDisplay from "../../../components/footer";

import MtnImg from "../../../assets/images/bill_logos/mtn.jpg";
import NtelImg from "../../../assets/images/bill_logos/ntel.svg";
import GloImg from "../../../assets/images/bill_logos/glo.png";
import MobileImg from "../../../assets/images/bill_logos/9mobile.png";
import AirtelImg from "../../../assets/images/bill_logos/airtel.png";
import { yupResolver } from "@hookform/resolvers/yup";
import RequestTransactionCode from "../../../components/profile/transcode";
import AirtimePage from "./bills/airtime";
import DataPage from "./bills/data";
import ElectricityPage from "./bills/electricity";
import TVPage from "./bills/tv";


const FundWalletPage = () => {

    const Navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const antPageIcon = <LoadingOutlined style={{ fontSize: 35, color: '#0D60D8' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const digitsOnly = (value) => /^\d+$/.test(value);
    // AIRTIME PURCHASE STARTS

    const checkAirtimeData = yup.object().shape({
        provider: yup.string().required('Please select provider'),
        phone: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly)
    });
    // start form handlers
    const [searchParam] = useSearchParams();

    const { handleSubmit: handleAirtimePayment, control: controlAirtimePayment, formState: { errors: errorAirtime } } = useForm({
        resolver: yupResolver(checkAirtimeData)
    });
    const [loadingData, setLoadingData] = useState(false);
    const [loadingPageData, setLoadingPageData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [userAirtimeAmount, setUserAirtimeAmount] = useState(0);
    const [, setUserTransPin] = useState('');
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [billType, setBillType] = useState("airtim");
    const [providerOption, setProviderOption] = useState([]);
    const [airtimeData, setAirtimeData] = useState({});

    useEffect(() => {
        let param = searchParam.get("rel");
        if (param === "airtime") {
            setBillType("airtime");
        } else if (param === "electricity") {
            setBillType("electricity");
        } else if (param === "tv") {
            setBillType("tv");
        } else {
            setBillType("data");
        }
    }, [])

    // fund user wallet amount
    const onChangeValue = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setUserAirtimeAmount(amount);
    }

    // user transfer
    const navigate = useNavigate();

    /* finalize pay bill */
    const finalizeAirtimeBillPayment = async e => {
        setLoadingData(true);
        setErrorMessage("");
        try {
            const profile = await _complete_user_paybill("buy_airtime", airtimeData);
            if (profile.data.status === "success") {
                openNotificationWithIcon('success', 'Transaction completed successfully');
                Navigate(`${AppRoutes.wallet}`);
                // window.location = profile?.data?.data?.link ? profile?.data?.data?.link : profile?.data?.data?.response_data?.link;
                // setActiveFundWalletData(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
                setLoadingData(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please reload page to try again.')
            }
            setLoadingData(false);
        }
    }

    const fetchAirtimeProviders = async () => {
        setLoadingPageData(true);
        setErrorMessage("");
        try {
            const profile = await _fetch_bill_options(billType);
            if (profile.data.status === "success") {
                setProviderOption(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
            }
            setLoadingPageData(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please reload page to try again.')
            }
            setLoadingPageData(false);
        }
    }

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);

    // AIRTIME PURCHASES ENDS

    // DATA PURCHASE BEGINS

    const [dataProviderOption, setDataProviderOption] = useState([]);

    const fetchDataProviders = async () => {
        setLoadingPageData(true);
        setErrorMessage("");
        try {
            const profile = await _fetch_bill_options("data");
            if (profile.data.status === "success") {
                setDataProviderOption(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
            }
            setLoadingPageData(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please reload page to try again.')
            }
            setLoadingPageData(false);
        }
    }

    useEffect(() => {
        fetchAirtimeProviders();
        fetchDataProviders();
    }, [])

    const checkDataData = yup.object().shape({
        // provider: yup.string().required('Please select provider'),
        phone: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly)
    });
    // start form handlers
    const { handleSubmit: handleDataPayment, control: controlDataPayment, formState: { errors: errorData } } = useForm({
        resolver: yupResolver(checkDataData)
    });
    const [dataData, setDataData] = useState([]);
    const [loadingDataData, setLoadingDataData] = useState(false);
    const [loadingDataDat] = useState("50000");
    const [selectedDataPlan, setSelectedDataPlan] = useState({});
    const [selectedDataProvider, setSelectedDataProvider] = useState();
    const [dataPaymentData, setDataPaymentData] = useState({});

    const fetchDataPlans = async billDataInfo => {
        setLoadingPageData(true);
        setErrorMessage("");
        try {
            const profile = await _fetch_user_data_options(billDataInfo);
            if (profile.data.status === "success") {
                setDataData(profile.data.data);
                // console.log(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
            }
            setLoadingPageData(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please reload page to try again.')
            }
            setLoadingPageData(false);
        }
    }

    const updateUserBillProvider = e => {
        fetchDataPlans(e.split("---")[0]);
        setSelectedDataProvider(e.split("---")[1])
    }

    const updateSelectedUserBillProvider = e => {
        setSelectedDataPlan({
            amount: String(e.split("---")[1]).slice(0, e.split("---")[1].length - 2),
            slug: e.split("---")[0],
        })
        // console.log(typeof String(e.split("---")[1]).slice(0, e.split("---")[1].length - 2))
    }

    const finishUserBill = async e => {
        setLoadingData(true);
        setErrorMessage("");
        try {
            const profile = await _complete_user_paybill("buy_data", dataPaymentData);
            if (profile.data.status === "success") {
                // console.log(profile)
                openNotificationWithIcon('success', 'Transaction completed successfully');
                Navigate(`${AppRoutes.wallet}`);
            } else {
                openNotificationWithIcon("error", profile.data.message);
                setLoadingData(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please reload page to try again.')
            }
            setLoadingData(false);
        }
    }

    // confirm user transaction pin
    const handleBillPaymentForm = e => {
        if (billType === "airtime") {
            setAirtimeData({
                amount: userAirtimeAmount,
                phone_number: e.phone,
                provider: e.provider
            })
            controlTransCodeModalDisplay();
        } else {
            setDataPaymentData({
                amount: selectedDataPlan.amount,
                phone_number: e.phone,
                product_slug: selectedDataPlan.slug,
                provider: selectedDataProvider
            })
            controlTransCodeModalDisplay();
        }
    }

    const checkUserPin = async e => {
        setLoadingData(true);
        controlTransCodeModalDisplay();
        setLoadingPageData(true);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            if (profile.data.status === "success") {
                if (billType === "airtime") {
                    finalizeAirtimeBillPayment();
                } else {
                    finishUserBill();
                }
            } else {
                setLoadingData(false);
                setLoadingPageData(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please reload page to try again.')
            }
            setLoadingData(false);
        }
    }


    return (
        <div>
            {
                billType === "airtime" ?
                    <AirtimePage />
                    : billType === "electricity" ?
                        <ElectricityPage />
                        : billType === "tv" ?
                            <TVPage /> :
                            <DataPage />
            }
        </div>
    )
}

export default FundWalletPage;