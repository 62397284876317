import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import { Link } from "react-router-dom";
import axiosCall from "../common/axios_call";
import { Swiper, SwiperSlide } from "swiper/react";

const AllCategories = () => {

    const [loadingData, setLoadingData] = useState(true);
    const [categoryData, setCategoryData] = useState([]);

    const fetchAllCategory = () => {
        axiosCall('/category/no_pagination')
            .then(res => {
                setLoadingData(false);
                setCategoryData(res.data.data);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    useEffect(() => {
        fetchAllCategory();
    }, []);

    let skeleton = [];
    for (let i = 0; i < 5; i++) {
        skeleton.push(<div>
            <Skeleton.Button shape="circle" size="large" style={{ width: "100%", height: "7rem" }} active />
        </div>)
    }

    return (
        <div>
            {
                loadingData ?
                    <div className="grid-5">
                        {skeleton.map((skeleton, index) => (
                            <div key={index}>
                                {skeleton}
                            </div>
                        ))}
                    </div> :
                    <>
                        <Swiper
                            spaceBetween={3} slidesPerView={5}
                            loop={false} breakpoints={5}>
                            {categoryData.map((category, index) => (
                                <SwiperSlide key={`cat-${index}`}>
                                    <div className="category-box-info">
                                        <div className="category-box">
                                            <img src={category.category_image} alt={category.category_name} />
                                        </div>
                                        <h4>{category.category_name}</h4>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </>
            }
        </div>
    )
}

export default AllCategories;