
import React, { useEffect, useState } from "react";

import { Input, Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";


const TermsPage = () => {

    const Navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    // start form handlers
    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm({});

    return (
        <div className="display-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    {/* <h5>Transfer Cash</h5> */}
                    <div></div>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                </div>
            </div>
            <div className="display-main-content mt_2">
                <div className="hero-sect">
                    <div className="container">
                        <h3 className="sec-page-title" style={{ marginBottom: 0 }}>Terms of use</h3>
                        <div className="faq_container">
                            <div style={{ marginTop: '20px' }}></div>
                            <h4>
                                PLEASE NOTE THAT YOUR USE OF AND ACCESS TO THE SERVICES (AS DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS; IF 
                                YOU DO NOT AGREE TO ALL OF THESE TERMS, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
                            </h4>
                            <div style={{ marginTop: '30px' }}></div>
                            <p>These Terms of Use (these "Terms") represent an agreement between you and Consukon Limited and contain the terms and
                                conditions governing your use of and access to konnect and all affiliated websites owned and operated by us
                                (collectively, the "Website") and our products, services, and applications (together with the Website, the
                                "Services"). "You" and "your" mean the person who uses or accesses the Services. "We," "us," and "our" mean Konnect
                                and its successors, affiliates, and assignees. As used in these Terms, "Konnect Account" means the accounts (wallet
                                and konnect balance) you have with us for the Services.</p>
                            <h5>YOUR CONSENT TO USE ELECTRONIC SIGNATURES AND COMMUNICATIONS</h5>
                            <p>Your Consent. To the extent permitted by applicable law, you consent to use electronic signatures and to
                                electronically receive all records, notices, statements, communications, and other items for all services provided
                                to you under these Terms and in connection with your relationship with us (collectively, "Communications") that we
                                may otherwise be required to send or provide you in electronic form (e.g., by mail). By accepting and agreeing to
                                these Terms electronically, you represent that: (1) you have read and understand this consent to use electronic
                                signatures and to receive Communications electronically; (2) you satisfy the minimum hardware and software
                                requirements specified below; and (3) your consent will remain in effect until you close your account as specified
                                below.</p>
                            <p>Your Consent. To the extent permitted by applicable law, you consent to use electronic signatures and to
                                electronically receive all records, notices, statements, communications, and other items for all services provided
                                to you under these Terms and in connection with your relationship with us (collectively, "Communications") that we
                                may otherwise be required to send or provide you in electronic form (e.g., by mail). By accepting and agreeing to
                                these Terms electronically, you represent that: (1) you have read and understand this consent to use electronic
                                signatures and to receive Communications electronically; (2) you satisfy the minimum hardware and software
                                requirements specified below; and (3) your consent will remain in effect until you close your account as specified
                                below.</p>
                            <p>You must keep your contact information current with us to ensure that we are able to provide communications to you
                                electronically, you must notify us of any change in your email address and your mobile device number or other text
                                message address by updating your profile on the Website.</p>
                            <p>Changes. We reserve the right, in our sole discretion, to communicate with you in electronic form. In addition, we
                                reserve the right, in our sole discretion, to discontinue the provision of electronic Communications or to terminate
                                or change the terms and conditions on which we provide electronic Communications. Except as otherwise required by
                                applicable law, we will notify you of any such termination or change by updating these Terms on the Website or
                                delivering notice of such termination or change electronically.</p>
                            <p>The information you provide us is subject to our Privacy Policy.</p>
                            <h5>How do I use the Services?</h5>
                            <p>The purpose of the services is for your shop with ease and to help grow your cash.</p>
                            <p>You can use funds in your Konnect accounts to make purchases, withdraw cash, transfer funds to third parties, or for
                                any other purpose. You are solely responsible for determining whether the funds debited from your Konnect account is
                                secure and are acceptable to you. We are not responsible for any third-party fees that may be incurred because of
                                using the Services, including, but not limited to, third-party fees incurred because of maintaining insufficient
                                funds in your bank account.</p>
                            <p>Konnect is not a financial tool, nor a get rich quick or investment app, and the services are not intended to provide
                                financial advice We do not make any representations, warranties, or guarantees of any kind that the services are
                                appropriate for you. Before using the services, you should consider obtaining additional information and advice from
                                a financial adviser.</p>
                            <h5>Are there any fees for using the Services?</h5>
                            <p>There is no monthly fee for the Services, we reserve the right to change fees for the Services in the future. We will
                                notify you before charging a fee for the Services by delivering notice to you electronically, by posting such fee on
                                the Website, or by any other method permitted by applicable law. If you continue using the Services after such
                                notice, you must pay all applicable fees for the Services</p>
                            <h5>Any restrictions on using the Services?</h5>
                            <p>You may only use the Services for your own personal, commercial use and or on behalf of or for the benefit of any
                                third party. Your use of the Services must comply with all applicable law. If your use of the Services is not
                                allowed by applicable law, then you are not authorized to use the Services. We are not responsible if you use the
                                Services in any manner that violates applicable law.</p>
                            <p>You agree not to authorize any other person or entity to use your username and password or mobile device to access
                                the Services. You are solely responsible for the maintenance, confidentiality, and security of your username,
                                password, and other User Information. Except as otherwise required by applicable law, you are responsible for all
                                transactions and other activities authorized or performed using your username and password or mobile device, whether
                                authorized or unauthorized by you. Except as otherwise expressly stated in these Terms or required by applicable
                                law, we are not responsible for any losses arising out of the loss or theft of your User Information or your mobile
                                device or from unauthorized or fraudulent transactions associated with your card, bank account or your konnect
                                account. If you suspect or become aware of any unauthorized activity or access to your username, password, or mobile
                                device, you must change your information and security details immediately or contact us.</p>
                            <p>We offer the Services and the features, information, materials, and content provided and depicted through the
                                Services (collectively, "Content") solely for your personal use for the purposes described therein and in these
                                Terms. All other uses are prohibited. You may not restrict or inhibit any other person from using or enjoying the
                                Services or Content.</p>
                            <p>The Services and Content are protected by copyright, trademark, patent, and other intellectual property laws. We
                                expressly reserve all rights and remedies under applicable law. Except as expressly provided by these Terms or with
                                our prior written consent, you may not use, modify, disassemble, decompile, reverse engineer, reproduce, distribute,
                                rent, sell, license, publish, display, download, transmit, or otherwise exploit any Content in any form by any
                                means. Without limiting the foregoing, you agree not to (and not to allow any third party to): (a) use any robot,
                                spider, scraper, or other automatic or manual device, process, or means to access the Services or copy any Content,
                                except as expressly authorized by us; (b) take any action that imposes or may impose (in our sole determination) an
                                unreasonable or a disproportionately large load on the Services or our infrastructure; (c) utilize any device,
                                software, or routine that will interfere or attempt to interfere with the functionality of the Services; (d) rent,
                                lease, copy, provide access to, or sublicense any portion of the Services or Content to a third party; (e) use any
                                portion of the Services or Content to provide, or incorporate any portion of the Services or Content into, any
                                product or service provided to a third party; (f) reverse engineer, decompile, disassemble, or otherwise seek to
                                obtain the source code of the Services or Content; (g) modify the Services or Content or create any derivative
                                product from any of the foregoing; (h) remove or obscure any proprietary or other notices contained in the Services
                                or Content; (i) use the Services or Content in any way that is illegal, harmful, fraudulent, deceptive, threatening,
                                harassing, defamatory, obscene, or otherwise objectionable, as we may determine in our sole discretion; (j)
                                jeopardize the security of your account or any other person’s account (such as allowing someone else to use your
                                username and password to access the Services); (k) attempt, in any manner, to obtain the username, password,
                                account, or other security information from any other user of the Services; (l) violate the security of any computer
                                network or crack any passwords or security encryption codes; or (m) run Listserv, any form of auto-responder or
                                "spam," or any processes that run or are activated while you are not logged in to access the Services. We may, but
                                are not obligated to, monitor your use of the Services and Content.</p>
                            <p>We do not grant you any licenses, express or implied, to our intellectual property or the intellectual property of
                                our licensors, except as expressly stated in these Terms. We and our third-party licensors retain all right, title,
                                and interest in and to the Services, Content, and any associated patents, trademarks, copyrights, mask work rights,
                                trade secrets, and other intellectual property rights.</p>
                            <p>To the extent permitted by applicable law, we may, in our sole discretion and without liability to you, terminate (or
                                suspend access to) your use of the Services, Content, or your Account for any reason, including, but not limited to,
                                your breach of these Terms.</p>
                            <h5>What do I do if my mobile device is lost or stolen or if I suspect someone has gained unauthorized access to my
                                username or password?</h5>
                            <p>If your mobile device is lost or stolen or if you suspect someone has gained unauthorized access to your username or
                                password, you must change your contact details and security information or contact us immediately for us to take any
                                action, you will need to provide certain User Information so we can verify your identity.</p>
                            <h5>How does Konnect communicate with me about the Services?</h5>
                            <p>To use the Services, you may be required to provide and verify your mobile device number or other text message
                                address to us, and you must expressly consent to receive text messages relating to the Services at that number or
                                address. Third-party data and message fees may apply. To verify your mobile device number or text message address,
                                we may send you a code via text message to the mobile device number or text message address you provide, and you
                                must enter that code as instructed by us. If you change your mobile device number or text message address, you must
                                promptly provide and verify your new mobile device number or text message address.</p>
                            <p>In addition, if you provide your email address to us, we may send you important notices via email about the Services.
                            </p>
                            <h5>Transactions</h5>
                            <p>You agree to abide by our transactions rules whenever you shop, fund your account or transfer to another account or
                                self</p>
                            <p>You agree to pay in full for all shop transactions if delivered to your assigned delivery or pick-up location. That
                                you are liable to pay a penal fee for orders not cancelled within the stipulated period and if you decide to not
                                receive the order either due to you not been available to receive the order or to pick-up at our location.</p>
                            <h5>What do I need to know about third-party websites?</h5>
                            <p>The Services may contain links or connections to third-party websites or services that are not owned or operated by
                                us or our third-party service providers or licensors. We provide such links and connections for your reference only.
                                We do not control such third-party websites or services and are not responsible for their availability or content.
                                Our inclusion of such links and connections does not imply our endorsement of such third-party websites or services
                                or any association with their owners or operators. We assume no liability whatsoever for any such third-party
                                websites or services or any content, features, products, or practices of such third-party websites or services. Your
                                access and use of such third-party websites and services is subject to applicable third-party terms and conditions
                                and privacy policies. We encourage you to read the terms and conditions and privacy policy of each third-party
                                website or service that you visit or utilize.</p>
                            <h5>What if I want to stop using the Services?</h5>
                            <p>You may stop using the services, close your konnect account by contacting us. Notwithstanding the foregoing, if there
                                are any pending transactions relating to your account when we receive your termination notice, we will close your
                                account promptly after such transactions are completed.</p>
                            <p>If you do not use the services for a certain period, applicable law may require us to report the funds in your
                                loyalty account as unclaimed property. If this occurs, we may try to locate you at the address shown in our records.
                                If we are unable to locate you, we may be required to deliver any funds in your loyalty account to the applicable
                                state as unclaimed property. The specified period to report and deliver funds to a state varies by state, but
                                usually ranges between two and five years.</p>
                            <h5>What about my privacy?</h5>
                            <p>We take the privacy of its users very seriously. You understand that by using the Services, you consent to the
                                collection, use, storage, and disclosure of your information as set forth in these Terms and our Privacy Policy.</p>
                            <h5>Additional information (Indemnity and Liabilities)</h5>
                            <p>Without limiting the foregoing, we and our third-party service providers make no warranty that (a) the services will
                                meet your requirements, (b) the services will be uninterrupted, timely, secure, or error-free, (c) the results that
                                may be obtained from the use of the services will be accurate or reliable, (d) the quality of any products,
                                services, information, or other material purchased or obtained by you through the services will meet your
                                expectations, or (e) any errors in the services will be corrected. no oral or written information or advice obtained
                                by you from us or our third-party service providers through or from the services will create any warranty not
                                expressly stated in these terms.</p>
                            <p>Any material downloaded or otherwise obtained through the services is done at your own discretion and risk, and you
                                are solely responsible for any damage to your computer system or loss of data that results from the download of any
                                such material</p>
                            <p>Limitation of liability. to the fullest extent permitted by applicable law, you agree that Konnect, its affiliates,
                                and its and their respective employees, officers, directors, agents, and third-party service providers will not be
                                liable to you or any third party for (a) the performance of the services or the inability to use the services; (b)
                                any indirect, incidental, special, consequential, punitive, or exemplary damages, whether based in contract, tort,
                                or otherwise, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible
                                losses, even if such persons have been advised of the possibility of such damages, which arise out of or are in any
                                way connected with these terms, the services, or content.</p>
                            <p>Indemnity. At our request, you agree to defend, indemnify, and hold harmless konnect, its affiliates, and its and
                                their respective employees, officers, directors, agents, and third-party service providers from and against any and
                                all claims, suits, liabilities, damages (actual and consequential), losses, fines, penalties, costs, and expenses
                                (including reasonable attorneys’ fees) arising from or in any way related to any third-party claims relating to your
                                use of the Services, violation of these Terms, applicable law or any third-party rights, or your fraud or wilful
                                misconduct.</p>
                            <p>Assignment. You may not transfer, assign, or delegate these Terms or your rights or obligations hereunder or your
                                account in any way (by operation of law or otherwise) without our prior written consent. To the extent permitted by
                                applicable law, we may transfer, assign, or delegate these Terms and our rights and obligations hereunder without
                                your consent.</p>
                            <p>Third-Party Beneficiary. You agree that our third-party services providers are third-party beneficiaries of the
                                applicable provisions of these Terms, with all rights to enforce such provisions as if such service providers were a
                                party to these Terms</p>
                            <p>Governing Law. These Terms are made under and will be governed by and construed in accordance with the laws of the
                                Federal Republic of Nigeria, without giving effect to any principles that provide for the application of the law of
                                another jurisdiction.</p>
                            <p>DISPUTE RESOLUTION BY BINDING ARBITRATION; JURY TRIAL WAIVER; CLASS ACTION WAIVER. For any and all controversies,
                                disputes, demands, claims, or causes of action between you and us (including the interpretation and scope of this
                                Section and the arbitrability of the controversy, dispute, demand, claim, or cause of action) relating to the
                                Services or these Terms (as well as any related or prior agreement that you may have had with us), you and we agree
                                to resolve any such controversy, dispute, demand, claim, or cause of action exclusively through binding and
                                confidential arbitration. The arbitration will take place in the federal judicial district of your residence. As
                                used in this Section, "we" and "us" mean Consukon Limited and its subsidiaries, affiliates, predecessors,
                                successors, and assigns and all its and their respective employees, officers, directors, agents, and
                                representatives. In addition, "we" and "us" include any third party providing any product, service, or benefit in
                                connection with the Services or these Terms (as well as any related or prior agreement that you may have had with
                                us) if such third party is named as a co-party with us in any controversy, dispute, demand, claim, or cause of
                                action subject to this Section.</p>
                            <p>Arbitration will be subject to the Federal Arbitration Act and not any state arbitration law. The arbitration will be
                                conducted by commercial arbitrators from the Nigeria Arbitration and Conciliation Act with substantial experience in
                                resolving commercial contract disputes. As modified by these Terms, and unless otherwise agreed upon by the parties
                                in writing, the arbitration will be governed by the Nigeria Arbitration and Conciliation Act’s Commercial
                                Arbitration Rules and, if the arbitrator deems them applicable, the Supplementary Procedures for Consumer Related
                                Disputes (collectively, the "Rules and Procedures").</p>
                            <p>Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other
                                governmental assessments associated with your activity in connection with the Services, if we may, in our sole
                                discretion, do any of the foregoing on your behalf or for ourselves. The failure of either you or us to exercise, in
                                any way, any right herein will not be deemed a waiver of any further rights hereunder. If any provision of these
                                Terms is found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent
                                necessary, so that these Terms will otherwise remain in full force and effect and enforceable. These Terms, together
                                with our Privacy Policy, constitute the entire and sole agreement between you and us with respect to the Services
                                and supersedes all prior understandings, arrangements, or agreements, whether written or oral, regarding the
                                Services. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of
                                konnect, and you do not have any authority of any kind to bind us in any respect whatsoever.</p>
                        </div>
                        <div style={{ marginTop: '50px' }}></div>
                    </div>
                </div>
            </div>
            {/* <FooterDisplay /> */}
        </div>
    )
}

export default TermsPage;