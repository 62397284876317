import React, { useEffect, useState } from "react";

import { Divider, Input, Select, Spin, Tabs, notification } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from 'react-number-format';
import * as yup from "yup";

import 'swiper/css';

import _1 from "../../../../assets/_1.jpg"

import { ReactComponent as ArrowLeftIcon } from "../../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../../../assets/images/icons/mobilenotification.svg";
import { _complete_bank_transfer, _complete_user_paybill, _confirm_user_transfer_pin, _fetch_bank_list, _fetch_bill_options, _fetch_user_data_options, _fund_user_wallet, _verify_user_bank_account } from "../../../../common/axios_services";
import AppRoutes from "../../../../common/routes";
import FooterDisplay from "../../../../components/footer";

import MtnImg from "../../../../assets/images/bill_logos/mtn.jpg";
import NtelImg from "../../../../assets/images/bill_logos/ntel.svg";
import GloImg from "../../../../assets/images/bill_logos/glo.png";
import MobileImg from "../../../../assets/images/bill_logos/9mobile.png";
import AirtelImg from "../../../../assets/images/bill_logos/airtel.png";
import { yupResolver } from "@hookform/resolvers/yup";
import RequestTransactionCode from "../../../../components/profile/transcode";


const AirtimePage = () => {

    const Navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const antPageIcon = <LoadingOutlined style={{ fontSize: 35, color: '#0D60D8' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const digitsOnly = (value) => /^\d+$/.test(value);
    // AIRTIME PURCHASE STARTS

    const checkAirtimeData = yup.object().shape({
        provider: yup.string().required('Please select provider'),
        phone: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly)
    });
    // start form handlers
    const [searchParam] = useSearchParams();

    const { handleSubmit: handleAirtimePayment, control: controlAirtimePayment, formState: { errors: errorAirtime } } = useForm({
        resolver: yupResolver(checkAirtimeData)
    });
    const [loadingData, setLoadingData] = useState(false);
    const [loadingPageData, setLoadingPageData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [userAirtimeAmount, setUserAirtimeAmount] = useState(0);
    const [, setUserTransPin] = useState('');
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [billType, setBillType] = useState("airtime");
    const [providerOption, setProviderOption] = useState([]);
    const [airtimeData, setAirtimeData] = useState({});

    useEffect(() => {
        let param = searchParam.get("rel");
        if (param === "airtime") {
            setBillType("airtime")
        }
    }, [])

    // fund user wallet amount
    const onChangeValue = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setUserAirtimeAmount(amount);
    }

    // user transfer
    const navigate = useNavigate();

    /* finalize pay bill */
    const finalizeAirtimeBillPayment = async e => {
        setLoadingData(true);
        setErrorMessage("");
        try {
            const profile = await _complete_user_paybill("buy_airtime", airtimeData);
            if (profile.data.status === "success") {
                // console.log(profile)
                openNotificationWithIcon("success", "Data purchase successful");
                Navigate(AppRoutes.wallet)
                // window.location = profile?.data?.data?.link ? profile?.data?.data?.link : profile?.data?.data?.response_data?.link;
                // setActiveFundWalletData(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
                setLoadingData(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please reload page to try again.')
            }
            setLoadingData(false);
        }
    }

    const fetchAirtimeProviders = async () => {
        setLoadingPageData(true);
        setErrorMessage("");
        try {
            const profile = await _fetch_bill_options(billType);
            if (profile.data.status === "success") {
                setProviderOption(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
            }
            setLoadingPageData(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while fetching data. Please reload page to try again.')
            }
            setLoadingPageData(false);
        }
    }

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);

    useEffect(() => {
        fetchAirtimeProviders();
    }, [])

    // AIRTIME PURCHASES ENDS

    // confirm user transaction pin
    const handleBillPaymentForm = e => {
        setAirtimeData({
            amount: userAirtimeAmount,
            phone_number: e.phone,
            provider: e.provider
        })
        controlTransCodeModalDisplay();
    }

    const checkUserPin = async e => {
        setLoadingData(true);
        controlTransCodeModalDisplay();
        setLoadingPageData(true);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            if (profile.data.status === "success") {
                finalizeAirtimeBillPayment();
            } else {
                setLoadingData(false);
                setLoadingPageData(false);
                openNotificationWithIcon("error", "An error occurred while completing transaction. Please check network and try again");
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while completing transaction. Please reload page to try again.')
            }
            setLoadingData(false);
            setLoadingPageData(false);
        }
    }


    return (
        <Spin spinning={loadingPageData} indicator={antPageIcon}>
            <div className="display-page bills">
                <div className="small-page-title contain">
                    <div className="grid-flex">
                        <div
                            onClick={() => Navigate(-1)}>
                            <ArrowLeftIcon />
                        </div>
                        <div>
                            <h3 className="sec-page-title">Pay Bills</h3>
                        </div>
                        <div>
                            <NotificationIcon />
                        </div>
                    </div>
                </div>
                <div className="display-main-content mt_3">
                    <div className="hero-sect">
                        <div className="container">
                            <p className="form-explanation">
                                All financial details are stored to PCIDSS level 1 and transmitted via an encrypted 256-bit Https SSL connection.
                            </p>
                            <div>
                                <div className="mt_5">
                                    <div>
                                        {errorMessage.length ? <p className="form-error mb_3">{errorMessage}</p> : ""}
                                    </div>
                                    <form onSubmit={handleAirtimePayment(handleBillPaymentForm)}>
                                        <div className="form_group">
                                            <div className="grid-flex">
                                                <label>Select Provider</label>
                                            </div>
                                            <Controller name="provider" control={controlAirtimePayment}
                                                render={({ field }) => (
                                                    <Select {...field} style={{ height: '5rem', width: '100%' }}>
                                                        {
                                                            providerOption.map((provider, index) => (
                                                                <Select.Option key={index} value={provider.attributes.name}>
                                                                    <div className="bill-inline">
                                                                        {
                                                                            provider.attributes.name === "MTN" ?
                                                                                <img src={MtnImg} className="bill_logo" alt={provider.attributes.name} />
                                                                                : provider.attributes.name === "Airtel" ?
                                                                                    <img src={AirtelImg} className="bill_logo" alt={provider.attributes.name} />
                                                                                    : provider.attributes.name === "NTEL" ?
                                                                                        <img src={NtelImg} className="bill_logo" alt={provider.attributes.name} />
                                                                                        : provider.attributes.name === "GLO" ?
                                                                                            <img src={GloImg} className="bill_logo" alt={provider.attributes.name} />
                                                                                            :
                                                                                            <img src={MobileImg} className="bill_logo" alt={provider.attributes.name} />

                                                                        }
                                                                        {provider.attributes.name}
                                                                    </div>
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                )} />
                                            {errorAirtime.provider && <p className="form-error">{errorAirtime.provider.message}</p>}
                                        </div>
                                        <div className="form_group">
                                            <div className="grid-flex">
                                                <label>Phone number</label>
                                            </div>
                                            <Controller name="phone" control={controlAirtimePayment}
                                                render={({ field }) => (
                                                    <Input type="tel"
                                                        {...field} style={{ height: '5rem', width: '100%' }} />
                                                )} />
                                            {errorAirtime.phone && <p className="form-error">{errorAirtime.phone.message}</p>}
                                        </div>
                                        <div className="form_group">
                                            <div className="grid-flex">
                                                <label>Amount</label>
                                                <p>0% charge</p>
                                            </div>
                                            <Controller name="amount" control={controlAirtimePayment}
                                                render={({ field }) => (
                                                    <NumberFormat thousandSeparator={true}
                                                        prefix={'NGN '}
                                                        className="numeric"
                                                        onInput={e => onChangeValue(e)}
                                                        {...field} style={{ height: '5rem', width: '100%' }} />
                                                )} />
                                        </div>
                                        <div className="welcome-buttons mt_20">
                                            <div className="welcome-buttons-content">
                                                {
                                                    !loadingData ?
                                                        <button
                                                            disabled={userAirtimeAmount > 0 ? false : true}
                                                            className="btn btn-green">Continue</button>
                                                        :
                                                        <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={controlTransCodeModalDisplay}
                            inputCount={4} actionFunction={checkUserPin} stateFunc={setUserTransPin} />
                    </div>
                    <FooterDisplay />
                </div >
            </div >
        </Spin >
    )
}

export default AirtimePage;