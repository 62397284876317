
import { Input, notification, Spin, Select } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { _complete_user_kyc, _save_user_bvn, _send_password_reset_mail } from '../../common/axios_services';
import NaijaStates from 'naija-state-local-government';

const KYCAddressForm = props => {

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const borderAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#258635' }} spin />;
    const [allLgas, setAllLgas] = useState([]);
    const [allStates,] = useState(NaijaStates.states());
    const [userState, setUserState] = useState("");


    const resetCheck = yup.object().shape({
        postal_code: yup.string().required('Postal code field is required'),
        city: yup.string().required('City field is required'),
        lga: yup.string().required('Local government field is required'),
        house_address: yup.string().required('House address field is required'),
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const { handleSubmit: handleFinalSubmit, control: controlFinalSubmit, formState: { errors: finalSubmitError }, setValue } = useForm({
        resolver: yupResolver(resetCheck)
    });

    const SaveUserBasicData = async e => {
        if (userState) {
            let kycData = {
                city: e.city,
                house_address: e.house_address,
                country: "NG",
                lga: e.lga,
                postal_code: e.postal_code,
                state: userState,
            }
            props.formAction(kycData);
        } else {
            openNotificationWithIcon("error", "Please enter all fields");
        }
    }

    const onChangeState = (value) => {
        if (value) {
            setValue("lga", "");
            const allLGAsinAState = NaijaStates.lgas(`${value}`);
            setAllLgas(allLGAsinAState.lgas);
            setUserState(value);
        }
    }

    return (
        <div className="">
            <form onSubmit={handleFinalSubmit(SaveUserBasicData)}>
                <div className="grid-2">
                    <div className="form_group">
                        <label htmlFor="postal_code">Postal Code</label>
                        <Controller name="postal_code" control={controlFinalSubmit} defaultValue=""
                            render={({ field }) => (
                                <Input {...field}
                                    style={{ height: "5rem", display: "flex", alignItems: "center" }}
                                />
                            )} />
                        {finalSubmitError.postal_code && <p className="form-error">{finalSubmitError.postal_code.message}</p>}
                    </div>
                    <div className="form_group">
                        <label htmlFor="city">City</label>
                        <Controller name="city" control={controlFinalSubmit} defaultValue=""
                            render={({ field }) => (
                                <Input {...field}
                                    style={{ height: "5rem", display: "flex", alignItems: "center" }}
                                />
                            )} />
                        {finalSubmitError.city && <p className="form-error">{finalSubmitError.city.message}</p>}
                    </div>
                </div>
                <div className="grid-2">
                    <div className="form_group">
                        <label htmlFor="state">State</label>
                        <Controller name="state" control={controlFinalSubmit} defaultValue=""
                            render={({ field }) => (
                                <Select onChange={onChangeState} placeholder="Select State" name="state" style={{ width: '100%', height: '5rem' }}>
                                    {allStates.map((state) => (
                                        <Select.Option key={state} value={state}>{state}</Select.Option>
                                    ))}
                                </Select>
                            )} />
                    </div>
                    <div className="form_group">
                        <label htmlFor="lga">LGA</label>
                        <Controller name="lga" control={controlFinalSubmit}
                            render={({ field }) => (
                                <Select placeholder="Select LGA" name="state" style={{ width: '100%', height: '5rem' }} {...field}>
                                    {allLgas.map((lga) => (
                                        <Select.Option key={lga} value={lga}>{lga}</Select.Option>
                                    ))}
                                </Select>
                            )} />
                        {finalSubmitError.lga && <p className="form-error">{finalSubmitError.lga.message}</p>}
                    </div>
                </div>
                <div className="form_group">
                    <label htmlFor="house_address">House Address</label>
                    <Controller name="house_address" control={controlFinalSubmit} defaultValue=""
                        render={({ field }) => (
                            <Input.TextArea rows={3} {...field} />
                        )} />
                    {finalSubmitError.house_address && <p className="form-error">{finalSubmitError.house_address.message}</p>}
                </div>
                <div className="welcome-buttons mt_5">
                    <div className="welcome-buttons-content">
                        {
                            !props.loadingData ?
                                <button className="btn-blue">Continue</button>
                                :
                                <button className="btn-blue" disabled><Spin indicator={antIcon} /></button>
                        }
                        {
                            !props.loadingData ?
                                <button className="btn-border-blue mt_2" onClick={props.routeControl}>
                                    Go Back
                                </button>
                                :
                                <button className="btn-border-blue mt_2" onClick={props.routeControl}>
                                    <Spin indicator={borderAntIcon} />
                                </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}

export default KYCAddressForm;