
import { Input, Spin, notification } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../../common/routes';
import { _verify_user_email_signup, _verify_user_passcode_for_password_reset } from '../../common/axios_services';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";


const VerifyPasswordResetMail = () => {

    const Navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);
    const [emailAddress] = useState(localStorage.getItem("email_address_for_reset"));

    const resetCheck = yup.object().shape({
        // email: yup.string().email('Email address is not valid').required('Email address field is required'),
        pass_code: yup.string().min(3, "Pass code can not be less than 3 characters").required('Verification code is required').max(5, "Pass code can not be more than 5 characters"),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            email: "",
            password: "",
        },
        resolver: yupResolver(resetCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    useEffect(() => {
        localStorage.removeItem("email_address_for_reset");
    }, [])


    const verifyUser = async e => {
        setErrorMessage('');
        setFetchingData(true);
        let { email, pass_code } = e;
        try {
            const response = await _verify_user_passcode_for_password_reset({
                email: emailAddress,
                pass_code,
                type: "periodic"
            });
            openNotificationWithIcon("success", "Email address verified successfully");
            Navigate(`/reset-password/${response.data.data.id}/${response.data.data.token}`);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    return (
        <div className="signin-page verification_page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ion-icon name="arrow-back-circle"></ion-icon>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div className="auth-sect-div">
                        <h3 className="auth-sect-title">Verify Pass Code!</h3>
                        <p>We have sent an OTP to your email address and phone number. Please enter the OTP and your email address to
                            continue your reset process.</p>
                    </div>
                    <div className="error-block">{errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}</div>
                    <form onSubmit={handleSubmit(verifyUser)}>
                        <div className="form_group">
                            <label htmlFor="email">Email address</label>
                            <Controller name="email" control={control} defaultValue={emailAddress}
                                render={({ field }) => (
                                    <Input name="email" {...field} id="email" type='"email' disabled
                                        style={{ height: "5rem" }} value={emailAddress} />
                                )} />
                            {errors.email && <p className="form-error">{errors.email.message}</p>}
                        </div>
                        <div className="form_group">
                            <label htmlFor="pass_code">Verification Code</label>
                            <Controller name="pass_code" control={control}
                                render={({ field }) => (
                                    <Input name="pass_code" {...field} id="pass_code"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.pass_code && <p className="form-error">{errors.pass_code.message}</p>}
                        </div>
                        <div className="welcome-buttons mt_20">
                            <div className="welcome-buttons-content">
                                <div>
                                    {
                                        !fetchingData ?
                                            <button className="btn-green">Verify Passcode</button>
                                            :
                                            <button disabled className="btn-green"><Spin indicator={antIcon} /></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default VerifyPasswordResetMail;