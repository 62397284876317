import React from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../../common/routes";

import ConfettiImg from "../../assets/images/Information.png";

const SignupSuccessfulPage = () => {

    return (
        // <Spin spinning={pageLoader} indicator={secAntIcon}>
        <div className="display-page">
            <div className="display-main-content main-center-message inline-status-box mt_3">
                <div className="hero-sect">
                    <div className="container">
                        <div className="status-circle">
                            <img src={ConfettiImg} alt="confetti" />
                        </div>
                        <h4>Complete KYC</h4>
                        <p className="form-explanation">
                            We require your data to complete your signup process so you can start saving money while you shop and
                            earn extra cash monthly.
                        </p>
                        <div>
                            <a className="btn btn-base" target="_blank" href="https://konnect.ws/signin">Complete KYC</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </Spin>
    )
}

export default SignupSuccessfulPage;