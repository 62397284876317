import "../assets/css/mini.css";

import React from "react";

import 'swiper/css';

import { Link, useNavigate } from 'react-router-dom';
import AllAppRoutes from "../common/routes";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";

import { Collapse } from 'antd';

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _get_user_account_history, _wise9ja_topup } from '../common/axios_services';

import CoinsGIF from "../assets/images/illustrations/coins.gif";

import AppRoutes from "../common/routes";

const HowReferralWorksPage = () => {

    const Navigate = useNavigate();

    const items = [
        {
            key: '1',
            label: <>
                <span>1</span>
                <div>
                    <p>What is referral program?</p>
                </div>
            </>,
            children: <p>
                Our referral program is how users invite families and friends. Also
                to invite business users (food vendors).
            </p>,
        },
        {
            key: '2',
            label: <>
                <span>2</span>
                <div>
                    <p>Who can refer and earn?</p>
                </div>
            </>,
            children: <p>You earn a referral bonus when your friends sign up using
                your referral code. Your bonus will allow you to enjoy discount when you shop and extra cash at Wise9ja.</p>,
        },
        {
            key: '3',
            label: <>
                <span>3</span>
                <div>
                    <p>What is RA?</p>
                </div>
            </>,
            children: <p>RA is called referral ambassador. This is a user that earns
                a referral income when their referee completes transactions from.</p>,
        },
    ];

    return (
        <div className="display-page full-height">
            <div className="how-to referral">
                <div className="small-page-title contain">
                    <div className="grid-flex">
                        <div
                            onClick={() => Navigate(-1)}>
                            <ArrowLeftIcon />
                        </div>
                        <div></div>
                        
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="display-main-content mt_2">
                    <div className="contain">
                        <div>
                            <h3>How Referral Works</h3>
                            <img src={CoinsGIF} alt="gift" />
                            <p className="center">Our referral allows users to earn weekly as Sales Agents working with our food vendors. Visit
                                our Join us page and learn more</p>
                        </div>
                    </div>
                    <div className="white-bg mt_2">
                        <h5>How our Referral plans work</h5>
                        <ul>
                            <li>
                                <span>1</span>
                                <p>Earn weekly as a Sales Agent or Become a Food Vendor.</p>
                            </li>
                            <li>
                                <span>2</span>
                                <p>Share your referral code with food vendors and earn instant cash.</p>
                            </li>
                            <li>
                                <span>3</span>
                                <p>Share with families and friends and get referral commission whenever they shop.</p>
                            </li>
                            <li>
                                <span>4</span>
                                <p>Get referral bonus to enjoy discount when you shop.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="white-bg faqs mt_2">
                        <div className="grid-flex">
                            <div className="grid-flex">
                                <p>Read our FAQs</p>
                            </div>
                            <div className="link-blue-cover">
                                {/* <ion-icon name="chevron-forward-outline"></ion-icon> */}
                                <ion-icon name="help-outline"></ion-icon>
                            </div>
                        </div>
                        <ul>
                            <div className="faq-box">
                                <Collapse defaultActiveKey={['1']} ghost items={items} expandIconPosition="end" />
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HowReferralWorksPage;