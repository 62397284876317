
import { Input, Drawer, notification, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import { _send_password_reset_mail } from '../../common/axios_services';
import { encrypted_token } from '../../slices/profileSlice';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";
import AppRoutes from '../../common/routes';

const SendResetMail = () => {
    const Navigate = useNavigate();
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');

    const resetCheck = yup.object().shape({
        emailAddress: yup.string().email('Email address is not valid').required('Email address field is required'),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            emailAddress: ""
        },
        resolver: yupResolver(resetCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const SendUserPasswordResetMail = async e => {
        setErrorMessage('');
        setFetchingData(true);
        let { emailAddress } = e;
        try {
            const response = await _send_password_reset_mail({ email: emailAddress })
            localStorage.setItem("email_address_for_reset", emailAddress)
            openNotificationWithIcon('success', response.data.message)
            Navigate(AppRoutes.verify_passcode);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    return (
        <div className="signin-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ion-icon name="arrow-back-circle"></ion-icon>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div className="auth-sect-div">
                        <h3 className="auth-sect-title">Amend your password!</h3>
                        <p className='width-85'>Enter the email address attributed to your account and we will send you an OTP to use for your password reset.</p>
                        {/* <p>No account yet? <Link className="inner-link" to={AppRoutes.signup}>Click here to create one now.</Link></p> */}
                    </div>
                    <div className="error-block">{errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}</div>
                    <form onSubmit={handleSubmit(SendUserPasswordResetMail)}>
                        <div className="form_group">
                            <label htmlFor="emailAddress">Email address</label>
                            <Controller name="emailAddress" control={control}
                                render={({ field }) => (
                                    <Input name="emailAddress" {...field} id="emailAddress"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.emailAddress && <p className="form-error">{errors.emailAddress.message}</p>}
                        </div>
                        <div className="welcome-buttons mt_5">
                            <div className="welcome-buttons-content">
                                {
                                    !fetchingData ?
                                        <button className="btn-blue">Reset Password</button>
                                        :
                                        <button className="btn-blue" disabled><Spin indicator={antIcon} /></button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SendResetMail;