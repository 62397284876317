import "../../assets/css/auth.css";

import { Input, Spin, Drawer, Checkbox, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import NaijaStates from 'naija-state-local-government';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import axios from '../../common/axios_call';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import AuthBanner from "../../assets/images/illustrations/auth_1.jpg";
import AppRoutes from "../../common/routes";
import { _sign_user_up } from "../../common/axios_services";

const SignUpPage = () => {

    const [currentFormDisp, setCurrentFormDisp] = useState("basic");

    let url = new URLSearchParams(window.location.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [appLoading, setAppLoading] = useState(false);
    const [signupAlert, setSignupAlert] = useState(true);
    const [fetchingData, setFetchingData] = useState(false);
    const [extraData, setExtraData] = useState(false);
    const [referralCode,] = useState(url.get('konnectrfc') ? url.get('konnectrfc') : '');
    const [redirectUrl,] = useState(url.get('konnectrd') ? url.get('konnectrd') : '');
    const [stepDisplay, setStepDisplay] = useState(0);
    const [userData, setUserData] = useState({});
    const [displayExtraData, setDisplayExtraData] = useState(false);
    const [userToRA, setUserToRA] = useState(1);
    const [is_ra, set_is_ra] = useState(false);
    const [is_so, set_is_so] = useState(false);
    const [openAuthResponseModal, setOpenAuthResponseModal] = useState(false);
    const [userBasicInfo, setUserBasicInfo] = useState({
        first_name: "", last_name: "", email: ""
    })
    const [phoneNumberInfo, setPhoneNumberInfo] = useState({
        phone_number: ""
    })
    const [userLivingState, setUserLivingState] = useState("");
    const [allLgas, setAllLgas] = useState([]);

    const digitsOnly = (value) => /^\d+$/.test(value);

    const validator = yup.object().shape({
        password: yup.string().min(6).required('Password field is required')
        // confirm_password: yup.string().min(6).required('Password field is required'),
    })


    const { handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validator)
    });

    const signupUser = async e => {
        setAppLoading(true);
        setErrorMessage('');
        let { first_name, last_name, email, phone_number, password, home_address, referral_code, lga } = e;

        try {
            let saveUser = await _sign_user_up({
                first_name, last_name, email, password, confirm_password: password,
                referral_code, image: "", phone_number: phone_number.slice(3),
                phone_code: phone_number.slice(0, 3), role: userToRA, app: "mobile", role_id: 1, is_ra, is_so, home_address, lga, state: userLivingState
            })
            if (saveUser?.data?.status === "error") {
                // setExtraData(false)
                setAppLoading(false);
                return setErrorMessage(saveUser.data.message)
            }
            localStorage.setItem('konnectnewaccountcreated', 'true');
            navigate(AppRoutes.verify_email);
        } catch (err) {
            if (err?.response?.data?.message) {
                setErrorMessage(err?.response?.data?.message);
            } else if (err?.code === "ERR_NETWORK") {
                setErrorMessage('An error occurred while signing user up. Please check network and try again');
            } else {
                setErrorMessage('An error occurred while signing user up. Please try again');
            }
            setAppLoading(false);
            // setExtraData(false);
            setOpenAuthResponseModal(true);
        }
    }

    const basicInfoValidator = yup.object().shape({
        first_name: yup.string().required('First name field is required'),
        last_name: yup.string().required('Last name field is required'),
        email: yup.string().email('Email address is not valid').required('Email address field is required'),
        password: yup.string().min(6).required('Password field is required'),
        phone_number: yup.string()
            .min(9, 'Please enter a valid phone number')
            .required('Phone number field is required')
            .test('Digits only', 'The field should have digits only', digitsOnly)
    })
    const { handleSubmit: handleBasicInfo, control: controlBasicInfo, formState: { errors: basicInfoError } } = useForm({
        resolver: yupResolver(basicInfoValidator)
    });

    const onChangeState = (value) => {
        if (value) {
            const allLGAsinAState = NaijaStates.lgas(`${value}`);
            setUserLivingState(value);
            setAllLgas(allLGAsinAState.lgas);
        }
    }

    return (
        <div className="signin-page">
            <div>
                <div className="small-page-title grid-flex">
                    <div onClick={() => navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div>
                        <div className="auth-sect-div">
                            <h3 className="auth-sect-title">Create a free account</h3>
                            <p>Have an account already? <Link className="inner-link" to={AppRoutes.signin}>Sign In Now.</Link></p>
                        </div>
                    </div>
                    {
                        errorMessage.length ?
                            <p className="form-error"
                                style={{ marginBottom: 30, display: "block" }}>{errorMessage}</p>
                            : ""
                    }
                    <form onSubmit={handleBasicInfo(signupUser)}>
                        <div className="grid-2">
                            <div className="form_group">
                                <label htmlFor="first_name">First name</label>
                                <Controller name="first_name" control={controlBasicInfo}
                                    render={({ field }) => (
                                        <Input name="first_name" {...field} id="first_name"
                                            style={{ height: "5rem" }} />
                                    )} />
                                {basicInfoError.first_name && <p className="form-error">{basicInfoError.first_name.message}</p>}
                            </div>
                            <div className="form_group">
                                <label htmlFor="last_name">Last name (Surname)</label>
                                <Controller name="last_name" control={controlBasicInfo}
                                    render={({ field }) => (
                                        <Input name="last_name" {...field} id="last_name"
                                            style={{ height: "5rem" }} />
                                    )} />
                                {basicInfoError.last_name && <p className="form-error">{basicInfoError.last_name.message}</p>}
                            </div>
                        </div>
                        <div className="grid-2">
                            <div className="form_group">
                                <label htmlFor="email">Email address</label>
                                <Controller name="email" control={controlBasicInfo} defaultValue=""
                                    render={({ field }) => (
                                        <Input name="email" {...field} id="email"
                                            style={{ height: "5rem" }} />
                                    )} />
                                {basicInfoError.email && <p className="form-error">{basicInfoError.email.message}</p>}
                            </div>
                            <div className="form_group">
                                <label htmlFor="phone_number">Phone number</label>
                                <Controller name="phone_number" control={controlBasicInfo} defaultValue=""
                                    render={({ field }) => (
                                        <PhoneInput
                                            country={'ng'} {...field} placeholder="0 000 000" style={{ height: "5rem" }}
                                        // value={this.state.phone}
                                        // onChange={phone => this.setState({ phone })}
                                        />
                                        // <Input name="phone_number" type="tel" {...field} id="phone_number"
                                        //     style={{ height: "5rem" }} />
                                    )} />
                                {basicInfoError.phone_number && <p className="form-error">{basicInfoError.phone_number.message}</p>}
                            </div>
                        </div>
                        <div className="form_group">
                            <label htmlFor="password">Password</label>
                            <Controller name="password" control={controlBasicInfo} defaultValue=""
                                render={({ field }) => (
                                    <Input.Password name="password" {...field} id="password"
                                        style={{ height: "5rem" }} />
                                )} />
                            {basicInfoError.password && <p className="form-error">{basicInfoError.password.message}</p>}
                        </div>
                        <div className="form_group">
                            <label htmlFor="referral_code">Referral Code (if referred by a friend)</label>
                            <Controller name="referral_code" control={controlBasicInfo} defaultValue=""
                                render={({ field }) => (
                                    <Input name="referral_code" {...field} id="referral_code"
                                        style={{ height: "5rem" }} />
                                )} />
                        </div>
                        <div className="extra-links">
                            <Checkbox onChange={() => set_is_so(!is_so)}>Want to become a food vendor? Click here</Checkbox>
                        </div>
                        <div className={`vendor-signup-div ${!is_so ? "hide" : ""}`}>
                            <div className="form_group">
                                <label htmlFor="home_address">Vendor Address</label>
                                <Controller name="home_address" control={controlBasicInfo} defaultValue=""
                                    render={({ field }) => (
                                        <Input name="home_address" {...field} id="home_address"
                                            style={{ height: "5rem" }} />
                                    )} />
                            </div>
                            <div className="grid-2">
                                <div className="form_group">
                                    <label htmlFor="state">State</label>
                                    <Controller name="state" control={controlBasicInfo} defaultValue=""
                                        render={({ field }) => (
                                            <Select placeholder="Select state" onChange={onChangeState} name="state" id="state"
                                                style={{ width: '100%', height: "5rem" }}>
                                                <Select.Option value="lagos">Lagos</Select.Option>
                                            </Select>
                                        )} />
                                </div>
                                <div className="form_group">
                                    <label htmlFor="lga">LGA</label>
                                    <Controller name="lga" control={controlBasicInfo} defaultValue=""
                                        render={({ field }) => (
                                            <Select placeholder="Select LGA" {...field} name="lga" style={{ width: '100%', height: '5rem' }}>
                                                {allLgas.map((lga, index) => (
                                                    <Select.Option key={index} value={lga}>{lga}</Select.Option>
                                                ))}
                                            </Select>
                                        )} />
                                </div>
                            </div>
                        </div>
                        <div className="welcome-buttons mt_15">
                            <div className="welcome-buttons-content">
                                <div>
                                    {
                                        !appLoading ?
                                            <button
                                                className="btn-blue">Create a free Account</button> :
                                            <button
                                                className="btn-blue"><Spin indicator={antIcon} /></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage;