import React from "react";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import CustormerImg from "../assets/images/icons/customer.svg";

import { useNavigate } from "react-router-dom";

const TopNav = props => {

    const Navigate = useNavigate();

    return (
        <div className="small-page-title contain">
            <div className="grid-flex">
                <div
                    onClick={() => Navigate(-1)}>
                    <ArrowLeftIcon />
                </div>
                <div>
                    <h3 className="sec-page-title">{props.title}</h3>
                </div>
                <div>
                    {
                        props.type === "bell" ?
                            <NotificationIcon />
                            :
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <img src={CustormerImg} className="customer" alt="customer" />
                            </a>
                    }
                </div>
            </div>
        </div>
    )
}

export default TopNav;