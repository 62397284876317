
import { Input, notification, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { _reset_user_password } from '../../common/axios_services';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";

import AppRoutes from '../../common/routes';

const ResetPasswordPage = () => {

    const Navigate = useNavigate();
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);

    const routerParams = useParams();

    const resetPasswordCheck = yup.object().shape({
        new_password: yup.string().min(6).required('Password field is required'),
        confirm_password: yup.string().min(6).required('Confirm Password field is required'),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            password: "",
        },
        resolver: yupResolver(resetPasswordCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const resetUserPassword = async e => {
        setErrorMessage('');
        setFetchingData(true);
        if (e.new_password === e.confirm_password) {
            let passwordData = {
                id: routerParams.id,
                new_password: e.new_password,
                confirm_new_password: e.confirm_password,
                token: routerParams.token,
                type: "token"
            }

            try {
                const response = await _reset_user_password(routerParams.token, passwordData)
                openNotificationWithIcon('success', response.data.message)
                Navigate("/home");
            } catch (err) {
                setFetchingData(false);
                if (err.response) {
                    setErrorMessage(err.response.data.message);
                } else {
                    setErrorMessage(err.message);
                }
            }
        } else {
            setErrorMessage("Your password and confirm password are not equal.");
        }
    }

    return (
        <div className="signin-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ion-icon name="arrow-back-circle"></ion-icon>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div className="auth-sect-div">
                        <h3 className="auth-sect-title">Enter New Password</h3>
                        <p className='width-85'>Please enter your new password to complete your password reset process.</p>
                    </div>
                    <div className="error-block">{errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}</div>
                    <form onSubmit={handleSubmit(resetUserPassword)}>
                        <div className="form_group">
                            <label htmlFor="new_password">New Password</label>
                            <Controller name="new_password" control={control}
                                render={({ field }) => (
                                    <Input.Password name="new_password" {...field} id="new_password"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.new_password && <p className="form-error">{errors.new_password.message}</p>}
                        </div>
                        <div className="form_group">
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <Controller name="confirm_password" control={control}
                                render={({ field }) => (
                                    <Input.Password name="confirm_password" {...field} id="confirm_password"
                                        style={{ height: "5rem" }} />
                                )} />
                            {errors.confirm_password && <p className="form-error">{errors.confirm_password.message}</p>}
                        </div>
                        <div className="welcome-buttons mt_20">
                            <div className="welcome-buttons-content">
                                {
                                    !fetchingData ?
                                        <button className="btn-blue">Reset Password</button>
                                        :
                                        <button className="btn-blue" disabled><Spin indicator={antIcon} /></button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage;