import React, { useEffect, useState } from "react";

import { Input, Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";


const ContactUsPage = () => {

    const Navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    // start form handlers
    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm({});

    return (
        <div className="display-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    {/* <h5>Transfer Cash</h5> */}
                    <div></div>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                </div>
            </div>
            <div className="display-main-content mt_2">
                <div className="hero-sect">
                    <div className="container">
                        <h3 className="sec-page-title">Reach out to us</h3>
                        <div className="top-selection-bar">
                            <div
                                className={`select-bar active-bar`}>
                                <div className="active-inner-text">
                                    <h4>Contact Us</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="mt_5">
                                <form>
                                    <div className="form-flex-2">
                                        <div className="form_group">
                                            <label>First name</label>
                                            <Controller name="first-name" control={control}
                                                render={({ field }) => (
                                                    <Input name="first-name" {...field} id="first-name"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                        <div className="form_group">
                                            <label>Last name</label>
                                            <Controller name="last-name" control={control}
                                                render={({ field }) => (
                                                    <Input name="last-name" {...field} id="last-name"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label>Email address</label>
                                        <Controller name="email" control={control}
                                            render={({ field }) => (
                                                <Input name="email" type="email" {...field} id="email"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="form_group">
                                        <label>Phone number</label>
                                        <Controller name="phone-number" control={control}
                                            render={({ field }) => (
                                                <Input name="phone-number" {...field} id="phone-number"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="form_group">
                                        <label>Message</label>
                                        <Controller name="narration" control={control}
                                            render={({ field }) => (
                                                <Input.TextArea rows={3} name="narration" {...field} id="narration" />
                                            )} />
                                    </div>
                                    <div className="welcome-buttons">
                                        <div className="welcome-buttons-content">
                                            {
                                                !loadingData ?
                                                    <button className="btn-base">Send a message</button>
                                                    :
                                                    <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterDisplay /> */}
        </div>
    )
}

export default ContactUsPage;