import "../../assets/css/mini.css";

import React, { useEffect, useState } from "react";

import { Input, notification, Drawer, Spin } from "antd";
import { DateTime } from 'luxon';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import AllAppRoutes from "../../common/routes";

import { ReactComponent as NotificationIcon } from "../../assets/images/icons/mobilenotification.svg";
import { ReactComponent as HelpIcon } from "../../assets/images/icons/help-circle.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";

import {
    _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_referral_bonus_account, _fetch_user_referral_data, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans,
    _get_all_account_details, _get_all_wise9ja, _get_profile, _get_referral_pool, _get_user_account_history, _user_dashboard_referrals, _wise9ja_topup
} from '../../common/axios_services';

import EmptyGIF from "../../assets/images/illustrations/no-entry.gif";

import FooterDisplay from "../../components/footer";
import { useSelector } from "react-redux";
import TopNav from "../../components/top-nav";

const ReferralPage = () => {

    const Navigate = useNavigate();

    const userProfile = useSelector(state => state.userProfile.dataFetched);


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };
    const [referralSummary, setReferralSummary] = useState({});
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [currentHistDisp, setCurrentHistDisp] = useState("wallet");

    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    let jwt_token = localStorage.getItem('konnect_token');

    const [majorPageSpinner, setMajorPageSpinner] = useState(true);

    const [bonusHistory, setBonusHistory] = useState([]);
    const [referralHistory, setReferralHistory] = useState([]);

    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(5);
    const [referralPool, setReferralPool] = useState(false);
    const [referralPoolChange, setReferralPoolChange] = useState(userProfile.is_sa ? 'sa' : userProfile.is_so ? 'so' : '');
    const [loadingReferralPool, setLoadingReferralPool] = useState(true);
    const [displaySalesAgentMessage, setDisplaySalesAgentMessage] = useState(false);

    const getReferralSummary = async () => {
        try {
            const referrals = await _user_dashboard_referrals();
            if (referrals.data.status === "success") {
                setLoadingSpinner(false);
                setReferralSummary(referrals.data.data);
            } else {
                openNotificationWithIcon('error', referrals.data.message);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const getVerifiedReferralInfo = async () => {
        try {
            const referrals = await _fetch_user_referral_data(page, page_size);
            if (referrals.data.status === "success") {
                console.log(referrals.data.data)
                setReferralHistory(referrals.data.data);
            } else {
                openNotificationWithIcon('error', referrals.data.message);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const getReferralBonusData = async () => {
        setMajorPageSpinner(true);
        try {
            const data = await _fetch_user_referral_bonus_account(page, page_size);
            setBonusHistory(data.data.data);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token');
                    Navigate('/signin');
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon("error", 'An error occurred while fetching data. Please check network and try again.');
            } else {
                openNotificationWithIcon("error", 'An error occurred while fetching data. Please reload page to try again.');
            }
            setMajorPageSpinner(false);
        }
    }

    React.useEffect(() => {
        if (jwt_token) {
            getReferralBonusData();
            getReferralSummary();
            getVerifiedReferralInfo();
        } else {
            Navigate("/signin");
        }
    }, [])


    const getReferralPool = async () => {
        setLoadingReferralPool(true)
        try {
            const referral_pool = await _get_referral_pool();
            setReferralPool(referral_pool.data.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    Navigate('/signin')
                    localStorage.removeItem('konnect_token')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        } finally {
            setLoadingReferralPool(false)
        }
    }

    useEffect(() => {
        getReferralSummary();
        getReferralPool()
    }, [])

    const handleChange = (pool) => {
        setReferralPoolChange(pool)
    }

    const toggleSalesAgentMessage = () => setDisplaySalesAgentMessage(!displaySalesAgentMessage);

    return (
        <div className="wise9ja-content">
            <div>
                <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
                    <div className="homepage wise9ja-disp">
                        <div className="homepage-history-header">
                            <TopNav title="Your Referral Information" />
                            <div className="contain mt_5">
                                <div className="grid-2 content-grid-cover">
                                    <div className="content-summary-box _1">
                                        <div className="content-summary-info">
                                            <h4>{!loadingSpinner ? <>{referralSummary.signup_referal}</> : "-"}</h4>
                                            <p>Verified referral</p>
                                        </div>
                                    </div>
                                    <div className="content-summary-box _2">
                                        <div className="content-summary-info">
                                            <h4>{!loadingSpinner ? <><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                className="numeric" displayType={'text'} value={(+referralSummary.bonus_balance).toFixed(2)} /></> : "-"}</h4>
                                            <p>Bonus Earned</p>
                                        </div>
                                    </div>
                                    <div className="content-summary-box _3">
                                        <div className="content-summary-info">
                                            <h4>{!loadingSpinner ? <><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                className="numeric" displayType={'text'} value={(+referralSummary.ra_income_bonus).toFixed(2)} /></> : "-"}</h4>
                                            <p>RA income</p>
                                        </div>
                                    </div>
                                    <div className="content-summary-box _4">
                                        {(userProfile?.is_sa || userProfile?.is_so) ?
                                            <div className="content-summary-info">
                                                {userProfile?.is_sa ?
                                                    <div onClick={() => setDisplaySalesAgentMessage(true)}>
                                                        <h4>
                                                            <span className="currency">NGN</span> {!loadingSpinner ?
                                                                <NumberFormat thousandSeparator={true} displayType={'text'} value={(+referralPool?.sa_referal_pool).toFixed(2)} />
                                                                : ""}
                                                        </h4>
                                                    </div> : ""
                                                }
                                                {/* {userProfile?.is_so && referralPool?.so_referral_pool?.length ?
                                                    <div onClick={() => setDisplaySalesAgentMessage(true)}>
                                                        <h4>
                                                            <span className="currency">NGN</span> {!loadingSpinner ?
                                                                <NumberFormat thousandSeparator={true} displayType={'text'} value={(+referralPool?.so_referral_pool).toFixed(2)} />
                                                                : ""}
                                                        </h4>
                                                    </div>
                                                    : ""
                                                } */}
                                                <p>Sales Referral Pool <InfoIcon style={{ width: 10 }} /></p>
                                            </div>
                                            :
                                            <div className="content-summary-info">
                                                <h4>{!loadingSpinner ? <>{referralSummary.ra_transactions}</> : "-"}</h4>
                                                <p>RA transactions</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt_2">
                                    <Link style={{ width: "100%", display: "block", textAlign: "center" }} to={AllAppRoutes.how_referral_works} className="btn-green">How Referral Works</Link>
                                </div>
                            </div>
                        </div>
                        <div className="hero-sect mt_2">
                        </div>
                        <div className="content-display-content">
                            <div className="contain">
                                <div className="wise9ja-action-bar">

                                </div>
                                <div className="mt history-data-detail">
                                    <div className="top-history-header">
                                        <div
                                            onClick={() => { setCurrentHistDisp("wallet") }}
                                            className={`history-box ${currentHistDisp === "wallet" ? "active-history" : ""}`}>
                                            <h3 className="tile-header">Verified Referrals History</h3>
                                        </div>
                                        <div
                                            onClick={() => { setCurrentHistDisp("transaction") }}
                                            className={`history-box ${currentHistDisp === "transaction" ? "active-history" : ""}`}>
                                            <h3 className="tile-header">Referral Income History</h3>
                                        </div>
                                    </div>
                                    <div className="contai">
                                        {
                                            !majorPageSpinner ?
                                                currentHistDisp === "wallet" ?
                                                    referralHistory.length ?
                                                        referralHistory.map((history, index) => (
                                                            <div className={`current-history-display ${index === 0 ? "first-history-disp" : ""}`} key={`loy-${index}`}>
                                                                <div className="disp-category">
                                                                    <h3>VT</h3>
                                                                </div>
                                                                <div>
                                                                    <h3>{history?.user_name?.slice(0, 1)?.toUpperCase()}{history?.user_name?.slice(1)}</h3>
                                                                    <ul>
                                                                        <li>{DateTime.fromISO(history?.created_at).toLocaleString(DateTime.DATE_HUGE)};</li>
                                                                        <li>{DateTime.fromISO(history.created_at).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <h3 className="history-amount">+<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={Math.round(Number(history?.signup_bonus) * 1).toFixed(2)} /></h3>
                                                                    <p className={`credit status`}>credit</p>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div>
                                                            <div className="empty-data">
                                                                <div>
                                                                    <img src={EmptyGIF} alt="failure gif" />
                                                                    <p>No data found</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                    bonusHistory.length ?
                                                        bonusHistory.map((history, index) => (
                                                            <div className={`current-history-display ${index === 0 ? "first-history-disp" : ""}`} key={`loy-${index}`}>
                                                                <div className="disp-category">
                                                                    <h3>{history?.service?.slice(0, 1)?.toUpperCase()}T</h3>
                                                                </div>
                                                                <div>
                                                                    <h3>{history?.service?.slice(0, 1)?.toUpperCase()}{history?.service?.slice(1)} Transaction</h3>
                                                                    <ul>
                                                                        <li>{DateTime.fromISO(history?.timestamp).toLocaleString(DateTime.DATE_HUGE)};</li>
                                                                        <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <h3 className="history-amount">{history?.payment_type === "credit" ? "+" : "-"}<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={Math.round(Number(history?.amount) * 1).toFixed(2)} /></h3>
                                                                    <p className={`${history.payment_type} status`}>{history?.payment_type}</p>
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div>
                                                            <div className="empty-data">
                                                                <div>
                                                                    <img src={EmptyGIF} alt="failure gif" />
                                                                    <p>No data found</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
            <FooterDisplay />
            <Drawer footer={null} onClose={toggleSalesAgentMessage} open={displaySalesAgentMessage} placement="bottom">
                <div className="modal-disp empty">
                    <div className="extra-detail">
                        <div className="contain">
                            <HelpIcon style={{ height: "4rem", margin: "0px auto", width: "auto", display: "block", marginBottom: 10 }} />
                            <p>Sales referral pool is the cash you get when you refer food vendors</p>
                            <button onClick={toggleSalesAgentMessage} style={{ display: "block", textAlign: "center" }} className="btn btn-red">Close</button>
                        </div>
                    </div>
                </div>
            </Drawer>
        </div >
    )
}

export default ReferralPage;