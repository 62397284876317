import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";

const TopCard = props => {
    const Navigate = useNavigate();
    return (
        <div>
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <h5>{props.pageTitle}</h5>
                    <NotificationIcon />
                </div>
            </div>
        </div>
    )
}

export default TopCard;