import React from "react";

import { Input } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/mobilecart.svg";
import TopCircle from "../components/top-circle";

const CategoriesPage = () => {

    return (
        <div className="homepage">
            <TopCircle>
                <div className="grid-flex">
                    <div>
                        <div className="icon-box">
                            <NotificationIcon />
                        </div>
                    </div>
                    <div>
                        <h3>Vegetables</h3>
                    </div>
                    <div>
                        <div className="icon-box">
                            <CartIcon />
                        </div>
                    </div>
                </div>
            </TopCircle>
            <div className="hero-sect">
                <div className="contain">
                    <div className="extra-data-message mt_5">
                        <div className="extra-data-content">
                            <h3>Get 10% off groceries with Plus+ T&C Apply</h3>
                            <div>
                                <p>Spend $30.00 to get 5%</p>
                            </div>
                        </div>
                        <div className="extra-div _1"></div>
                        <div className="extra-div _2"></div>
                        <div className="extra-div _3"></div>
                    </div>
                </div>
            </div>
            <div className="contain mt_5">
                <div className="product-bar">
                    <h4 className="sub-heading">Products you might need</h4>
                    <div>
                        <div className="grid-2">
                            <div className="product-box-info">
                                <div className="product-box-image">

                                </div>
                                <h4>Plant Hunter (Frozen pack)</h4>
                                <p>500 grams</p>
                                <h3><span className="currency">NGN</span>5,000.00</h3>
                                <button>Add to Cart</button>
                            </div>
                            <div className="product-box-info">
                                <div className="product-box-image">

                                </div>
                                <h4>Plant Hunter (Frozen pack)</h4>
                                <p>500 grams</p>
                                <h3><span className="currency">NGN</span>5,000.00</h3>
                                <button>Add to Cart</button>
                            </div>
                            <div className="product-box-info">
                                <div className="product-box-image">

                                </div>
                                <h4>Plant Hunter (Frozen pack)</h4>
                                <p>500 grams</p>
                                <h3><span className="currency">NGN</span>5,000.00</h3>
                                <button>Add to Cart</button>
                            </div>
                            <div className="product-box-info">
                                <div className="product-box-image">

                                </div>
                                <h4>Plant Hunter (Frozen pack)</h4>
                                <p>500 grams</p>
                                <h3><span className="currency">NGN</span>5,000.00</h3>
                                <button>Add to Cart</button>
                            </div>
                            <div className="product-box-info">
                                <div className="product-box-image">

                                </div>
                                <h4>Plant Hunter (Frozen pack)</h4>
                                <p>500 grams</p>
                                <h3><span className="currency">NGN</span>5,000.00</h3>
                                <button>Add to Cart</button>
                            </div>
                            <div className="product-box-info">
                                <div className="product-box-image">

                                </div>
                                <h4>Plant Hunter (Frozen pack)</h4>
                                <p>500 grams</p>
                                <h3><span className="currency">NGN</span>5,000.00</h3>
                                <button>Add to Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoriesPage;