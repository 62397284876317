import React, { useEffect, useState } from "react";

import { Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import 'swiper/css';

import NumberFormat from "react-number-format";

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";
import { _check_user_transfer_charges, _complete_bank_transfer, _complete_wallet_transfer, _confirm_user_transfer_pin, _fetch_amount_trans_fee, _fetch_bank_list, _fund_user_wallet, _get_profile, _verify_user_bank_account, _verify_user_from_ref_code } from "../../../common/axios_services";
import RequestTransactionCode from "../../../components/profile/transcode";
import AppRoutes from "../../../common/routes";
import { useDispatch } from "react-redux";
import { fetchData, profileData } from "../../../slices/profileSlice";
import TopNav from "../../../components/top-nav";


const TransferBetweenAccounts = () => {

    const Navigate = useNavigate();

    const dispatch = useDispatch();
    const [jwt] = useState(localStorage.getItem("konnect_token"));

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const secAntIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const subAntIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const [userData, setUserData] = useState({});

    // start form handlers

    const [userTransPin, setUserTransPin] = useState('');
    const [pageLoader, setPageLoader] = useState(true);
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);

    // wallet to wallet info
    const [loadingWalletTransFee, setLoadingWalletTransFee] = useState(false);
    const [loadedTransData, setLoadedTransData] = useState(false);
    const [transferToWalletTransFee, setTransferToWalletTransFee] = useState("");
    const [walletWithdrawalAmount, setWalletWithdrawalAmount] = useState("");
    const [walletWithdrawalAmountError, setWalletWithdrawalAmountError] = useState("");

    // user transfer
    const navigate = useNavigate();


    // Wallet to Wallet
    const { handleSubmit } = useForm({});

    const [walletNarration, setWalletTransferNarration] = useState("");
    const [walletTransferAmount, setWalletTransferAmount] = useState("");
    const [loadingWalletData, setLoadingWalletData] = useState(false);
    const [walletErrorMessage, setWalletErrorMessage] = useState("");
    const [transfer_from, setTransferFrom] = useState("wallet");
    const [transfer_to, setTransferTo] = useState("konnect");

    const updateTransferAmount = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setLoadedTransData(false);
        setWalletTransferAmount(amount);
        setWalletWithdrawalAmount(amount);
    }

    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            let userData = user_profile?.data.data;
            setUserData(userData);
            setPageLoader(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
            setPageLoader(false);
        }
    }
    useEffect(() => {
        if (jwt) {
            fetchUser();
        } else {
            setPageLoader(false);
        }
    }, [])

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);
    const handleWalletTransferForm = e => {
        controlTransCodeModalDisplay();
    }

    const checkUserTransFeeForTransfer = async () => {
        setLoadingWalletTransFee(true);
        setWalletWithdrawalAmountError("");
        setTransferToWalletTransFee("");
        try {
            const profile = await _fetch_amount_trans_fee({
                settings_id: String(15),
                amount: walletWithdrawalAmount
            });
            setTransferToWalletTransFee(profile.data.charges);
            setLoadingWalletTransFee(false);
            setLoadedTransData(true);
        } catch (err) {
            if (err?.response?.data?.message === "Unuthorized") {
                Navigate("/signin");
            }
            setWalletWithdrawalAmountError(err?.response?.data?.message ? err.response.data.message : err.message);
            setLoadingWalletTransFee(false);
        }
    }

    const changeTransferFromOption = e => {
        setTransferFrom(e);
        setTransferTo(e === "wallet" ? "konnect" : "wallet");
        // console.log()
    }

    const changeTransferToOption = e => {
        setTransferTo(e);
        setTransferFrom(e === "wallet" ? "konnect" : "wallet");
    }

    // fetch charges for transfer to friend
    useEffect(() => {
        let userRefTimeout = null;
        if (walletWithdrawalAmount.length > 2) {
            userRefTimeout = setTimeout(checkUserTransFeeForTransfer, 2000);
        }
        return () => clearTimeout(userRefTimeout);
    }, [walletWithdrawalAmount]);

    const completeWalletTransfer = async () => {
        setLoadingWalletData(true);
        setPageLoader(true);
        setWalletErrorMessage('');
        const data = {
            account_type: transfer_from,
            amount: walletWithdrawalAmount,
            ben_account_type: transfer_to,
            charges: transferToWalletTransFee,
            debit_currency: "NGN",
            narration: walletNarration,
            referal_no: userData.ref_code,
            transfer_opt: "internal"
        }
        try {
            let transferData = await _complete_wallet_transfer(data);
            if (transferData.data.status === "success") {
                openNotificationWithIcon('success', 'Transfer completed successfully');
                let transData = transferData.data.data;
                Navigate(`${AppRoutes.wallet}`);
                // Navigate(`${AppRoutes.action_status_page}?payment_id=${transData.payment_id}&reference_key=${transData.reference}`);
            } else {
                setWalletErrorMessage(transferData.data.message);
                openNotificationWithIcon('error', transferData.data.message);
                setLoadingWalletData(false);
                setPageLoader(false);
            }
        } catch (err) {
            let errMessage = err?.response?.data?.message ? err.response.data.message : err.message;
            if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while completing transfer. Please check network and try again')
                setWalletErrorMessage('An error occurred while completing transfer. Please check network and try again');
            } else {
                openNotificationWithIcon('error', errMessage);
                setWalletErrorMessage(errMessage);
            }
            setLoadingWalletData(false);
            setPageLoader(false);
        }
    }

    const checkUserPinForWalletTransfer = async e => {
        setLoadingWalletData(true);
        setWalletErrorMessage('');
        controlTransCodeModalDisplay();
        setPageLoader(true);
        try {
            const profile = await _confirm_user_transfer_pin(e);
            if (profile.data.status === "success") {
                completeWalletTransfer();
            } else {
                setLoadingWalletData(false);
                setWalletErrorMessage(profile.data.message);
                setPageLoader(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    setWalletErrorMessage('An error occurred while checking pin. Please check network and try again');
                } else if (err?.response?.data?.message === "Unauthorized") {
                    setWalletErrorMessage('User Authorization expired. Please log in to continue');
                    navigate('/signin');
                }
                else {
                    setWalletErrorMessage(err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                }
            } else {
                setWalletErrorMessage('An error occurred while checking pin. Please try again');
            }
            setLoadingWalletData(false);
            setPageLoader(false);
        }
    }

    return (
        <Spin spinning={pageLoader} indicator={secAntIcon}>
            <div className="display-page">
                <TopNav title="Transfer Between Accounts" />
                <div className="display-main-content mt_3">
                    <div className="hero-sect">
                        <div className="container">
                            {/* <h3 className="sec-page-title">Transfer to Account</h3> */}
                            <p className="form-explanation">
                                All financial details are stored to PCIDSS level 1 and transmitted via an encrypted 256-bit Https SSL connection.
                            </p>
                            <div>
                                <div className="mt_2">
                                    <div>
                                        {
                                            walletErrorMessage.length ?
                                                <p className="form-error"
                                                    style={{ marginBottom: 30, display: "block" }}>{walletErrorMessage}</p>
                                                : ""
                                        }
                                        <form onSubmit={handleSubmit(handleWalletTransferForm)}>
                                            <div className="form_group">
                                                <div className="grid-flex">
                                                    <label>Amount</label>
                                                    {
                                                        loadingWalletTransFee ?
                                                            <Spin indicator={subAntIcon} />
                                                            : transferToWalletTransFee.length ?
                                                                <p><span className="currency">NGN </span><NumberFormat thousandSeparator={true}
                                                                    value={(+transferToWalletTransFee)?.toFixed(2)}
                                                                    className="numeric" displayType="text"
                                                                /></p> : ""
                                                    }
                                                </div>
                                                <div>
                                                    <NumberFormat thousandSeparator={true}
                                                        prefix={'NGN '}
                                                        className="numeric"
                                                        onInput={e => updateTransferAmount(e)}
                                                        style={{ height: '5rem', width: '100%' }} />
                                                    {/* <Input name="amount" onChange={updateTransferAmount} id="amount"
                                                        value={walletTransferAmount} style={{ height: "5rem" }} /> */}
                                                    {walletWithdrawalAmountError.length ? <p className="form-error">{walletWithdrawalAmountError}</p> : ""}
                                                </div>
                                            </div>
                                            <div className="grid-flex" style={{ gridGap: 10, alignItems: "center" }}>
                                                <div className="form_group">
                                                    <div className="grid-flex" style={{ alignItems: "center" }}>
                                                        <label>Transfer From</label>
                                                    </div>
                                                    <div>
                                                        <Select name="transferFrom" defaultValue={transfer_from} value={transfer_from}
                                                            onChange={changeTransferFromOption}
                                                            style={{ height: "5rem", width: "100%" }}>
                                                            <Select.Option value="wallet">Wallet Balance</Select.Option>
                                                            <Select.Option value="konnect">Konnect Balance</Select.Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>&mdash;</p>
                                                </div>
                                                <div className="form_group">
                                                    <div className="grid-flex" style={{ alignItems: "center" }}>
                                                        <label>Transfer To</label>
                                                    </div>
                                                    <div>
                                                        <Select name="transferTo" defaultValue="" value={transfer_to}
                                                            onChange={changeTransferToOption}
                                                            style={{ height: "5rem", width: "100%" }}>
                                                            <Select.Option value="wallet">My Wallet Balance</Select.Option>
                                                            <Select.Option value="konnect">My Konnect Balance</Select.Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="welcome-buttons mt_15">
                                                <div className="welcome-buttons-content">
                                                    {
                                                        !loadingWalletData ?
                                                            <button
                                                                disabled={!loadedTransData ? true : false}
                                                                className="btn-green">Continue</button>
                                                            :
                                                            <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={controlTransCodeModalDisplay}
                    inputCount={4} actionFunction={checkUserPinForWalletTransfer} stateFunc={setUserTransPin} />
            </div>
        </Spin>
    )
}

export default TransferBetweenAccounts;