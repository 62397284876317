import React from "react";
import ReactDOM from "react-dom/client";
import AppRoutes from "./routes";

const App = () => {
    return (
        <div>
            <AppRoutes />
        </div>
    )
}

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(<App />)