import "../../assets/css/dashboard.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import { ReactComponent as HomeIcon } from "../../assets/images/icons/home.svg";
import { ReactComponent as CartIcon } from "../../assets/images/icons/shopping-cart.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/notification_white.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/images/icons/archive.svg";
import { ReactComponent as CreditCardIcon } from "../../assets/images/icons/credit-card.svg";
import { ReactComponent as PocketsIcon } from "../../assets/images/icons/pocket.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/camera.svg";
import { ReactComponent as UserIcon } from "../../assets/images/icons/user.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/users.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/icons/log-out.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/info.svg";

import NumberFormat from 'react-number-format';

import EmptyGif from "../../assets/images/illustrations/empty.gif";

import FolderIcon from "../../assets/images/illustrations/12_folder.png";
import FooterDisplay from "../../components/footer";

import { fetchData, profileData } from "../../slices/profileSlice";
import AllAppRoutes from "../../common/routes";
import { _confirm_user_transfer_pin, _generate_otp, _get_profile, _update_user_type, _user_dashboard_referrals, _verify_otp } from "../../common/axios_services";
import { useDispatch } from "react-redux";
import { Drawer, Spin, Upload, notification } from "antd";
import RequestTransactionCode from "../../components/profile/transcode";

const HistoryDetailPage = () => {

    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [jwt] = useState(localStorage.getItem("konnect_token"));

    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };

    const [referralSummary, setReferralSummary] = useState({});
    const [loadingSpinner, setLoadingSpinner] = useState(true);
    const [userData, setUserData] = useState({});
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [userPin, setUserPin] = useState('');
    const [loadingData, setLoadingData] = useState(false);
    const [transCodeDrawer, setTransCodeDrawer] = useState(false);
    const [otpCounter, setOtpCounter] = useState(false);
    const [userType, setUserType] = useState("basic");
    const [displaySalesAgentMessage, setDisplaySalesAgentMessage] = useState(false);

    const toggleTransactionCodeDrawer = () => {
        setTransCodeDrawer(!transCodeDrawer);
    }
    const toggleTransCodeModalDisplay = () => {
        setDisplayTransCodeModal(!displayTransCodeModal);
        setOtpCounter(true);
    };
    const toggleOTPCounter = () => setOtpCounter(!otpCounter);

    const getReferralSummary = async () => {
        try {
            // const referrals = await _get_all_referrals({ page, page_size })
            const referrals = await _user_dashboard_referrals();
            if (referrals.data.status === "success") {
                setLoadingSpinner(false);
                setReferralSummary(referrals.data.data);
            } else {
                openNotificationWithIcon('error', referrals.data.message);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            // openNotificationWithIcon('error', 'An error occurred while loading referral history');
        }
    }
    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            dispatch(profileData(user_profile?.data?.data));
            let userData = user_profile?.data.data;
            setUserData(userData);
            setUserType(userData.is_ra ? "ra" : "basic");
            setLoadingSpinner(false);
            console.log(userData)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
            setLoadingSpinner(false);
        }
    }
    useEffect(() => {
        if (jwt) {
            fetchUser();
            // getReferralSummary();
        } else {
            setLoadingSpinner(false);
        }
    }, [])

    const logout = () => {
        localStorage.removeItem('konnect_token')
        Navigate("/signin");
    }

    const sendOTPToUser = async () => {
        setLoadingSpinner(true);
        try {
            const generate_otp = await _generate_otp();
            openNotificationWithIcon('success', generate_otp?.data?.message);
            setLoadingSpinner(false);
            toggleTransCodeModalDisplay();
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                    Navigate('/signin')
                }
                openNotificationWithIcon("error", err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
            setLoadingSpinner(false);
        }
    }

    const VerifyOtp = async () => {
        setLoadingSpinner(true);
        try {
            const verifyOtp = await _verify_otp({ otp: userPin });
            setLoadingSpinner(false);
            openNotificationWithIcon('success', verifyOtp?.data?.message);
            toggleTransactionCodeDrawer();
        } catch (err) {
            setLoadingSpinner(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                    Navigate('/signin')
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    const verifyUserPin = async e => {
        setLoadingSpinner(true);
        try {
            const profile = await _confirm_user_transfer_pin(userPin);
            toggleTransactionCodeDrawer();
            setLoadingSpinner(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    openNotificationWithIcon('error', 'An error occurred while checking pin. Please check network and try again');
                } else if (err?.response?.data?.message === "Unauthorized") {
                    openNotificationWithIcon('error', 'User Authorization expired. Please log in to continue');
                    Navigate('/signin');
                }
                else {
                    openNotificationWithIcon('error', err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', 'An error occurred while checking pin. Please try again');
            }
            setLoadingSpinner(false);
        }
    }

    const updateUserType = async () => {
        setLoadingSpinner(true)
        try {
            const changeType = await _update_user_type({ status: !userData.is_ra });
            setLoadingSpinner(false);
            openNotificationWithIcon('success', changeType?.data?.message);
            let currType = userData.is_ra;
            if (currType) {
                setUserType("basic");
            } else {
                setUserType("ra");
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                    Navigate('/signin')
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
        setLoadingSpinner(false)
    }

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const toggleSalesAgentMessage = () => setDisplaySalesAgentMessage(!displaySalesAgentMessage);

    return (
        <Spin spinning={loadingSpinner} indicator={newAntIcon}>
            <div className="profile-display settings-display detail">
                <div className="settings-detail-summary">
                    {
                        jwt ?
                            <>
                                <div className="profile-first-sect">
                                    <div className="top-body-box">
                                        <div className="grid-flex">
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                                            <div onClick={logout} className="logout"><LogoutIcon /></div>
                                        </div>
                                    </div>
                                    <div className="contain">
                                        <Upload {...props}>
                                            <div className="avatar-blk-cover">
                                                <CameraIcon />
                                            </div>
                                        </Upload>
                                        <div>
                                            <h4>{userData.first_name} {userData.last_name}</h4>
                                            {/* <p>Referral code: {userData.ref_code}</p> */}
                                            <div>
                                                <button onClick={toggleTransCodeModalDisplay} className="btn-blank" to="">View Details</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="extra-detail">
                                    <div className="contain">
                                        <ul>
                                            <li className="grid-flex">
                                                <span>Transaction Ref</span>
                                                <span>{userData.email}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Date</span>
                                                <span>{userData.ref_code}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Amount</span>
                                                <span>{userData.pin}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Balance</span>
                                                <span>{userData.pin}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Payment Mode</span>
                                                <span>{userData.pin}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Payment Status</span>
                                                <span>{userData.pin}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Status</span>
                                                <span>{userData.pin}</span>
                                            </li>
                                            <li className="grid-flex">
                                                <span>Description</span>
                                                <span>{userData.pin}</span>
                                            </li>

                                        </ul>
                                        <button onClick={toggleTransactionCodeDrawer} className="btn btn-red">Go Back</button>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="center-content short mt_5">
                                <div className="center-content-text">
                                    <img src={FolderIcon} alt="folder" />
                                    <h4>Create a Konnect Account</h4>
                                    <p>Konnect allows you to enjoy instant and monthly cash reward from product offers and commision when you refer friends. Sign-up
                                        now to get started.</p>
                                    <div>
                                        <div className="">
                                            <Link className="btn-base" to={AllAppRoutes.signup}>Create account now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>

                <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={toggleTransCodeModalDisplay}
                    inputCount={4} actionFunction={verifyUserPin} stateFunc={setUserPin} />
            </div>
        </Spin >
    )
}

export default HistoryDetailPage;