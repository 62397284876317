import React from "react";

import { Input, Drawer, Divider } from "antd";

import TopCard from "../components/top-card";

import FooterDisplay from "../components/footer";

import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";
import MeatImg from "../assets/images/categories/meat.png";
import OilImg from "../assets/images/categories/oil.png";
import PlantainImg from "../assets/images/categories/plantain.png";
import SnacksImg from "../assets/images/categories/snacks.png";
import BabyCareImg from "../assets/images/categories/baby.png";
import FrozenImg from "../assets/images/categories/frozen.png";
import PersonalImg from "../assets/images/categories/personal.png";

const DiscoverPage = () => {
    return (
        <div>
            <TopCard pageTitle="Discover" />
            <div className="contain mt_2 display-main-content">
                <div className="search-bar mt_3">
                    <div>
                        <div className="search-icon-cover">
                            <SearchIcon />
                        </div>
                    </div>
                    <Input name="searchKey" placeholder='Search for "Groceries"' className="search-bar-input" />
                </div>
                <div className="divider-line mt_3"></div>
                <div className="hero-sect mt_3">
                    <div className="contain">
                        <div className="extra-data-message">
                            <div className="extra-data-content">
                                <h3>Get 10% off groceries with Plus+ T&C Apply</h3>
                                <div>
                                    <p>Spend $30.00 to get 5%</p>
                                </div>
                            </div>
                            <div className="extra-div _1"></div>
                            <div className="extra-div _2"></div>
                            <div className="extra-div _3"></div>
                        </div>
                    </div>
                </div>
                <div className="grid-2 mt_3">
                    <div className="discover-category-box">
                        <div>
                            <h4>Meat</h4>
                            <p>3 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={MeatImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Vegetable Oils</h4>
                            <p>13 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={OilImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Kitchen</h4>
                            <p>10 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={PlantainImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Snacks</h4>
                            <p>5 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={SnacksImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Baby Care</h4>
                            <p>10 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={BabyCareImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Frozen Foods</h4>
                            <p>30 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={FrozenImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Personal Use</h4>
                            <p>50 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={PersonalImg} alt="category image" />
                        </div>
                    </div>
                    <div className="discover-category-box">
                        <div>
                            <h4>Meat</h4>
                            <p>3 products</p>
                        </div>
                        <div className="discover-cat-img">
                            <img src={MeatImg} alt="category image" />
                        </div>
                    </div>
                </div>
            </div>
            <FooterDisplay />
        </div>
    )
}

export default DiscoverPage;