import React, { useState } from "react";

import { Input, Divider, Drawer } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

import { Link } from "react-router-dom";

import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye.svg";
import { ReactComponent as EyesShutIcon } from "../../assets/images/icons/eye-off.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/images/icons/arrow-right-circle.svg";

import { ReactComponent as CartIcon } from "../../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/icons/mobilenotification.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/mobilecart.svg";

import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left.svg";
import TopCircle from "../../components/top-circle";


import Mobile from "../../assets/images/mobile.jpg";
import MeatImg from "../../assets/images/categories/meat.png";
import OilImg from "../../assets/images/categories/oil.png";
import PlantainImg from "../../assets/images/categories/plantain.png";
import SnacksImg from "../../assets/images/categories/snacks.png";
import BabyCareImg from "../../assets/images/categories/baby.png";
import FrozenImg from "../../assets/images/categories/frozen.png";
import PersonalImg from "../../assets/images/categories/personal.png";
import FooterDisplay from "../../components/footer";
import { Controller, useForm } from "react-hook-form";
import TopCard from "../../components/top-card";


const Wise9jaPage = () => {

    const { control, handleSubmit } = useForm({});

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openFundWalletDrawer, setOpenFundWalletDrawer] = useState(false);
    const [activeDisplay, setActiveDisplay] = useState('wallet');


    const onCloseDrawer = () => setOpenDrawer(false);
    const onCloseFundWalletDrawer = () => setOpenFundWalletDrawer(false);

    const [hideWalletBalance, setHideWalletBalance] = useState(false);

    const changeWalletBalanceVisibility = () => setHideWalletBalance(!hideWalletBalance);

    return (
        <div className="homepage wise9ja-dis">
            <TopCard pageTitle="Transactions History" />
            <div className="history-disp grid-2">
                <div
                    onClick={() => setActiveDisplay("wallet")}
                    className={`${activeDisplay === "wallet" ? "active-history" : ""} history-header`}>
                    <h4>Wallet History</h4>
                </div>
                <div
                    onClick={() => setActiveDisplay("order")}
                    className={`${activeDisplay === "order" ? "active-history" : ""} history-header`}>
                    <h4>Order History</h4>
                </div>
            </div>
            <div className="display-main-content content-display-content">
                <div className="contain">
                    <div className="hero-sect">
                        <div className="contain">
                            <div className="extra-data-message mt_3">
                                <div className="extra-data-content">
                                    <h3>Get 10% off groceries with Plus+ T&C Apply</h3>
                                    <div>
                                        <p>Spend $30.00 to get 5%</p>
                                    </div>
                                </div>
                                <div className="extra-div _1"></div>
                                <div className="extra-div _2"></div>
                                <div className="extra-div _3"></div>
                            </div>
                        </div>
                    </div>
                    <div className="wise9ja-action-bar">

                    </div>
                    <div className="mt_3 history-data-detail">
                        <div className="contain">
                            <div className="first-history-disp current-history-display">
                                <div className="disp-category"></div>
                                <div>
                                    <h3>Wise9ja Transaction</h3>
                                    <ul>
                                        <li>Dec 30th,</li>
                                        <li>03:15pm</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="history-amount">-<span className="currency">NGN</span>15,800.00</h3>
                                    <p className="status success">successful</p>
                                </div>
                            </div>
                            <Divider style={{ margin: "20px 0px" }} />
                            <div className="current-history-display">
                                <div className="disp-category"></div>
                                <div>
                                    <h3>Wise9ja Transaction</h3>
                                    <ul>
                                        <li>Dec 30th,</li>
                                        <li>03:15pm</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="history-amount">+<span className="currency">NGN</span>15,800.00</h3>
                                    <p className="status success">successful</p>
                                </div>
                            </div>
                            <Divider style={{ margin: "20px 0px" }} />
                            <div className="current-history-display">
                                <div className="disp-category"></div>
                                <div>
                                    <h3>Wise9ja Transaction</h3>
                                    <ul>
                                        <li>Dec 30th,</li>
                                        <li>03:15pm</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="history-amount">+<span className="currency">NGN</span>15,800.00</h3>
                                    <p className="status success">successful</p>
                                </div>
                            </div>
                            <Divider style={{ margin: "20px 0px" }} />
                            <div className="first-history-disp current-history-display">
                                <div className="disp-category"></div>
                                <div>
                                    <h3>Wise9ja Transaction</h3>
                                    <ul>
                                        <li>Dec 30th,</li>
                                        <li>03:15pm</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="history-amount">-<span className="currency">NGN</span>15,800.00</h3>
                                    <p className="status success">successful</p>
                                </div>
                            </div>
                            <Divider style={{ margin: "20px 0px" }} />
                            <div className="current-history-display">
                                <div className="disp-category"></div>
                                <div>
                                    <h3>Wise9ja Transaction</h3>
                                    <ul>
                                        <li>Dec 30th,</li>
                                        <li>03:15pm</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="history-amount">+<span className="currency">NGN</span>15,800.00</h3>
                                    <p className="status success">successful</p>
                                </div>
                            </div>
                            <Divider style={{ margin: "20px 0px" }} />
                            <div className="current-history-display">
                                <div className="disp-category"></div>
                                <div>
                                    <h3>Wise9ja Transaction</h3>
                                    <ul>
                                        <li>Dec 30th,</li>
                                        <li>03:15pm</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className="history-amount">+<span className="currency">NGN</span>15,800.00</h3>
                                    <p className="status success">successful</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterDisplay />
            <Drawer
                title="New Wise9ja Transaction"
                placement="bottom"
                closable={false}
                onClose={(onCloseDrawer)}
                open={openDrawer}
                key="bottom"
            >
                <div>
                    <div className="form_group">
                        <label htmlFor="amount">Amount to Pay</label>
                        <Controller name="amount" defaultValue="" control={control}
                            render={({ field }) => (
                                <Input name="amount" {...field} id="amount"
                                    style={{ height: "5rem" }} />
                            )} />
                    </div>
                    <div className="form_group">
                        <label htmlFor="wise9jaPlan">Wise9ja Plan</label>
                        <Controller name="wise9jaPlan" defaultValue="" control={control}
                            render={({ field }) => (
                                <Input name="wise9jaPlan" {...field} id="wise9jaPlan"
                                    style={{ height: "5rem" }} />
                            )} />
                    </div>
                    <div className="form_group">
                        <button
                            onClick={onCloseDrawer}
                            className="btn-base">Continue</button>
                    </div>
                    <div
                        onClick={onCloseDrawer}
                        className="form_group">
                        <button className="btn-blank">Cancel</button>
                    </div>
                </div>
            </Drawer>
            <Drawer
                title="Fund Wallet"
                placement="bottom"
                closable={false}
                onClose={(onCloseFundWalletDrawer)}
                open={openFundWalletDrawer}
                key="bottom"
            >
                <div>
                    <div className="form_group">
                        <label htmlFor="amount">Amount</label>
                        <Controller name="amount" defaultValue="" control={control}
                            render={({ field }) => (
                                <Input name="amount" {...field} id="amount"
                                    style={{ height: "5rem" }} />
                            )} />
                    </div>
                    <div className="form_group">
                        <button
                            onClick={onCloseFundWalletDrawer}
                            className="btn-base">Fund Wallet</button>
                    </div>
                    <div
                        onClick={onCloseFundWalletDrawer}
                        className="form_group">
                        <button className="btn-blank">Cancel</button>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Wise9jaPage;