import React, { useEffect, useState } from "react";

import { Divider, Input, Select, Spin, Tabs, notification } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from 'react-number-format';

import 'swiper/css';

import _1 from "../../../assets/_1.jpg"

import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";
import { _complete_bank_transfer, _confirm_user_transfer_pin, _fetch_bank_list, _fund_user_wallet, _verify_user_bank_account } from "../../../common/axios_services";
import AppRoutes from "../../../common/routes";
import FooterDisplay from "../../../components/footer";

import BlobGreenImg from "../../../assets/images/icons/blob_green.svg";
import BlobRedImg from "../../../assets/images/icons/blob_red.svg";


const FundWalletPage = () => {

    const Navigate = useNavigate();

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    // start form handlers
    const { handleSubmit: handleFundWalletSubmit, control: controlFundWallet, reset: resetFundWallet,
        watch: watchFundWallet, formState: { errors: errorFundWallet } } = useForm({});


    const [currentTransferOpt, setCurrentTransferOpt] = useState("wallet");
    const [currentDispTab, setCurrentDispTab] = useState("wallet");
    const [loadingData, setLoadingData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [fundUserWalletAmount, setfundUserWalletAmount] = useState(0);
    const [saveCardDetail, setSaveCardDetail] = useState(false);

    // fund user wallet amount
    const onChangeValue = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setfundUserWalletAmount(amount);
    }

    // user transfer
    const navigate = useNavigate();

    /* finalize fund user wallet */
    const finalizeFundWallet = async e => {
        setLoadingData(true);
        setErrorMessage("");
        try {
            const profile = await _fund_user_wallet({
                account_type: currentTransferOpt === "wallet" ? "wallet" : "konnect",
                amount: fundUserWalletAmount,
                save_card: saveCardDetail
            });
            if (profile.data.status === "success") {
                openNotificationWithIcon("success", "Transaction successful");
                Navigate(AppRoutes.wallet)
                // window.location = profile?.data?.data?.link ? profile?.data?.data?.link : profile?.data?.data?.response_data?.link;
                // setActiveFundWalletData(profile.data.data);
            } else {
                openNotificationWithIcon("error", profile.data.message);
                setLoadingData(false);
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                }
            } else if (err?.code === "ERR_NETWORK") {
                openNotificationWithIcon('error', 'An error occurred while funding wallet. Please check network and try again.')
            } else {
                openNotificationWithIcon('error', 'An error occurred while funding wallet. Please reload page to try again.')
            }
            setLoadingData(false);
        }
    }
    // transfer to friend


    return (
        <div className="display-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    <div>
                        <h3 className="sec-page-title">Fund Wallet</h3>
                    </div>
                    <div>
                        <NotificationIcon />
                    </div>
                </div>
            </div>
            <div className="display-main-content mt_3">
                <div className="hero-sect">
                    <div className="container">
                        <p className="form-explanation">Premium designed icons for use in web, iOS, Android, and desktop apps. Support for SVG. Completely open source.</p>
                        <div>
                            <div className="mt_5">
                                <div>
                                    {errorMessage.length ? <p className="form-error mb_3">{errorMessage}</p> : ""}
                                </div>
                                {/* <img src={_1} /> */}
                                <form onSubmit={handleFundWalletSubmit(finalizeFundWallet)}>
                                    <div className="form_group">
                                        <div className="grid-flex">
                                            <label>Amount</label>
                                            <p>0% charge</p>
                                        </div>
                                        <Controller name="amount" control={controlFundWallet}
                                            render={({ field }) => (
                                                <NumberFormat thousandSeparator={true}
                                                    prefix={'NGN '}
                                                    className="numeric"
                                                    onInput={e => onChangeValue(e)}
                                                    {...field} style={{ height: '5rem', width: '100%' }} />
                                            )} />
                                    </div>
                                    <div className="form_grou">
                                        <label>Account to fund</label>
                                        <div className="box-display">
                                            <div
                                                onClick={() => setCurrentTransferOpt("wallet")}
                                                className={`single-box ${currentTransferOpt === "wallet" ? "active" : ""}`}>
                                                <div>
                                                    <h3>Wallet <br /> <span>Balance</span></h3>
                                                    {/* <p><span>NGN </span>400,000.00</p> */}
                                                </div>
                                                <div className="blob-cover">
                                                    <img src={BlobRedImg} alt="blob" />
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => setCurrentTransferOpt("konnect")}
                                                className={`single-box _2 ${currentTransferOpt === "konnect" ? "active" : ""}`}>
                                                <div>
                                                    <h3>Konnect <br /> <span>Balance</span></h3>
                                                    {/* <p><span>NGN </span>0.00</p> */}
                                                </div>
                                                <div className="blob-cover">
                                                    <img src={BlobGreenImg} alt="blob" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="welcome-buttons mt_20">
                                        <div className="welcome-buttons-content">
                                            {
                                                !loadingData ?
                                                    <button className="btn btn-green">Continue</button>
                                                    :
                                                    <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
                <FooterDisplay />
            </div >
        </div >
    )
}

export default FundWalletPage;