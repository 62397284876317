import React, { useEffect, useState } from "react";

import { Input, Select, Spin, Tabs, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";


const PrivacyPolicy = () => {

    const Navigate = useNavigate();
    const [loadingData, setLoadingData] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    // start form handlers
    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm({});

    return (
        <div className="display-page">
            <div className="small-page-title contain">
                <div className="grid-flex">
                    <div
                        onClick={() => Navigate(-1)}>
                        <ArrowLeftIcon />
                    </div>
                    {/* <h5>Transfer Cash</h5> */}
                    <div></div>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                </div>
            </div>
            <div className="display-main-content mt_2">
                <div className="hero-sect">
                    <div className="container">
                        <h3 className="sec-page-title" style={{ marginBottom: 0 }}>Your Privacy Matters</h3>
                        <div>
                            <div className="faq_container">
                                <div style={{ marginTop: '20px' }}></div>
                                <p>
                                    This Privacy Policy describes your privacy rights regarding how and when we collect, use, store, share and
                                    protect your information across our website, APIs, applications, email notifications and tools
                                    regardless of how you use or access them.
                                    <br />
                                    Notwithstanding which country you live in, when using any of our services, you consent to the collection,
                                    transfer, storage, disclosure, and use of your information in Nigeria and any other country we operate
                                    as described in this privacy policy. This includes any information you choose to provide that is deemed
                                    sensitive under applicable law.
                                    <br /><br />
                                    We respect the privacy of our online visitors and registered users, and we will take reasonable steps to
                                    protect your information.
                                    <br /> <br />
                                    It is our policy to respect your privacy regarding any information we may collect while operating our website.
                                    <br /> <br />
                                    Accordingly, we have developed this privacy policy for you to understand how we collect, use, communicate,
                                    disclose, and otherwise make use of personal information. We have outlined our privacy policy below. What
                                    We Do to Information Collected
                                </p>
                                <h5>Personal Information</h5>
                                <p>
                                    Upon registration, we collect personal information which you voluntarily provided by lawful means. We collect
                                    and use personal information solely for fulfilling the purposes specified by us and for other ancillary
                                    purposes.
                                    <br /><br />
                                    This refers to the personal information you submit when you register (e.g., email address, company name,
                                    password, bank account number, etc.) it may also include anonymous information that is linked to you, for
                                    example, your IP Address. It should be relevant to the purposes for which it is to be used, and, to the extent
                                    necessary for those purposes, should be accurate, complete, and up to date.
                                    <br /> <br />
                                    The following lists what we use your personal information for. These may be updated from time to time. To
                                    provide you with access to our service.
                                </p>
                                <ul>
                                    <li>To verify the information provided.</li>
                                    <li>To prevent, detect and manage any illegal and fraudulent activity.</li>
                                    <li>To improve our services to you.</li>
                                    <li>To address any inappropriate use of our services.</li>
                                    <li>To update our website layout, database, and content.</li>
                                    <li>To target advertisements, newsletter, and service updates.</li>
                                    <li>Resolve disputes that may occur.</li>
                                </ul>
                                <p>
                                    Additional personal information may be gotten from third party applications and other
                                    identification/verification services. For example, from your financial institution.
                                    <br /> <br />
                                    Once you begin to use our services, we keep all records of your transactions and we will not share or disclose
                                    your personal information with a third party without your consent.
                                </p>
                                <h5>Protecting Your Information</h5>
                                <p>
                                    We will protect your personal information by using global security safeguards against loss or theft, as well as
                                    against any unauthorized access, risk of loss, disclosure, copying, misuse, or modification. Other security
                                    measures include but not limited to, firewall, data encryption and granting access only to employees to fulfil
                                    their job responsibilities.
                                    <br />
                                    We are committed to conducting our business in accordance with these principles to ensure that the
                                    confidentiality of personal information is protected and maintained.
                                </p>
                                <h5>Data Storage</h5>
                                <p>
                                    We will only retain personal information on our servers for as long as an account is active. Once an account has
                                    been deactivated, your information is stored on our servers for as long as necessarily obligated by law. This
                                    policy may change from time to time at our sole discretion.
                                </p>
                                <h4>COOKIES POLICY</h4>
                                <h5>What is a cookie?</h5>
                                <p>
                                    A cookie is a small file placed onto your device that enables Konnect features and functionality. For example,
                                    cookies enable us to identify your device, secure your access to Konnect and our sites generally, and even help
                                    us know if someone attempts to access your account from a different device. Cookies also enable you to easily
                                    share content on Konnect and help us serve relevant ads to you.
                                </p>
                                <h5>When does Konnect place cookies?</h5>
                                <p>
                                    We use cookies on our sites and mobile applications. Any browser visiting these sites will receive cookies from
                                    us. We also place cookies in your browser when you visit non-Konnect sites that host our plugins or tags.
                                </p>
                                <h5>What types of cookies?</h5>
                                <p>
                                    We use two types: persistent cookies and session cookies. A persistent cookie helps us recognize you as an
                                    existing user, so it is easier to return to our site or interact with our services without signing in again.
                                    Session cookies only last for as long as the session (usually the current visit to a website or a browser
                                    session).
                                </p>
                                <h5>What are cookies used for?</h5>
                                <p>
                                    Cookies can be used to recognize you when you visit Konnect, remember your preferences, and give you a
                                    personalized experience that is in line with your settings. Cookies also make your interactions with us faster
                                    and more secure. Additionally, cookies allow us to bring you advertising both on and off the sites and bring
                                    customized features to you through Konnect plugins such as our “Share” button.
                                </p>
                                <table>
                                    <thead>
                                        <tr className="table100-head" style={{ borderBottom: "1px solid #F2F2F2", marginBottom: '1500%' }}>
                                            <th className="column1">Categories of Use</th>
                                            <th className="column2">Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid #F2F2F2", marginBottom: '1500%' }}>
                                            <td className="column1">Authentication</td>
                                            <td className="column2">
                                                If you are signed in to Konnect, cookies help us show you the right information and
                                                personalize your experience.
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid #F2F2F2", marginBottom: '1500%' }}>
                                            <td className="column1">Security</td>
                                            <td className="column2">
                                                We use cookies to enable and support our security features, and to help us detect malicious activity
                                                and violations of our User Agreement.
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid #F2F2F2", marginBottom: '1500%' }}>
                                            <td className="column1">
                                                Preferences, features, and services
                                            </td>
                                            <td className="column2">
                                                Cookies can tell us which language you prefer and what your communications preferences are. They can
                                                help you fill out forms on Konnect more easily. They also provide you with features, insights, and
                                                customized content in conjunction with our plugins.
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid #F2F2F2", marginBottom: '1500%' }}>
                                            <td className="column1">Advertising</td>
                                            <td className="column2">
                                                We may use cookies to show you relevant advertising both on and off the Konnect site. Similarly, our
                                                partners may use a cookie to determine whether we have shown an ad and how it performed or provide
                                                us with information about how you interact with ads.
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: "1px solid #F2F2F2", marginBottom: '1500%' }}>
                                            <td className="column1">Performance, Analytics and Research</td>
                                            <td className="column2">
                                                Cookies help us learn how well our site and plugins perform in different locations. We also use
                                                cookies to understand, improve, and research products, features, and services, including when you
                                                access Konnect from other websites, applications, or devices such as your work computer or your
                                                mobile device.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h5>Controlling cookies</h5>
                                <p>
                                    Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability
                                    of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized
                                    to you. It may also stop you from saving customized settings like login information.
                                </p>
                            </div>
                            <div style={{ marginTop: '20px' }}></div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <FooterDisplay /> */}
        </div>
    )
}

export default PrivacyPolicy;