import "../../../assets/css/dashboard.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/images/icons/arrow-right.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";

import ImgDisp from "../../../assets/images/illustrations/img.jpg";

import Logo from "../../../assets/images/logo.png";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Input, notification, Spin, Radio, Select, DatePicker } from "antd";
import { Controller, useForm } from "react-hook-form";
import { _change_password, _generate_otp, _get_profile, _update_profile, _update_trans_pin, _verify_otp } from "../../../common/axios_services";
import FormResponseModal from "../../../components/api-response";
import { fetchData, profileData } from "../../../slices/profileSlice";
import BasicOTPComponent from "../../../components/otpform";
import RequestTransactionCode from "../../../components/profile/otpcode";

import { useOtpInput } from "react-otp-input-hook";
import OtpInput from 'react-otp-input';
import TopNav from "../../../components/top-nav";

const ProfileBasicSettings = () => {

    const Navigate = useNavigate();
    const dispatch = useDispatch();

    const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "#fff" }} spin />;
    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            // placement: "bottom-right"
        });
    };

    const [loadingData, setLoadingData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [currentDispTab, setCurrentDispTab] = useState("profile");
    const [modalDispTab, setModalDispTab] = useState("otp");

    // user data
    // const profileInfo = useSelector(state => state.userProfile.dataFetched);
    const profileInfo = useSelector(state => state);

    // basic profile
    const basicInfoValidator = yup.object().shape({
        firstName: yup.string().required('First name field is required'),
        lastName: yup.string().required('Last name field is required'),
        email: yup.string().email('Email address is not valid').required('Email address field is required'),
        phoneNumber: yup.string().required('Phone number is not valid'),
    })
    const { handleSubmit: handleBasicInfo, control: controlBasicInfo, setValue: setBasicInfoValue, formState: { errors: basicInfoError } } = useForm({
        resolver: yupResolver(basicInfoValidator)
    });

    // fetch user data
    const [VerActivated, setVerActivated] = useState(false);
    const [otp, setOtp] = useState(false);
    const [userGenderOption, setUserGenderOption] = useState("");
    const [userDOB, setUserDOB] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [loadingSpinner, setLoadingSpinner] = useState(true);

    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            dispatch(profileData(user_profile?.data?.data));
            setVerActivated(user_profile?.data?.data?.login_2fa);
            setImageUrl(user_profile?.data?.data?.image);

            let userData = user_profile?.data.data;
            setBasicInfoValue("firstName", userData.first_name);
            setBasicInfoValue("lastName", userData.last_name);
            setBasicInfoValue("email", userData.email);
            setBasicInfoValue("phoneNumber", userData.phone_number);
            setUserGenderOption(userData.gender ? userData.gender : "");
            setUserDOB(userData.dob ? userData.dob : "2008-02-24");
            setBasicInfoValue("userType", userData.role.user_type);
            setLoadingSpinner(false);
            if (user_profile?.data?.data?.email_otp) {
                setOtp(user_profile?.data?.data?.email_otp);
            };
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`);
                };
                dispatch(profileData(false));
                dispatch(fetchData(false));
            } else {
            }
        }
    }
    useEffect(() => {
        fetchUser();
        // if (searchParams.get('pin')) {
        //     setActiveTabKey('3');
        // }
        // updateCount
    }, [])

    const genderOptions = [
        {
            label: 'Male',
            value: 'male',
        },
        {
            label: 'Female',
            value: 'female',
        }
    ];

    const onChangeGenderOption = ({ target: { value } }) => {
        setUserGenderOption(value);
    };

    const onChangeUserDOB = (date, dateString) => {
        setUserDOB(dateString);
    };

    const updateUserInfo = async e => {
        setLoadingData(true);
        setErrorMessage("");
        try {
            let { email, firstName, lastName, phoneNumber, ageGroup, userType } = e;
            const data = {
                first_name: firstName,
                last_name: lastName,
                email,
                phone_number: phoneNumber,
                userType: userType,
                gender: userGenderOption,
                dob: "",
                image: "",
                pin: profileInfo.pin,
                age: ageGroup,
            }
            const updateProfile = await _update_profile(data)
            setErrorMessage("");
            openNotificationWithIcon('success', updateProfile?.data?.message);
            Navigate("/settings");
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    setErrorMessage('An error occurred while checking pin. Please check network and try again');
                } else if (err?.response?.data?.message === "Unauthorized") {
                    setErrorMessage('User Authorization expired. Please log in to continue');
                    Navigate('/signin');
                }
                else {
                    setErrorMessage(err?.data?.message.length ? err?.data?.message : err?.response?.data?.message);
                }
            } else {
                setErrorMessage('An error occurred while checking pin. Please try again');
            }
            setLoadingData(false);
        }
    }

    const editPasswordValidator = yup.object().shape({
        oldPassword: yup.string().required('Please enter your password'),
        newPassword: yup.string().required('Please enter your new password'),
        confirmPassword: yup.string().required('Please enter your new password'),
    })
    // update user password
    const { handleSubmit: editPasswordHandleSubmit, control: editPasswordControl, formState: { errors: editPasswordError } } = useForm({
        resolver: yupResolver(editPasswordValidator)
    });

    const changePassword = async e => {
        let { oldPassword, newPassword, confirmPassword } = e;
        setLoadingData(true);
        setErrorMessage("");
        try {
            const pass_changed = await _change_password({ current_password: oldPassword, new_password: newPassword, confirm_new_password: confirmPassword })
            setLoadingData(false)
            openNotificationWithIcon('success', pass_changed?.data?.message);
            Navigate("/settings");
        } catch (err) {
            setLoadingData(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    Navigate('/signin');
                    openNotificationWithIcon('error', err?.response?.data?.message);
                } else {
                    // setErrorMessage(err?.response?.data?.title, err?.response?.data?.message);
                    setErrorMessage(err?.response?.data?.message);
                    openNotificationWithIcon('error', err?.response?.data?.message);
                }
            } else {
                setErrorMessage(err?.message);
                openNotificationWithIcon('error', err?.message);
            }
        }
    }

    // security update
    const [userOTP, setUserOTP] = useState('');
    const [updatePinVisible, setUpdatePinVisible] = useState(false);
    const [reGen, setReGen] = useState(false);
    const [verifiedOtp, setVerifiedOtp] = useState(false);
    const [transCodeDrawer, setTransCodeDrawer] = useState(false);
    const [currentOTPCounter, setCurrentOTPCounter] = useState(0);
    const [currentTransCode, setCurrentTransCode] = useState("");
    const [newTransCode, setNewTransCode] = useState("");
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [userTransPin, setUserTransPin] = useState('');

    const otpCheckValidator = yup.object().shape({
        otp: yup.string().required('Please enter OTP sent to your mail'),
    })
    const { handleSubmit: otpCheckHandleSubmit, control: otpCheckControl, formState: { errors: otpCheckError } } = useForm({
        // resolver: yupResolver(otpCheckValidator)
    });

    const closeTransactionCodeDrawer = () => {
        setTransCodeDrawer(false);
    }

    const controlTransCodeModalDisplay = () => setDisplayTransCodeModal(!displayTransCodeModal);

    // const basicInfoValidator = yup.object().shape({
    //     firstName: yup.string().required('First name field is required'),
    //     lastName: yup.string().required('Last name field is required'),
    //     email: yup.string().email('Email address is not valid').required('Email address field is required'),
    //     phoneNumber: yup.string().required('Phone number is not valid'),
    // })
    const { handleSubmit, control, setValue } = useForm({
        resolver: yupResolver(basicInfoValidator)
    });

    const genOtp = async () => {
        setLoadingData(true);
        setLoadingSpinner(true);
        try {
            const generate_otp = await _generate_otp();
            setOtp(true);
            setReGen(false)
            openNotificationWithIcon('success', generate_otp?.data?.message);
            setLoadingData(false);
            setLoadingSpinner(false);
            setCurrentOTPCounter(40);
            setTransCodeDrawer(true);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                    Navigate('/signin')
                }
                openNotificationWithIcon("error", err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
            setLoadingData(false);
            setLoadingSpinner(false);
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.addEventListener('load', () => callback(reader.result));
    }

    // verify otp box

    const [pageUserPin, setPageUserPin] = useState('');

    const onChangeOTPInput = e => {
        if (pageUserPin.length < 4) {
            setPageUserPin(e);
        }
    }

    const [inputData, setInputData] = useState("");
    const [otpCounter, setOtpCounter] = useState(false);
    const [transPinErrorMessage, setTransPinErrorMessage] = useState("");


    const toggleOTPCounter = () => setOtpCounter(!otpCounter);
    const toggleTransCodeModalDisplay = () => {
        if (pageUserPin.length === 4) {
            setDisplayTransCodeModal(!displayTransCodeModal);
            setOtpCounter(true);
        } else {
            openNotificationWithIcon("error", "Please enter 4 characters")
        }
    };

    const sendOTPToUser = async () => {
        setLoadingSpinner(true);
        try {
            const generate_otp = await _generate_otp();
            openNotificationWithIcon('success', generate_otp?.data?.message);
            setLoadingSpinner(false);
            toggleTransCodeModalDisplay();
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                    Navigate('/signin')
                }
                openNotificationWithIcon("error", err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
            setLoadingSpinner(false);
        }
    }

    const updatePin = async (value) => {
        setTransPinErrorMessage('');
        try {
            const changeTransPin = await _update_trans_pin({
                current_pin: `${profileInfo.pin === "0000" ? "0000" : currentTransCode}`,
                new_pin: pageUserPin,
                otp: userOTP,
                confirm_new_pin: pageUserPin
            })
            console.log(changeTransPin)
            openNotificationWithIcon(changeTransPin?.data?.status, changeTransPin?.data?.message);
            setLoadingData(false);
            Navigate("/settings");
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon('error', err?.response?.data?.message);
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err?.response?.data?.message);
            } else {
                setTransPinErrorMessage(err?.message);
                openNotificationWithIcon('error', "Something went wrong", err?.message);
            }
            setLoadingData(false);
        }
    }

    const VerifyOtp = async () => {
        setLoadingSpinner(true);
        setTransPinErrorMessage('');
        try {
            const verifyOtp = await _verify_otp({ otp: userOTP });
            updatePin();
            openNotificationWithIcon('success', verifyOtp?.data?.message);
        } catch (err) {
            setLoadingSpinner(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                    Navigate('/signin');
                } else {
                    setTransPinErrorMessage(err?.response?.data?.message);
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                }
            } else {
                setTransPinErrorMessage(err?.message);
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    return (
        <div>
            <Spin spinning={loadingSpinner} indicator={newAntIcon}>
                <div className="profile-display settings-display form-page">
                    <TopNav title="Edit Profile" />
                    <div className="display-main-content white-b">
                        <div className="contain">
                            {/* <h3 className="sec-page-title">Edit Profile</h3>
                            <div className="top-selection-bar">
                                <div
                                    onClick={() => { setCurrentDispTab("profile") }}
                                    className={`select-bar ${currentDispTab === "profile" ? "active-bar" : ""}`}>
                                    <div className="active-inner-text">
                                        <h4>Account Info</h4>
                                    </div>
                                </div>
                                <div
                                    onClick={() => { setCurrentDispTab("profile") }}
                                    className={`select-bar ${currentDispTab === "agent" ? "active-bar" : ""}`}>
                                    <div className="active-inner-text">
                                        <h4>My Sales Agent</h4>
                                    </div>
                                </div>
                                <div
                                    onClick={() => { setCurrentDispTab("password") }}
                                    className={`select-bar ${currentDispTab === "password" ? "active-bar" : ""}`}>
                                    <div className="active-inner-text">
                                        <h4>Password</h4>
                                    </div>
                                </div>
                                <div
                                    onClick={() => { setCurrentDispTab("security") }}
                                    className={`select-bar ${currentDispTab === "security" ? "active-bar" : ""}`}>
                                    <div className="active-inner-text">
                                        <h4>Security</h4>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="divider-line"></div> */}
                            <div className="mt_4">
                                <div>
                                    <div>
                                        {errorMessage.length ? <p className="form-error mb_3">{errorMessage}</p> : ""}
                                    </div>
                                    {
                                        currentDispTab === "profile" ?
                                            <form onSubmit={handleBasicInfo(updateUserInfo)}>
                                                <div className="grid-2">
                                                    <div className="form_group">
                                                        <label htmlFor="firstName">First name</label>
                                                        <Controller name="firstName" control={controlBasicInfo} defaultValue=""
                                                            render={({ field }) => (
                                                                <Input name="firstName" {...field} id="firstName"
                                                                    style={{ height: "5rem" }} />
                                                            )} />
                                                        {basicInfoError.firstName && <p className="form-error">{basicInfoError.firstName.message}</p>}
                                                    </div>
                                                    <div className="form_group">
                                                        <label htmlFor="lastName">Last name</label>
                                                        <Controller name="lastName" control={controlBasicInfo}
                                                            render={({ field }) => (
                                                                <Input name="lastName" {...field} id="lastName"
                                                                    style={{ height: "5rem" }} />
                                                            )} />
                                                        {basicInfoError.lastName && <p className="form-error">{basicInfoError.lastName.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="grid-">
                                                    <div className="form_group">
                                                        <label htmlFor="email">Email address</label>
                                                        <Controller name="email" control={controlBasicInfo}
                                                            render={({ field }) => (
                                                                <Input name="email" type="email" {...field} id="email" disabled
                                                                    style={{ height: "5rem" }} />
                                                            )} />
                                                        {basicInfoError.email && <p className="form-error">{basicInfoError.email.message}</p>}
                                                    </div>
                                                    <div className="form_group">
                                                        <label htmlFor="phoneNumber">Phone number</label>
                                                        <Controller name="phoneNumber" control={controlBasicInfo}
                                                            render={({ field }) => (
                                                                <Input name="phoneNumber" {...field} id="phoneNumber"
                                                                    style={{ height: "5rem" }} />
                                                            )} />
                                                        {basicInfoError.phoneNumber && <p className="form-error">{basicInfoError.phoneNumber.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="grid-">
                                                    <div className="form_group">
                                                        <label htmlFor="gender">Gender</label>
                                                        <Radio.Group
                                                            style={{ height: "5rem", display: "block" }}
                                                            options={genderOptions}
                                                            onChange={onChangeGenderOption}
                                                            value={userGenderOption}
                                                            optionType="button"
                                                            buttonStyle="solid"
                                                        />
                                                    </div>
                                                    <div className="form_group">
                                                        <label htmlFor="dob">Age</label>
                                                        <Controller name="dob" control={controlBasicInfo}
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    style={{ height: "5rem", display: "flex", alignItems: "center" }}
                                                                    onChange={onChangeUserDOB} />
                                                            )} />
                                                        {/* {basicInfoError.last_name && <p className="form-error">{basicInfoError.last_name.message}</p>} */}
                                                    </div>
                                                </div>
                                                <div className="welcome-buttons mt_20">
                                                    <div className="welcome-buttons-content">
                                                        {
                                                            !loadingData ?
                                                                <button className="btn-green">Continue</button>
                                                                :
                                                                <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                            : currentDispTab === "password" ?
                                                <div>
                                                    <form autoComplete="off" onSubmit={editPasswordHandleSubmit(changePassword)}>
                                                        <div>
                                                            <div className="form_group">
                                                                <label htmlFor="oldPassword">Old Password</label>
                                                                <Controller name="oldPassword" control={editPasswordControl} defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input.Password name="oldPassword" {...field} id="oldPassword"
                                                                            style={{ height: "5rem" }} />
                                                                    )} />
                                                                {editPasswordError.oldPassword && <p className="form-error">{editPasswordError.oldPassword.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="form_group">
                                                                <label htmlFor="newPassword">New Password</label>
                                                                <Controller name="newPassword" control={editPasswordControl} defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input.Password name="newPassword" {...field} id="newPassword"
                                                                            style={{ height: "5rem" }} />
                                                                    )} />
                                                                {editPasswordError.newPassword && <p className="form-error">{editPasswordError.newPassword.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="form_group">
                                                                <label htmlFor="confirmPassword">Re-enter New Password</label>
                                                                <Controller name="confirmPassword" control={editPasswordControl} defaultValue=""
                                                                    render={({ field }) => (
                                                                        <Input.Password name="confirmPassword" {...field} id="confirmPassword"
                                                                            style={{ height: "5rem" }} />
                                                                    )} />
                                                                {editPasswordError.confirmPassword && <p className="form-error">{editPasswordError.confirmPassword.message}</p>}
                                                            </div>
                                                        </div>
                                                        <div className="welcome-buttons">
                                                            <div className="welcome-buttons-content">
                                                                {
                                                                    !loadingData ?
                                                                        <button className="btn-base">Continue</button>
                                                                        :
                                                                        <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div> :
                                                <div>
                                                    <div className="form-group-cop">
                                                        <div className="main-otp-display">
                                                            <h5 style={{ fontSize: "1.6rem" }}>Enter new Transaction Code</h5>
                                                            <OtpInput
                                                                value={pageUserPin}
                                                                onChange={onChangeOTPInput}
                                                                numInputs={4}
                                                                renderSeparator={<span>-</span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="welcome-buttons mt_10">
                                                        <div className="welcome-buttons-content">
                                                            {
                                                                !loadingData ?
                                                                    <button onClick={sendOTPToUser} className="btn-green">Generate OTP</button>
                                                                    :
                                                                    <button className="btn-green" disabled><Spin indicator={antIcon} /></button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RequestTransactionCode openDrawer={displayTransCodeModal} closeDrawer={toggleTransCodeModalDisplay}
                    inputCount={6} stateFunc={setUserOTP} actionFunction={VerifyOtp} startCounter={otpCounter} counterAction={toggleOTPCounter} />
            </Spin>
        </div>
    )
}

export default ProfileBasicSettings;