import "../assets/css/mini.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Spin } from "antd";
import { DateTime } from 'luxon';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import AllAppRoutes from "../common/routes";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import { ReactComponent as PieChartIcon } from "../assets/images/icons/pie-chart.svg";
import { ReactComponent as GiftIcon } from "../assets/images/icons/gift.svg";

import CustormerImg from "../assets/images/icons/customer.svg";

import { Collapse } from 'antd';

import FolderIcon from "../assets/images/illustrations/12_folder.png";

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _get_user_account_history, _wise9ja_topup } from '../common/axios_services';
import { fetchData, profileData } from '../slices/profileSlice';

import EmptyGIF from "../assets/images/illustrations/no-entry.gif";
import GiftGIF from "../assets/images/illustrations/gift.gif";

import FooterDisplay from "../components/footer";
import AppRoutes from "../common/routes";
import TopNav from "../components/top-nav";

const Wise9jaPage = () => {

    const Navigate = useNavigate();

    const items = [
        {
            key: '1',
            label: <>
                <b>&bull;</b>
                {/* <span>1</span> */}
                <div>
                    <p>Why Wise9ja?</p>
                </div>
            </>,
            children: <p>Wise9ja supports our drive to deliver wholesale prices to our bulk retail buyers.</p>,
        },
        {
            key: '2',
            label: <>
                <b>&bull;</b>
                {/* <span>2</span> */}
                <div>
                    <p>How secure is my subscription amount?</p>
                </div>
            </>,
            children: <p>Your amount is safe and we reduce your risk by the upfront cashback and we payback your subscription amount in installments as scheduled including interest earned.</p>,
        },
        {
            key: '3',
            label: <>
                <b>&bull;</b>
                {/* <span>3</span> */}
                <div>
                    <p>Are there any restrictions?</p>
                </div>
            </>,
            children: <p>Wise9ja is available to all users but the subscription is subject to a minimum and maximum amount.</p>,
        },
        {
            key: '4',
            label: <>
                <b>&bull;</b>
                {/* <span>4</span> */}
                <div>
                    <p>How many times can I subscribe?</p>
                </div>
            </>,
            children: <p>There no limits but we advise you to subscribe wisely.</p>,
        },
    ];

    return (
        <div className="display-page">
            {/* <TopNav title="How Wise9ja Works" /> */}
            <div className="how-to">
                <div className="profile-first-sect">
                    <div className="top-body-box">
                        <div className="grid-flex">
                            <div
                                onClick={() => Navigate(-1)}>
                                <ArrowLeftIcon style={{ color: "#fff" }} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="gift-cover">
                            <img src={GiftGIF} alt="gift" />
                        </div>
                        <h3>How Wise9ja Works</h3>
                    </div>
                </div>
                <div className="display-main-conten mt_">
                    {/* <div className="contain">
                        <div>
                            <img src={GiftGIF} alt="gift" />
                            <p className="center">Wise9ja is a saving product that users can subscribe to and earn upfront rewards with the
                                subscribed amount paid at due.
                            </p>
                        </div>
                    </div> */}
                    <div className="white-bg mt_">
                        <h5>How to get started with Wise9ja</h5>
                        <ul>
                            <li>
                                <b>&bull;</b>
                                {/* <span>1</span> */}
                                <p>Click the button below or New Plan.</p>
                            </li>
                            <li>
                                <b>&bull;</b>
                                {/* <span>2</span> */}
                                <p>The subscription amount is deducted from your Konnect wallet.</p>
                            </li>
                            <li>
                                <b>&bull;</b>
                                {/* <span>3</span> */}
                                <p>You'll earn an upfront cashback which is a percentage of the subscription amount.</p>
                            </li>
                            <li>
                                <b>&bull;</b>
                                {/* <span>3</span> */}
                                <p>Earn extra cash from your bonus balance. YOur bonus balance is accrued from all bonuses.</p>
                            </li>
                            <li>
                                <b>&bull;</b>
                                {/* <span>4</span> */}
                                <p>We pay back your subscription amount in equal installments monthly or quarterly or in full (subject to terms).</p>
                            </li>
                        </ul>
                    </div>
                    <div className="white-bg faqs">
                        <div className="grid-flex">
                            <div className="grid-flex">
                                <h5>Read our FAQs</h5>
                            </div>
                        </div>
                        <div className="faq-box">
                            <Collapse defaultActiveKey={['1']} ghost items={items} expandIconPosition="end" />
                        </div>
                        <div className="contain link mt_4">
                            <p className="center">By clicking on Start Earning Now, you agree to our <Link to={AppRoutes.terms}>Terms and conditions</Link> and
                                <Link to={AppRoutes.privacy}>Privacy Policies</Link></p>
                        </div>
                        <div className="welcome-buttons bottom-button">
                            <div className="welcome-buttons-content">
                                <Link to={AppRoutes.start_earning} className="btn-base">Start Earning Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Wise9jaPage;