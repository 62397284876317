import "../assets/css/shop.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Select, Collapse, Radio, Spin, Space, Drawer, Input } from "antd";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { useNavigate } from "react-router-dom";

import 'swiper/css';

import { LoadingOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { addToCart, updateCart } from '../slices/cartSlice';
import NumberFormat from 'react-number-format';
import axiosCall from '../common/axios_call';
import { _delivery_info, _fetch_delivery_date_by_payment_option } from "../common/axios_services";
import TopNav from "../components/top-nav";
import { DateTime } from "luxon";

import InfoImg from "../assets/images/Information.png";
// import { Drawer, Input, Spin } from 'antd';
// import Moment from 'moment';
import NaijaStates from 'naija-state-local-government';

import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import ReactMoment from 'react-moment';
import Jwt_decrypt from '../common/Jwt_decrypt';
import { _add_delivery_address, _checkout_summary, _fetch_user_wallet_data, _general_settings_by_id, _get_all_cities, _get_all_dates, _get_all_pickup_locations, _get_all_state, _get_profile, _get_saved_address, _get_selected_delivery_location, _get_selected_pickup_location, _shop_discount, _single_product, _verify_pin, pay_checkout } from '../common/axios_services';
import { encrypted_token, fetchData, profileData } from '../slices/profileSlice';



const CheckOutPage = () => {

    const Navigate = useNavigate();
    const [cartQuantity, setCartQuantity] = useState(1);

    let url = new URLSearchParams(window.location.search);
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };

    const [orderTotals, setOrderTotals] = useState({});
    const [updateCount, setUpdateCount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    // NEW
    const [loadingData, setLoadingData] = useState(false);
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("delivery");
    const [displayAddressModal, setDisplayAddressModal] = useState(false);
    const [allStates, setAllStates] = useState([]);
    const [availableDeliveryDate, setAvailableDeliveryDate] = useState([]);
    const [loadAvailableDeliveryDate, setLoadAvailableDeliveryDate] = useState(false);
    const [userSelectedPaymentOption, setUserSelectedPaymentOption] = useState("");
    const [userSelectedDeliveryAddress, setUserSelectedDeliveryAddress] = useState("");
    const [loadingFinalButton, setLoadingFinalButton] = useState(false);





    const [currentSelectedAddress, setCurrentSelectedAddress] = useState("");
    const [currentUserDeliveryOption, setCurrUserDeliveryOption] = useState("delivery");
    // const [currentUserPaymentOption, setCurrUserPaymentOption] = useState("after_delivery");
    const [currentUserDeliveryDate, setCurrentUserDeliveryDate] = useState("");
    // const [loadingDeliveryDates, setLoadingDeliveryDates] = useState(false);
    const [allDeliveryLocations, setAllDeliveryLocations] = useState([]);



    const fetchDataStatus = useSelector(state => state.userProfile.fetch)
    const userData = useSelector(state => state.userProfile.dataFetched)
    const spinningIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const { handleSubmit: handleAddressFormSubmit, control: controlAddressForm, formState: { errors: errorsAddressForm },
        setValue: setAddressFormVideo, reset: resetAddressForm } = useForm({});

    let jwt_token = localStorage.getItem('konnect_token')

    const [deliveryDateUpdated, setDeliveryDateUpdated] = useState(false);
    const [allLgas, setAllLgas] = useState([]);
    const [userState, setUserState] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);

    // const [allDate, setAllDate] = useState([]);
    // const [paymentModal, setPaymentModal] = useState(false);
    // const [pay_on_delivery, setPay_on_delivery] = useState(false);
    // const [summaryLoading, setSummaryLoading] = useState(false);
    // const [dateRestrictions, setDateRestrictions] = useState(false);
    // const [accountBalance, setAccountBalance] = useState({});

    const DeliveryFees = async () => {
        // setOrderTotals(false)
        setLoadingData(true);
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        if (local_cart) {
            const data = {
                // payment_opt: paymentOption === "pay_now" ? "pay_now" : paymentOption === "bnpl" ? "buy_now_pay_later" : paymentOption === "pay_on_delivery" ? "pay_on_delivery" : "pay_now",
                // delivery_date_id: id,
                // sub_amount: `${local_cart?.data?.reduce(reduPrice, 0)}`,
                // is_pod: pay_on_delivery,
                // coupon: "0",
                // delivery_opt: deliveryMethod,

                app: "web",
                sub_amount: `${local_cart.data.reduce(reduPrice, 0)}`,
                discounts: `${local_cart.data.reduce(reduDiscount, 0)}`,
                cashback: `${local_cart.data.reduce(reduCashback, 0)}`,
                coupon: "0",
            }
            // setSummaryLoading(true)
            try {
                // const checkout_summary = await _checkout_summary(data)
                // const checkout_data = await _general_settings_by_id({ id: "1" })
                const delivery_info = await _delivery_info(data)
                // if ((+checkout_summary?.data?.data?.total_amount) < (+checkout_data?.data?.data?.value)) {
                //     openNotificationWithIcon("error", "Something went wrong", `Min order of NGN ${(+checkout_data?.data?.data?.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} before checkout`)
                //     navigate('/cart')
                // }
                // console.log(delivery_info);
                setOrderTotals(delivery_info.data);
                setLoadingData(false);
            } catch (err) {
                if (err.response) {
                    if (err.response === "Unauthorized") {
                        navigate('/')
                        openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    } else {
                        openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    }
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err.message);
                }
                setLoadingData(false);
            }
        }
    }






    const options = [];
    for (let index = 1; index <= 20; index++) {
        options.push(index);
    };
    const { Option } = Select;

    let cart_info = useSelector(state => state.userCart.cart)

    const reduQuantity = (total, num) => {
        return total + Number(num.quantity);
    }

    const reduWeight = (total, num) => {
        return total + num.total_weight;
    }

    const reduPrice = (total, num) => {
        return total + num.total_price;
    }
    const reduDiscount = (total, num) => {
        return total + num.discount;
    }
    const reduCashback = (total, num) => {
        return total + num.cashback;
    }


    useEffect(() => {
        const local_cart = JSON.parse(localStorage.getItem('konnect_cart'))
        dispatch(addToCart(local_cart));
        DeliveryFees();
    }, [updateCount]);

    const fetchUserAddresses = async () => {
        try {
            const allAddresses = await _get_saved_address()
            // console.log(allAddresses)
            setAllDeliveryLocations(allAddresses.data.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const fetchAddedStates = async () => {
        try {
            const uploadedStates = await _get_all_state();
            setAllStates(uploadedStates.data.data);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    // const fetchAddedCities = async () => {
    //     try {
    //         const uploadedCities = await _get_all_cities()
    //         setAllCities(uploadedCities.data.data)
    //     } catch (err) {
    //         if (err.response) {
    //             openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
    //         } else {
    //             openNotificationWithIcon('error', "Something went wrong", err.message)
    //         }
    //     }
    // }
    const fetchPickupLocations = async () => {
        try {
            const uploadedLocations = await _get_all_pickup_locations();
            console.log(uploadedLocations)
            // setAllLocations(uploadedLocations.data.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const getBalance = async () => {
        try {
            const get_balance = await _fetch_user_wallet_data()
            // setAccountBalance(get_balance?.data?.data);
        } catch (err) {
            // setAccountBalance({});
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate("/signin?redir=review")
                    localStorage.removeItem('konnect_token')
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const getProfile = async () => {
        try {
            const user_profile = await _get_profile()
            dispatch(profileData(user_profile.data.data))
            dispatch(fetchData(true))
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    dispatch(profileData(false))
                    dispatch(fetchData(false))
                    navigate("/signin?redir=review")
                    localStorage.removeItem('konnect_token')
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    // const getAllDates = async () => {
    //     try {
    //         const all_dates = await _get_all_dates({ payment_opt: paymentOption === "pay_now" ? "pay_now" : paymentOption === "pay_on_delivery" ? "pay_on_delivery" : paymentOption === "bnpl" ? "buy_now_pay_later" : "pay_now" })
    //         let date_data = all_dates.data.data.sort(function (a, b) {
    //             return new Date(a.date) - new Date(b.date)
    //         })
    //         setAllDate(date_data);
    //         console.log(date_data);
    //     } catch (err) {
    //         if (err.response) {
    //             if (err.response.data.message === "Unauthorized") {
    //                 navigate("/signin?redir=review")
    //                 localStorage.removeItem('konnect_token')
    //                 openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
    //             } else {
    //                 openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
    //             }
    //         } else {
    //             openNotificationWithIcon('error', "Something went wrong", err.message)
    //         }
    //     }
    // }
    const getDateRestrictions = async () => {
        // setDateRestrictions(false)
        try {
            const date_restrict = await _general_settings_by_id({ id: "2" })
            // setDateRestrictions(date_restrict?.data?.data?.value)
        } catch (err) {
            // setDateRestrictions(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    dispatch(profileData(false))
                    dispatch(fetchData(false))
                    navigate("/signin?redir=review")
                    localStorage.removeItem('konnect_token')
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const checkProductPrice = async () => {
        const storedCart = JSON.parse(localStorage.getItem('konnect_cart'))
        if (storedCart && storedCart.data && storedCart.data.length > 0) {
            try {
                const updatedCart = await Promise.all(
                    storedCart.data.map(async product => {
                        try {
                            const checkPrice = await _single_product({ id: product?.product_id })
                            let data = {
                                id: product?.id,
                                product_id: product?.product_id,
                                quantity: product?.quantity ? product?.quantity : 1,
                                timestamp: product?.timestamp,
                                product_name: product?.product_name,
                                product_image: product?.product_image,
                                description: checkPrice?.data?.data?.description,
                                product_price: checkPrice?.data?.data?.konnect_price,
                                supplier_price: checkPrice?.data?.data?.sellers_price,
                                weight: checkPrice?.data?.data?.weight,
                                discount_rate: checkPrice?.data?.data?.discount_rate,
                                discount: checkPrice?.data?.data?.konnect_price * checkPrice?.data?.data?.discount_rate * 0.01 * product?.quantity,
                                total_weight: product?.quantity * checkPrice?.data?.data?.weight,
                                total_price: product?.quantity * checkPrice?.data?.data?.konnect_price,
                                total_supplier_price: product?.quantity * checkPrice?.data?.data?.sellers_price,
                                cashback: 0,
                            }
                            return data;
                        } catch (err) {
                            return null;
                        }
                    })
                )
                storedCart.data = updatedCart.filter(product => product !== null)
                localStorage.setItem('konnect_cart', JSON.stringify(storedCart));
            } catch (err) {
                console.log('Error updating product prices:', err.message)
            }
        }
    }

    useEffect(() => {
        if (jwt_token) {
            const decrypted = Jwt_decrypt({ token: jwt_token })
            dispatch(encrypted_token(decrypted))
        }
        getProfile()
        fetchUserAddresses()
        fetchAddedStates()
        // fetchAddedCities()
        // fetchPickupLocations()
        checkProductPrice()
        getBalance()
        // }, [dispatch, jwt_token, updateCount, deliveryType, deliveryMethod])
    }, [dispatch, jwt_token, updateCount])
    useEffect(() => {
        // getAllDates()
        getDateRestrictions()
        // }, [paymentOption, dispatch, jwt_token, updateCount, deliveryType, deliveryMethod])
    }, [dispatch, jwt_token, updateCount])

    // const selectDeliveryDate = (e) => {
    //     setDeliveryDate(e?.target?.value);
    //     setFormattedDate(moment(e?.target?.value?.date.split('T')[0]).format('dddd, MMMM Do YYYY'));
    //     const get_delivery_date = JSON.parse(localStorage.getItem('konnect_cart'))
    //     get_delivery_date.delivery_date = moment(e.target.value.date).format('dddd, MMMM Do YYYY')
    //     localStorage.setItem('konnect_cart', JSON.stringify(get_delivery_date))
    // }


    // new functions
    const onChangeState = (value) => {
        if (value) {
            const allLGAsinAState = NaijaStates.lgas(`${value}`);
            setAllLgas(allLGAsinAState.lgas);
            setUserState(value);
        }
    }
    const toggleAddressModal = () => setDisplayAddressModal(!displayAddressModal);

    const home_delivery_address = async (values) => {
        setLoadingButton(true);
        try {
            const data = {
                user_id: userData?.id ? userData?.id : userData?.user_id,
                location_no: "0",
                Address: values.home_address,
                floor_no: values.floor_no,
                phone_contact: values.phone_no,
                email: userData?.email,
                state: userState,
                city: "city ",
                lga: values.lga,
                longitude: "1",
                latitude: "1",
            };
            const addedDeliveryAddress = await _add_delivery_address(data);
            await fetchUserAddresses();
            setLoadingButton(false);
            resetAddressForm();
            toggleAddressModal();
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                setLoadingButton(false);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
                setLoadingButton(false);
            }
        }
    }

    const updateUserPaymentOption = async e => {
        if (e !== userSelectedPaymentOption) {
            setLoadAvailableDeliveryDate(true);
            setUserSelectedPaymentOption(e);
            // setLoadingDeliveryDates(true);
            try {
                let fetchAvailableDates = await _fetch_delivery_date_by_payment_option(e);
                setAvailableDeliveryDate(fetchAvailableDates.data.data);
                setLoadAvailableDeliveryDate(false);
                setCurrentUserDeliveryDate("");
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        navigate("/signin?redir=review")
                        localStorage.removeItem('konnect_token')
                        openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                    } else {
                        openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                    }
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err.message)
                }
                setLoadAvailableDeliveryDate(false);
            }
        }
    }

    const updateUserSelectedDeliveryLocation = e => {
        console.log(e.split("---").join(" "))
        setUserSelectedDeliveryAddress(e.split("---").join(" "));
    }

    const updateUserDeliveryDate = e => {
        let date = {
            id: e.split("---")[1],
            date: e.split("---")[0]
        }
        setCurrentUserDeliveryDate(date);
    }

    const completeCheckout = async ({ mode, method }) => {
        setLoadingFinalButton(true);
        const get_info = JSON.parse(localStorage.getItem('konnect_cart'))
        try {
            const data = {
                product_id: get_info.product_id,
                product_meta: {
                    location_id: get_info.location_id,
                    order_no: `${get_info.order_id}`,
                    order_size: `${get_info.total_weight}`,
                    total_qty: `${get_info.data.reduce(reduQuantity, 0)}`,
                    sub_amount: `${get_info.data.reduce(reduPrice, 0)}`,
                    tax: orderTotals.tax_fee,
                    charges: `${get_info.charges}`,
                    discount: orderTotals.discounts,
                    coupon_amount: orderTotals.coupon,
                    delivery_fee: orderTotals.delivery_fee,
                    cashback: orderTotals.cashback,
                    total_amount: orderTotals.total_amount,
                    items_data: get_info.data,
                    delivery_opt: userSelectedPaymentOption,
                    delivery_address: get_info.delivery_data,
                    delivery_date: `${get_info.delivery_date}`,
                    delivery_date_id: currentUserDeliveryDate.id,
                    delivery_address_id: `${get_info?.delivery_data?.id}`,
                    narrations: "Product Checkout",
                    payment_opt: userSelectedPaymentOption,
                },
                service: "shop",
                payment_mode: "wallet",
                payment_method: "wallet",
                payment_opt: userSelectedPaymentOption === "pay_now" ? "pay_after_delivery" : "pay_on_delivery",
                amount: userSelectedPaymentOption === "pay_now" ? orderTotals.total_amount : orderTotals.upfront_payment,
                save_cade: true
            }
            const make_payment = await pay_checkout({ data })
            localStorage.removeItem('konnect_cart')
            console.log(make_payment);
            // window.open(make_payment.data.data.response_data.link, "_self")
        } catch (err) {
            setLoadingFinalButton(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    return (
        <Spin spinning={loadingData} indicator={antIcon}>
            <div className="display-page">
                <div className="display-main-content main-center-message inline-status-box mt_3">
                    <div className="hero-sect">
                        <div className="container">
                            <div className="status-circle">
                                <img src={InfoImg} alt="confetti" />
                            </div>
                            <h4>Complete Order</h4>
                            <p className="form-explanation">
                                Please visit our website to complete your checkout process.
                            </p>
                            <div>
                                <a className="btn btn-base" target="_blank" href="https://konnect.ws/signin">Complete Order</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default CheckOutPage;