import React, { useEffect, useState } from "react";

import { Input, Drawer, Select, Spin, Tabs, notificatio, Drawern, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import NumberFormat from 'react-number-format';

import { fetchData, profileData } from "../slices/profileSlice";
import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import AppRoutes from "../common/routes";
import { _general_settings_by_id, _get_profile, _rate_setting_by_id, _update_user_type } from "../common/axios_services";
// import { _general_settings_by_id, _rate_setting_by_id } from '../common/axios_services';

import ConfettiImg from "../assets/images/Confetti.png";

const PartnerPage = () => {

    const Navigate = useNavigate();
    const [loadingSpinner, setLoadingSpinner] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [userData, setUserData] = useState({});
    const [userType, setUserType] = useState(false);
    const dispatch = useDispatch();
    const [jwt] = useState(localStorage.getItem("konnect_token"));
    const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    // start form handlers
    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm({});

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };

    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            dispatch(fetchData(true));
            let userData = user_profile?.data.data;
            setUserData(userData);
            setUserType(userData?.is_ra ? true : false);
            setLoadingSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
            setLoadingSpinner(false);
        }
    }

    useEffect(() => {
        if (jwt) {
            fetchUser();
        } else {
            setLoadingSpinner(false);
        }
    }, [])

    const [saCashSo, setSaCashSo] = useState(false)
    const [userCashFromSo, setUserCashFromSo] = useState(false)
    const [saCashUserLogin, setSaCashUserLogin] = useState(false)
    const [salesAssistantPer, setSalesAssistantPer] = useState(false)
    const [referFoodVendorVal, setReferFoodVendorVal] = useState(0)
    const [referFoodVendorVal2, setReferFoodVendorVal2] = useState(0)
    const [ancAverMonIncoMon, setAncAverMonIncoMon] = useState(0)
    const [ancAverMonIncoMon2, setAncAverMonIncoMon2] = useState(0)
    const [venRCustomer, setVenRCustomer] = useState(0)
    const [averMonInco, setAverMonInco] = useState(0)

    const [displayDataDrawer, setDisplayDataDrawer] = useState(false);

    const toggleDisplayDataDrawer = () => setDisplayDataDrawer(!displayDataDrawer);

    const saCashLoginSO = async () => {
        setSaCashSo(false)
        try {
            const sa_cash_so = await _general_settings_by_id({ id: "4" })
            setSaCashSo(sa_cash_so?.data?.data?.value)
        } catch (err) {
            setSaCashSo(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const soCashLogin = async () => {
        setSaCashUserLogin(false)
        try {
            const so_cash_login = await _rate_setting_by_id({ id: "8" })
            setSaCashUserLogin(so_cash_login?.data?.data?.app_value)
        } catch (err) {
            setSaCashUserLogin(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const userCashSO = async () => {
        setUserCashFromSo(false)
        try {
            const user_cash_from_so = await _general_settings_by_id({ id: "12" })
            setUserCashFromSo(user_cash_from_so?.data?.data?.app_value)
        } catch (err) {
            setUserCashFromSo(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const salesAssistantl2 = async () => {
        setSalesAssistantPer(false)
        try {
            const sales_assistant_per = await _rate_setting_by_id({ id: "10" })
            setSalesAssistantPer(sales_assistant_per?.data?.data?.level_b_value)
        } catch (err) {
            setSalesAssistantPer(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    useEffect(() => {
        saCashLoginSO()
        userCashSO()
        soCashLogin()
        salesAssistantl2()
    }, [])

    const handleLevel1 = (value) => {
        let val = value.target.value;
        let level = "1"
        ReferFoodVendor(val, level)
    }
    const handleLevel2 = (value) => {
        let val = value.target.value;
        let level = "2"
        ReferFoodVendor(val, level)
    }
    const ReferFoodVendor = (value, level) => {
        if (saCashSo && level === "1") {
            setReferFoodVendorVal(+saCashSo * +value)
        } else if (saCashSo && level === "2") {
            setReferFoodVendorVal2(+saCashSo * +value)
        }
    }
    const VenRCus = (value) => {
        let val = value.target.value;
        setVenRCustomer((+saCashUserLogin + +userCashFromSo) * +val)
    }
    const AveMonInc = (value) => {
        let val = value.target.value;
        if (salesAssistantPer) {
            setAverMonInco((+salesAssistantPer / 100) * 3000000 * +val)
        }
    }
    const AncAveMonIncMon = (value) => {
        let val = value.target.value;
        if (salesAssistantPer) {
            setAncAverMonIncoMon((+salesAssistantPer / 100) * 3000000 * +val)
        }
    }
    const AncAveMonIncMon2 = (value) => {
        let val = value.target.value;
        if (salesAssistantPer) {
            setAncAverMonIncoMon2(((+salesAssistantPer / 100) * 3000000 * +val) * 0.5)
        }
    }

    const updateUserType = async () => {
        if (userType) {
            setLoadingSpinner(true);
            try {
                const changeType = await _update_user_type({ status: !userData.is_ra });
                setLoadingSpinner(false);
                openNotificationWithIcon('success', changeType?.data?.message);
                setUserType(true);
                toggleDisplayDataDrawer();
            } catch (err) {
                if (err.response) {
                    if (err.response.data.message === "Unauthorized") {
                        openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                        Navigate('/signin')
                    } else {
                        openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.message);
                    }
                } else {
                    openNotificationWithIcon('error', err.message);
                }
            }
            setLoadingSpinner(false)
        } else {
            toggleDisplayDataDrawer();
        }
    }


    return (
        <Spin spinning={loadingSpinner} indicator={newAntIcon}>
            <div className="display-page story-page how-to">
                <div className="small-page-title contain">
                    <div className="grid-flex">
                        <div
                            onClick={() => Navigate(-1)}>
                            <ArrowLeftIcon />
                        </div>
                        {/* <h5>Transfer Cash</h5> */}
                        <div></div>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="display-main-content mt_2">
                    <div className="hero-sect">
                        <div className="container">
                            <h3 className="sec-page-title">Join Us</h3>
                            {/* <div className="top-selection-bar">
                            <div
                                className={`select-bar active-bar`}>
                                <div className="active-inner-text">
                                    <h4>Partner</h4>
                                </div>
                            </div>
                        </div> */}
                            <div>
                                <p>Welcome to our join us page. This page is for Tier 2 users that want to join our support team. Our support team 
                                    are freelance users that work with our food vendors and refer food vendor to grow a food vendor business.</p>
                                <h6></h6>
                                <div>
                                    <div>
                                        <div className="white-block white-bg mt_3">
                                            <h4>What you enjoy as a member of our support team!</h4>
                                            <ul>
                                                <li>
                                                    <span>1</span>
                                                    <p>Get up to NGN 1,000 when you sign-up a food vendor.</p>
                                                </li>
                                                <li>
                                                    <span>2</span>
                                                    <p>Earn average of NGN 150,000 weekly as sales commission.</p>
                                                </li>
                                                <li>
                                                    <span>3</span>
                                                    <p>Get lots of benefits from the platform.</p>
                                                </li>
                                                <h4 className="sec-title">Checkout what a food vendor can enjoy.</h4>
                                                <ul>
                                                    <li>
                                                        <span>a</span>
                                                        <p>Free delivery when they order in bulk (Lagos & environs)</p>
                                                    </li>
                                                    <li>
                                                        <span>b</span>
                                                        <p>We support them with instalment payments at low interest</p>
                                                    </li>
                                                    <li>
                                                        <span>c</span>
                                                        <p>NGN 100,000 monthly for referring other food vendors</p>
                                                    </li>
                                                    <li>
                                                        <span>d</span>
                                                        <p>NGN 50,000 monthly for referring their customers</p>
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                        <div className="white-block mt_3">
                                            <h4>Calculate what you can earn monthly</h4>
                                            <form onSubmit={handleSubmit()}>
                                                <div className="form_group">
                                                    <label htmlFor="food_vendor">What you get monthly when you refer food vendors</label>
                                                    <Input onChange={handleLevel1} name="food_vendor" id="food_vendor" placeholder="XXX food vendor"
                                                        style={{ height: "5rem" }} />
                                                    <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat
                                                        value={referFoodVendorVal} displayType={'text'} thousandSeparator={true} /></p>
                                                    <p className="sidenote">Note we may assign established Food Vendors to Sales Agent</p>
                                                </div>
                                                <div className="form_group sec">
                                                    <label htmlFor="sales_income">Your average monthly sales income</label>
                                                    <Input name="sales_income" id="sales_income" placeholder="XXX food vendor" onChange={AveMonInc}
                                                        style={{ height: "5rem" }} />
                                                    <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat
                                                        value={averMonInco} displayType={'text'} thousandSeparator={true} /></p>
                                                    <p className="sidenote">Calculation is based on food vendors that cooks average of 7 bags of rice (50kg) weekly</p>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="white-block mt_3">
                                            <h4>Check out what a Food Vendor can earn monthly</h4>
                                            <form onSubmit={handleSubmit()}>
                                                <div className="form_group">
                                                    <label htmlFor="food_vendor">What Anchor Food Vendor gets for referring other Food Vendors</label>
                                                    <Input name="food_vendor" onChange={handleLevel2} id="food_vendor" placeholder="XXX food vendor"
                                                        style={{ height: "5rem" }} />
                                                    <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat
                                                        value={referFoodVendorVal2} displayType={'text'} thousandSeparator={true} /></p>
                                                </div>
                                                <div className="form_group">
                                                    <label htmlFor="anchor_food">What anchor food vendor gets as sales commission (1st month)</label>
                                                    <Input onChange={AncAveMonIncMon} name="anchor_food" id="anchor_food" placeholder="XXX food vendor"
                                                        style={{ height: "5rem" }} />
                                                    <p className='divider2' style={{ textAlign: "right" }}>= NGN
                                                        <NumberFormat value={ancAverMonIncoMon} displayType={'text'} thousandSeparator={true} /></p>
                                                </div>
                                                <div className="form_group">
                                                    <label htmlFor="anchor_food">What anchor food vendor gets as monthly as sales commission</label>
                                                    <Input name="anchor_food" onChange={AncAveMonIncMon2} id="anchor_food" placeholder="XXX food vendor"
                                                        style={{ height: "5rem" }} />
                                                    <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={ancAverMonIncoMon2}
                                                        displayType={'text'} thousandSeparator={true} /></p>
                                                </div>
                                                <div className="form_group sec">
                                                    <label htmlFor="food_vendor">What Food Vendor can gets for referring their customers</label>
                                                    <Input onChange={VenRCus} name="food_vendor" id="food_vendor" placeholder="XXX food vendor"
                                                        style={{ height: "5rem" }} />
                                                    <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat
                                                        value={venRCustomer} displayType={'text'} thousandSeparator={true} /></p>
                                                </div>
                                            </form>
                                        </div>
                                        {
                                            !userData.is_sa ?
                                                <div className="white-block white-bg mt_3">
                                                    {/* <h4>To join us, you must complete a 30 minutes assessment</h4>
                                                    <p>This is a 30 minutes multiple choice assessment. All answers do carry a score
                                                        and we grade on best answers.</p> */}

                                                    <div className="">
                                                        <div className="welcome-buttons-content">
                                                            {/* {
                                                                userType ? */}
                                                            <button onClick={updateUserType} className="btn btn-base">Click here to become RA</button>
                                                            {/* :
                                                                    <button disabled className="btn btn-border-base">You are already an RA</button>
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div> : ""
                                        }
                                    </div>
                                </div>
                                <div className="mt_5"></div>
                            </div>
                            {/* <div>
                            <div className="mt_5">
                                <form>
                                    <div className="form-flex-2">
                                        <div className="form_group">
                                            <label>First name</label>
                                            <Controller name="first-name" control={control}
                                                render={({ field }) => (
                                                    <Input name="first-name" {...field} id="first-name"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                        <div className="form_group">
                                            <label>Last name</label>
                                            <Controller name="last-name" control={control}
                                                render={({ field }) => (
                                                    <Input name="last-name" {...field} id="last-name"
                                                        style={{ height: "5rem" }} />
                                                )} />
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label>Email address</label>
                                        <Controller name="email" control={control}
                                            render={({ field }) => (
                                                <Input name="email" type="email" {...field} id="email"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="form_group">
                                        <label>Phone number</label>
                                        <Controller name="phone-number" control={control}
                                            render={({ field }) => (
                                                <Input name="phone-number" {...field} id="phone-number"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="form_group">
                                        <label>Your interest</label>
                                        <Controller name="interest" control={control}
                                            render={({ field }) => (
                                                <Input name="interest" {...field} id="interest"
                                                    style={{ height: "5rem" }} />
                                            )} />
                                    </div>
                                    <div className="welcome-buttons">
                                        <div className="welcome-buttons-content">
                                            {
                                                !loadingData ?
                                                    <button className="btn-base">Continue</button>
                                                    :
                                                    <button className="btn-base" disabled><Spin indicator={antIcon} /></button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
                <Drawer footer={null} open={displayDataDrawer} onCancel={toggleDisplayDataDrawer} placement="bottom">
                    <div className="inline-status-box">
                        <div className="modal-disp status-bar empty">
                            <div className="extra-detail">
                                <div className="contain">
                                    <div className="status-circle">
                                        <img src={ConfettiImg} alt="confetti" />
                                    </div>
                                    <h4 style={{ textAlign: "center" }}>Welcome Onboard</h4>
                                    <p className="form-explanation">Thank you for your interest to become a Support manager. To learn more and dicuss
                                        with our CEO, click below.
                                    </p>
                                    <div>
                                        <a href="https://wa.me/message/VDOXKM2HBTWXF1" style={{ display: "block", textAlign: "center" }} target="_blank"
                                            className="btn btn-base">Reach out to us</a>

                                        <button style={{ display: "block", textAlign: "center", padding: "15px 25px" }} onClick={toggleDisplayDataDrawer}
                                            className="btn btn-blank">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>
                {/* <FooterDisplay /> */}
            </div>
        </Spin>
    )
}

export default PartnerPage;