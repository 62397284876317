import "../../../assets/css/mini.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Spin } from "antd";
import { DateTime } from 'luxon';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _get_user_account_history, _wise9ja_topup } from '../../../common/axios_services';
import { encrypted_token, fetchData, profileData } from '../../../slices/profileSlice';

import EmptyGIF from "../../../assets/images/illustrations/no-entry.gif";


import FooterDisplay from "../../../components/footer";
import Wise9jaHistoryDetailPage from "../../../components/profile/history/wise9ja";
import TopNav from "../../../components/top-nav";


const Wise9jaPage = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };

    // transaction code confirmation starts

    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const [majorPageSpinner, setMajorPageSpinner] = useState(true);


    // wise9ja process starts

    let jwt_token = localStorage.getItem('konnect_token');

    const [displayMode, setDisplayMode] = useState("content");
    const [historyData, setHistoryData] = useState({});


    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            if (profile.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(profile.data.data))
            } else {
                dispatch(fetchData(false))
                dispatch(profileData(false))
            }
        } catch (err) {
            dispatch(fetchData(false))
            dispatch(profileData(false))
        }
    }

    React.useEffect(() => {
        getProfile();
    }, [dispatch, token, jwt_token])

    const Navigate = useNavigate();

    const [wiseHis, setWiseHis] = useState([]);
    const [totalWise, setTotalWise] = useState(0);
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const [total_wise, setTotal_wise] = useState(0)
    const [total_loyalty, setTotal_loyalty] = useState(0)
    const [page_size, setPage_size] = useState(10)
    const [page_no, setPage_no] = useState(1)
    const [pageLoy, setPageLoy] = useState(1)
    const [pageSizeLoy, setPageSizeLoy] = useState(10)

    // fetch user wise9ja plan
    const fetchUserWise9jaPlan = async () => {
        try {
            let userWise9jaTrx = await _fetch_user_wise9ja_data({ page, page_size });
            setWiseHis(userWise9jaTrx.data.data);
            setTotalWise(userWise9jaTrx.data.meta.total);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    // fetch user wise9ja plan

    useEffect(() => {
        fetchUserWise9jaPlan();
    }, [])

    const setDisplayModeToContent = () => {
        setDisplayMode("content");
    }

    const handleHistoryDisplay = history => {
        setDisplayMode("history");
        setHistoryData(history);
    }


    return (
        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
            {
                displayMode === "content" ?
                    <div className="homepage wise9ja-disp display-page">
                        <TopNav title="Wise9ja History" />
                        <div className="display-main-content mt_2">
                            <div className="content-display-content">
                                <div className="contain">
                                    <div className="wise9ja-action-bar">
                                    </div>
                                    <div className="mt_5 history-data-detail">
                                        <div className="contai">
                                            {
                                                !majorPageSpinner ?
                                                    wiseHis.length ?
                                                        wiseHis.map((history, index) => (
                                                            <React.Fragment key={index}>
                                                                <div key={index}
                                                                    onClick={() => handleHistoryDisplay(history)}
                                                                    className="first-history-disp current-history-display">
                                                                    <div className="disp-category">
                                                                        <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                                                                    </div>
                                                                    <div>
                                                                        <h3>Wise9ja Transaction</h3>
                                                                        <ul>
                                                                            <li>{DateTime.fromISO(history.created_at).toLocaleString(DateTime.DATE_HUGE)}</li>
                                                                            <li>{DateTime.fromISO(history.created_at).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div>
                                                                        <h3 className="history-amount red">-<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                            className="numeric" displayType={'text'} value={(+history.amount).toFixed(2)} /></h3>
                                                                        <p className="status">successful</p>
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ margin: "20px 0px" }} />
                                                            </React.Fragment>
                                                        ))
                                                        :
                                                        <div>
                                                            <div className="empty-data">
                                                                <div>
                                                                    <img src={EmptyGIF} alt="failure gif" />
                                                                    <p>No data found</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterDisplay />
                    </div> :
                    <Wise9jaHistoryDetailPage historyData={historyData} displayModeControl={setDisplayModeToContent} />
            }
        </Spin>
    )
}

export default Wise9jaPage;