import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import { ReactComponent as TimesIcon } from "../../../assets/images/x.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/images/icons/download.svg";

import { fetchData, profileData } from '../../../slices/profileSlice';

import SuccessGIF from "../../../assets/images/illustrations/confetti.gif";
import FailureGIF from "../../../assets/images/illustrations/no-entry.gif";
import EmptyGif from "../../../assets/images/illustrations/empty.gif";

import { _get_profile, _verify_transactions } from "../../../common/axios_services";
import { useDispatch } from "react-redux";
import NumberFormat from "react-number-format";


const ActionStatusPage = () => {

    const Navigate = useNavigate();

    const [actionStatus, setActionStatus] = useState("pending");
    const [checkingDataState, setCheckingDataState] = useState(true);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const params = new URLSearchParams(window?.location?.search);
    const [tx_ref,] = useState(params.get('tx_ref') ? params.get('tx_ref') : '');
    const [status,] = useState(params.get('status') ? params.get('status') : '');
    const [transaction_id,] = useState(params.get('transaction_id') ? params.get('transaction_id') : '');
    const [payment_id,] = useState(params.get('payment_id') ? params.get('payment_id') : '');
    const [reference_key,] = useState(params.get('reference_key') ? params.get('reference_key') : '');
    const [transData, setTransData] = useState({});

    const [modal1Open, setModal1Open] = useState(false)
    const [err_status, setErr_status] = useState(true)
    const [msg, setMsg] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [transRef, setTransRef] = useState("");
    const [services, setServices] = useState("");
    const [transId, setTransId] = useState("");
    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;

    const getProfile = async () => {
        try {
            const user_profile = await _get_profile()
            dispatch(profileData(user_profile.data.data))
            dispatch(fetchData(true))

        } catch (err) {
            dispatch(profileData(false))
            dispatch(fetchData(false))
            if (err.response) {
                navigate("/signin?redir=review")
                localStorage.removeItem('konnect_token')
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', 'Network Error')
            }
        }
    }

    const verifyPaymant = async () => {
        setModal1Open(true)
        try {
            // const verified = await _verify_transactions({ status, tx_ref, transaction_id });
            const verified = await _verify_transactions(reference_key);
            if (verified?.data?.data?.service) {
                if (verified?.data?.data?.status === "pending") {
                    setActionStatus("pending");
                } else if (verified?.data?.data?.status === "success") {
                    setActionStatus("success");
                } else {
                    setActionStatus("failed");
                }
                setMsg(verified.data.message);
                setCheckingDataState(false);
                setTransData(verified.data.data);
                setTransRef(verified.data.data.transaction_ref)
                setTransId(verified.data.data.transaction_id)
                setServices(verified.data.data.service)
                setErr_status(false)
            } else {
                setActionStatus("null");
                setCheckingDataState(false);
            }
        } catch (err) {
            setErr_status(true)
            if (err.response) {
                setMsg(err.response.data.message)
            } else {
                setMsg(err.message)
            }
            setCheckingDataState(false);
        }
    }

    useEffect(() => {
        verifyPaymant();
        getProfile();
    }, [status, tx_ref, transaction_id])

    return (
        <div className={`display-page confirmation_page ${checkingDataState ? "center" : ""} `}>
            <div>
                {
                    !checkingDataState ?
                        <div className="receipt-display">
                            <div className="top-action-cover">
                                <div className="icon-cover">
                                    <TimesIcon />
                                </div>
                                <div className="icon-cover">
                                    <DownloadIcon />
                                </div>
                            </div>
                            <div className="display-main-content mt_2">
                                <div className="hero-sect">
                                    {
                                        actionStatus === "success" ?
                                            <div className="container status-header">
                                                <img src={SuccessGIF} alt="success gif" />
                                                <h3>Transaction Successful!</h3>
                                            </div>
                                            : actionStatus === "failed" ?
                                                <div className="container status-header">
                                                    <img src={SuccessGIF} alt="failure gif" />
                                                    <h3>Transaction failed!</h3>
                                                </div> :
                                                <div className="container status-header">
                                                    <img src={EmptyGif} alt="empty gif" />
                                                    <h3>Transaction Pending!</h3>
                                                </div>
                                    }
                                    <div className="panel-divider-block mt_2">
                                        <div className="receipt-divider"></div>
                                        <div className="small-circle left"></div>
                                        <div className="small-circle right"></div>
                                    </div>
                                    <div className="container mt_2">
                                        <div>
                                            <ul className="flex-list">
                                                <li>
                                                    <span>Reference number</span>
                                                    <span>{actionStatus === "null" ? "-" : transData.transaction_ref}</span>
                                                </li>
                                                <li>
                                                    <span>Date</span>
                                                    <span>{actionStatus === "null" ? "-" : DateTime.fromISO(transData.timestamp).toLocaleString(DateTime.DATE_HUGE)}</span>
                                                </li>
                                                <li>
                                                    <span>Payment Method</span>
                                                    <span>{actionStatus === "null" ? "-" : transData.payment_mode}</span>
                                                </li>
                                            </ul>
                                            <div className="receipt-divider mt_2"></div>
                                            <ul className="flex-list mt_2">
                                                <li>
                                                    <span>Amount</span>
                                                    <span><span className="currency">NGN</span>
                                                        {actionStatus === "null" ? "-" :
                                                            <NumberFormat thousandSeparator={true} prefix={''}
                                                                className="numeric" displayType={'text'} value={(+transData.total_amount).toFixed(2)} />
                                                        }
                                                    </span>
                                                </li>
                                            </ul>

                                            {
                                                actionStatus === "null" ? "" :
                                                    actionStatus === "success" ?
                                                        <button className="btn btn-border-gray mt_2">Download PDF</button>
                                                        : actionStatus === "failed" ?
                                                            <button className="btn btn-border-gray mt_2">Retry Payment</button>
                                                            : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="">
                            <Spin indicator={antIcon} />
                        </div>
                }
                <div className="welcome-buttons">
                    <div className="welcome-buttons-content mt_2">
                        <Link to="/home" className="btn-white">Go Home</Link>
                    </div>
                </div>
            </div>
            {/* <FooterDisplay /> */}
        </div>
    )
}

export default ActionStatusPage;

