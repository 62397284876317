
import { Input, Drawer, notification, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Jwt_decrypt from '../../common/Jwt_decrypt';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import axios from '../../common/axios_call';

import { _get_profile, _get_profile_from_signin, signIn } from '../../common/axios_services';
import { encrypted_token } from '../../slices/profileSlice';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";

import LogoImg from '../../assets/images/logo.png';
import { ReactComponent as LockIcon } from '../../assets/images/icons/lock.svg';
import AppRoutes from '../../common/routes';

const SignInPage = () => {
    const Navigate = useNavigate();
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);
    const [redirectUrl,] = useState(params.get('konnectrd') ? params.get('konnectrd') : '');
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [referralCode,] = useState(params.get('konnectrfc') ? params.get('konnectrfc') : '');
    const [openAuthResponseModal, setOpenAuthResponseModal] = useState(false);

    const [newAccountModal, setNewAccountModal] = useState(localStorage.getItem('konnectnewaccountcreated') === 'true' ? true : false);

    const signInCheck = yup.object().shape({
        emailAddress: yup.string().email('Email address is not valid').required('Email address field is required'),
        password: yup.string().min(6).required('Password field is required'),
    });

    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            emailAddress: "",
            password: "",
        },
        resolver: yupResolver(signInCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const fetchUser = async e => {
        try {
            const user_profile = await _get_profile_from_signin(e);
            let userData = user_profile?.data.data;
            // console.log(userData)
            if (userData.kyc_status === "verified") {
                Navigate(AppRoutes.wallet);
            } else {
                Navigate(AppRoutes.successful_signup);
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    const signInUser = async e => {
        setErrorMessage('');
        setFetchingData(true);
        let { emailAddress, password } = e;
        try {
            const response = await signIn({ email: emailAddress, password, app: "mobile", device_id: "", device_type: "", role_id: 1 });

            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response.data.data.token }))
            fetchUser(response.data.data.token);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    return (
        <div className="signin-page">
            <div>
                <div className="small-page-title grid-flex">
                    <div onClick={() => Navigate("/")}>
                        <ArrowLeftIcon />
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <div className="contain auth-content">
                <div className="mt_3">
                    <div className="auth-sect-div">
                        <h3 className="auth-sect-title">Welcome to Konnect!</h3>
                        <p>No account yet? <Link className="inner-link" to={AppRoutes.signup}>Click here to create one now.</Link></p>
                    </div>
                    <div className="error-block">{errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}</div>
                    <form onSubmit={handleSubmit(signInUser)}>
                        <div className="form_group">
                            <label htmlFor="emailAddress">Email address</label>
                            <Controller name="emailAddress" control={control}
                                render={({ field }) => (
                                    <Input name="emailAddress" {...field} id="emailAddress"
                                        style={{ height: "5rem" }} />
                                )} />
                            {/* {errors.emailAddress && <p className="form-error">{errors.emailAddress.message}</p>} */}
                        </div>
                        <div className="form_group">
                            <label htmlFor="emailAddress">Password</label>
                            <Controller name="password" control={control}
                                render={({ field }) => (
                                    <Input.Password name="password" {...field} id="password"
                                        style={{ height: "5rem" }} />
                                )} />
                            {/* {errors.password && <p className="form-error">{errors.password.message}</p>} */}
                            <div className="mt_2">
                                <Link className="inner-link" to={AppRoutes.reset_mail}><span>Click here to reset password.</span></Link>
                            </div>
                        </div>
                        <div className="welcome-buttons mt_15">
                            <div className="welcome-buttons-content">
                                {
                                    !fetchingData ?
                                        <button className="btn-blue">Log in to your account</button>
                                        :
                                        <button className="btn-blue" disabled><Spin indicator={antIcon} /></button>
                                }
                            </div>
                            <div className="welcome-buttons-content mt_2">
                                <Link className="btn-border-blue" to="/home">Continue as a guest</Link>
                            </div>
                        </div>
                    </form>
                    <div className="bottom-fold-info">
                        <p>Having problems logging in? <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank"
                            className="inner-link">Chat with us</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInPage;