import { useEffect, useState } from "react";
import { Drawer, Spin, notification } from "antd";
import { useOtpInput } from "react-otp-input-hook";
import OtpInput from 'react-otp-input';
import { LoadingOutlined } from "@ant-design/icons";

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Link, useNavigate } from "react-router-dom";
import { _generate_otp } from "../../common/axios_services";

const OTPCodePage = props => {

    const onChange = e => {
        props.handleOTPCode(e);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 23 }} spin />;

    const Navigate = useNavigate();

    const [inputData, setInputData] = useState("");
    const [currentOTPCounter, setCurrentOTPCounter] = useState(40);
    const [sendingOTP, setSendingOTP] = useState(false);
    const [startCounter, setStartCounter] = useState(false);

    const { register } = useOtpInput({
        onInputValueChange: onChange,
    });

    const defaultOptions = { required: true };

    const [inputCount] = useState(props.inputCount ? props.inputCount : 5);

    let inputBox = [];
    for (let i = 0; i < inputCount; i++) {
        inputBox.push(<input {...register(`digit-${i + 1}`, defaultOptions)} type="tel" />);
    }

    const onChangeInput = (input) => {
        if (input.length <= props.inputCount) {
            setInputData(input);
            props.stateFunc(input);
        }
    }

    const onKeyPressInput = (button) => {
        if (button === "{bksp}") {
            let currInput = inputData;
            let newInput = currInput.slice(0, -1);
            setInputData(newInput);
            props.stateFunc(newInput);
        }
    }

    const performUserAction = () => {
        props.closeDrawer();
        props.actionFunction();
        setInputData("");
    }

    useEffect(() => {
        if (startCounter || props.startCounter ) {
            let counter = currentOTPCounter;
            let currentInterval = setInterval(() => {
                if (counter > 0) {
                    counter = counter - 1;
                    setCurrentOTPCounter(counter);
                } else {
                    clearInterval(currentInterval);
                    setStartCounter(false);
                    props.counterAction();
                }
            }, 1000)
        }
    }, [startCounter, props.startCounter]);

    useEffect(() => {
        if (inputData.length === props.inputCount) {
            performUserAction();
        }
    }, [inputData]);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };

    const sendOTPToUser = async () => {
        setSendingOTP(true);
        try {
            const generate_otp = await _generate_otp();
            openNotificationWithIcon('success', generate_otp?.data?.message);
            setSendingOTP(false);
            setCurrentOTPCounter(40);
            setStartCounter(true);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon("error", err?.response?.data?.message);
                    Navigate('/signin')
                }
                openNotificationWithIcon("error", err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
            setSendingOTP(false);
        }
    }

    return (
        <div className="otp-input-box">
            <Drawer open={props.openDrawer} className="trans-code" onClose={props.closeDrawer} footer={null} title=""
                placement="bottom" closable={false} key="bottom">
                <div className="otp-cover">
                    <div className="center">
                        <h3>Enter OTP</h3>
                    </div>
                    <div className="modal-input-box mt_3">
                        <div className="otp-input-cover">
                            <OtpInput
                                value={inputData}
                                // onChange={setOtp}
                                numInputs={inputCount}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        <div className="onscreen mt_3">
                            <div className="numeric-cover">
                                {
                                    sendingOTP ?
                                        <Spin indicator={antIcon} /> :
                                        <button type="button" onClick={sendOTPToUser} className="btn btn-blank" disabled={currentOTPCounter === 0 ? false : true}
                                            to="">Resend OTP? {currentOTPCounter === 0 ? "" : <small>({currentOTPCounter}secs)</small>}</button>
                                }
                            </div>
                            <Keyboard
                                onChange={onChangeInput}
                                layout={{
                                    default: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"]
                                }}
                                theme="hg-theme-default hg-layout-numeric numeric-theme"
                                onKeyPress={onKeyPressInput} inputPattern="tel"
                            />
                        </div>
                    </div>
                    {/* <div className="mt_3">
                        <button onClick={props.actionFunction} className="btn btn-base">Complete</button>
                    </div> */}
                </div>
            </Drawer>
        </div>
    );
};

export default OTPCodePage;