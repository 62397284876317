import "../../../assets/css/mini.css";

import React, { useState, useEffect } from "react";

import { Input, Divider, notification, Spin, Select } from "antd";
import { DateTime } from 'luxon';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import Jwt_decrypt from '../../../common/Jwt_decrypt';
import axiosCall from '../../../common/axios_call';

import { ReactComponent as CartIcon } from "../../../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/images/icons/mobilenotification.svg";
import { ReactComponent as PocketIcon } from "../../../assets/images/icons/pocket.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/icons/plus.svg";
import { ReactComponent as PieChartIcon } from "../../../assets/images/icons/pie-chart.svg";
import { ReactComponent as MenuIcon } from "../../../assets/images/icons/menu.svg";
import { ReactComponent as GiftIcon } from "../../../assets/images/icons/gift.svg";
import { ReactComponent as HardDriveIcon } from "../../../assets/images/icons/hard-drive.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/images/icons/arrow-left.svg";

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _get_user_account_history, _wise9ja_topup } from '../../../common/axios_services';
import { encrypted_token, fetchData, profileData } from '../../../slices/profileSlice';


import FooterDisplay from "../../../components/footer";
import { Controller, useForm } from "react-hook-form";


const SingleHistoryPage = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
            placement: "bottomRight"
        });
    };

    // transaction code confirmation starts
    const { handleSubmit: handleTransactionCodeForm, control: controlTransactionCodeConfirmation,
        formState: { errors: errorsTransCodeConfirmation } } = useForm({});

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const [currentDispTab, setCurrentDispTab] = useState("summary");
    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const [majorPageSpinner, setMajorPageSpinner] = useState(true);

    // transaction code confirmation ends

    // wise9ja process starts

    let jwt_token = localStorage.getItem('konnect_token');
    const [amount, setAmount] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState({});
    const [wise9jaPeriods, setWise9jaPeriods] = useState([]);
    const [wise9jaPeriodData, setWise9jaPeriodData] = useState([]);
    const [userPeriod, setUserPeriod] = useState('');
    const [wise9jaRespData, setWise9jaRespData] = useState('');
    const [walletData, setWalletData] = useState({});
    const [errMsg, setErrMsg] = useState(false);
    const [userPeriodDateSchedules, setUserPeriodDateSchedules] = useState([]);



    const getWise9ja = async () => {
        try {
            const wise9ja = await _get_all_wise9ja();
            let newArray = [];
            wise9ja.data.data.forEach(wise => {
                newArray[wise.plan_name] = wise;
            })
            setWise9jaPeriods(wise9ja.data.data);
            setWise9jaPeriodData(newArray);
        } catch (err) {
            console.log(err)
        }
    }
    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            if (profile.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(profile.data.data))
            } else {
                dispatch(fetchData(false))
                dispatch(profileData(false))
            }
        } catch (err) {
            dispatch(fetchData(false))
            dispatch(profileData(false))
        }
    }

    const fetchUserWalletBalance = async e => {
        try {
            let fetchUserAccountBalance = await _fetch_user_wallet_data();
            if (fetchUserAccountBalance.data.status === "success") {
                setWalletData(fetchUserAccountBalance.data.data);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
            }
            console.log(fetchUserAccountBalance)
        } catch (err) {
            console.log(err)
            openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
        }
    }

    React.useEffect(() => {
        getWise9ja();
        getProfile();
        fetchUserWalletBalance();
    }, [dispatch, token, jwt_token])

    const Navigate = useNavigate();

    const [walletBal, setWalletBal] = useState(0)
    const [voucherBal, setVoucherBal] = useState(0)
    const [ledgerBal, setLedgerBal] = useState(0);
    const [wise9jaBal, setWise9jaBal] = useState(0);

    const [wiseHis, setWiseHis] = useState([]);
    const [totalWise, setTotalWise] = useState(0);
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const [total_wise, setTotal_wise] = useState(0)
    const [total_loyalty, setTotal_loyalty] = useState(0)
    const [page_size, setPage_size] = useState(10)
    const [page_no, setPage_no] = useState(1)
    const [pageLoy, setPageLoy] = useState(1)
    const [pageSizeLoy, setPageSizeLoy] = useState(10)

    // fetch user wise9ja plan
    const fetchUserWise9jaPlan = async () => {
        try {
            let userWise9jaTrx = await _fetch_user_wise9ja_data({ page, page_size });
            console.log(userWise9jaTrx);
            setWiseHis(userWise9jaTrx.data.data);
            setTotalWise(userWise9jaTrx.data.meta.total);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    // fetch user wise9ja plan
    const fetchDashboardDetails = async () => {
        try {
            const getDashDetails = await _get_user_account_history()
            setWalletBal(getDashDetails.data.data.wallet_balance);
            setVoucherBal(getDashDetails.data.data.wisenija_cash_earned);
            setWise9jaBal(getDashDetails.data.data.wisenija_sub);
            setLedgerBal(getDashDetails.data.data.wisenija_cash_to_earn);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', "An error occurred while loading user-details.")
            }
        }
    }

    useEffect(() => {
        fetchDashboardDetails();
        fetchUserWise9jaPlan();
    }, [])




    return (
        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
            <div className="homepage wise9ja-disp display-page single-history">
                <div className="small-page-title contain">
                    <div className="grid-flex">
                        <div
                            onClick={() => Navigate(-1)}>
                            <ArrowLeftIcon />
                        </div>
                        {/* <h5>Transfer Cash</h5> */}
                        <div></div>
                        <div className="chat">
                            <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                                <ion-icon name="chatbubbles-outline"></ion-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="display-main-content mt_2">
                    <div className="content-display-content">
                        <div className="contain">
                            <div className="mt_3 top-card-display">
                                <p>Wise9ja Amount</p>
                                <h3 className="history-amount"><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                    className="numeric" displayType={'text'} value={37000.00} /></h3>
                            </div>
                            <div className="mt_5 history-data-detail">
                                {/* <h3 className="tile-header">Wise9ja History</h3> */}
                                <div className="top-selection-bar">
                                    <div
                                        onClick={() => { setCurrentDispTab("summary") }}
                                        className={`select-bar ${currentDispTab === "summary" ? "active-bar" : ""}`}>
                                        <div className="active-inner-text">
                                            <h4>Transaction Summary</h4>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => { setCurrentDispTab("dates") }}
                                        className={`select-bar ${currentDispTab === "dates" ? "active-bar" : ""}`}>
                                        <div className="active-inner-text">
                                            <h4>Payment Dates</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="contai mt_2">
                                    {
                                        wiseHis.map((history, index) => (
                                            <div key={index}>
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Wise9ja Transaction</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">{history?.status?.slice(0, 1).toUpperCase()}{history?.status?.slice(1)}</h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Amount Settled</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">-<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                            className="numeric" displayType={'text'} value={(+history.amount_settled).toFixed(2)} /></h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Wise9ja Plan</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">{history?.plan?.plan_name}</h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Wise9ja Duration</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">{history.month_duration} {history.month_duration ? "months" : "month"}</h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Monthly Payment</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">-<span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                            className="numeric" displayType={'text'} value={(+history.monthly_payment).toFixed(2)} /></h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Monthly Commission</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount"><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                            className="numeric" displayType={'text'} value={(+history.monthly_commission).toFixed(2)} /></h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Instant Commission</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount"><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                            className="numeric" displayType={'text'} value={(+history.instance_commission).toFixed(2)} /></h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Voucher Bonus</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount"><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                            className="numeric" displayType={'text'} value={(+history.voucher_bonus).toFixed(2)} /></h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Settlement Date</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">{DateTime.fromISO(history.settlement_date).toLocaleString(DateTime.DATE_HUGE)}</h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 10 }} />
                                                <div className="current-history-display">
                                                    <div>
                                                        <h3 className="hist-title">Description</h3>
                                                    </div>
                                                    <div>
                                                        <h3 className="history-amount">{history.description}</h3>
                                                    </div>
                                                </div>
                                                <Divider style={{ marginBottom: "10px", marginTop: 15 }} />
                                            </div>
                                        ))
                                    }
                                    <div className="welcome-buttons">
                                        <div className="welcome-buttons-content">
                                            <Link to="/" className="btn-base">Report Transaction</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    )
}

export default SingleHistoryPage;