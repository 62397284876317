import "../assets/css/homepage.css";

import React, { useState, useEffect } from "react";

import { Input } from "antd";
import 'swiper/css';
import { Link } from "react-router-dom";

import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import { ReactComponent as SearchIcon } from "../assets/images/icons/search.svg";

import ShopBanner from "../assets/images/banners/banner.jpg";
import CustormerImg from "../assets/images/icons/customer.svg";

import FooterDisplay from "../components/footer";
import AllProducts from "../components/products";
import AllCategories from "../components/category";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import AppRoutes from "../common/routes";


const Homepage = () => {

    const [fixedNav, setFixed] = useState(false);
    const [filterText, setFilterText] = useState("");
    const cart = useSelector(state => state.userCart.cart);
    const userData = useSelector(state => state);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            } else {
                setFixed(false);
            }
        })
    }, [])

    const filterTextUpdate = e => {
        setFilterText(e.target.value);
    }

    return (
        <div className="homepage home-display shop">
            <div
                className={`top-bar ${fixedNav ? 'fixed' : ''} contain mt_1`}>
                <div className="grid-flex top-welcome-address">
                    <div className="chat">
                        <a href="https://tawk.to/chat/61590f9625797d7a8901fd89/default" target="_blank">
                            <img src={CustormerImg} className="customer" alt="customer" />
                        </a>
                    </div>
                    <div>
                        <h5>Hey, you</h5>
                    </div>
                    <div>
                        <Link className="cartIcon" to={`/cart`}>
                            {cart ?
                                <div className="cart-box">
                                    <CartIcon />
                                    <h4>{cart?.data?.length}</h4>
                                </div>
                                :
                                <div className="cart-box">
                                    <CartIcon />
                                    <h4>0</h4>
                                </div>
                            }
                        </Link>
                    </div>
                </div>
                <div className="search-bar mt_2">
                    <div>
                        <div className="search-icon-cover">
                            <SearchIcon />
                        </div>
                    </div>
                    <Input name="searchKey" onChange={filterTextUpdate} placeholder='Search for "Groceries"' className="search-bar-input" />
                </div>
            </div>
            <div className="hero-sect mt_2">
                <div className="contain">
                    <div className="shop-banner-div">
                        <img src={ShopBanner} alt="banner" />
                    </div>
                </div>
            </div>
            <div className="mt_2">
                <AllCategories />
            </div>
            <div className="divider-line mt_2"></div>
            <div className="hero-sect mt_2">
                <div className="contain">
                    <Swiper
                        spaceBetween={15} slidesPerView={1.2}
                        loop={false} breakpoints={5}>
                        <SwiperSlide key={`extra-data-cover-1`}>
                            <div className="extra-data-message">
                                <div className="extra-data-content">
                                    <h3>Buy in bulk as a food vendor.</h3>
                                    <div>
                                        <p>Pay in bits and we deliver for free.</p>
                                    </div>
                                </div>
                                <div className="extra-div _1"></div>
                                <div className="extra-div _2"></div>
                                <div className="extra-div _3"></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={`extra-data-cover-2`}>
                            <div className="extra-data-message">
                                <Link to={AppRoutes.partner}>
                                    <div className="extra-data-content">
                                        <h3>Become a sales agent & earn weekly.</h3>
                                        <div>
                                            <p>Visit our Join us page.</p>
                                        </div>
                                    </div>
                                </Link>
                                <div className="extra-div _1"></div>
                                <div className="extra-div _2"></div>
                                <div className="extra-div _3"></div>
                            </div>
                        </SwiperSlide>
                        {/* {`extra-data-cover`} */}
                    </Swiper>
                </div>
            </div>
            <div className="body-content contain mt_3">
                <AllProducts filterText={filterText} />
            </div>
            <FooterDisplay />
        </div>
    )
}

export default Homepage;