
import { Input, DatePicker, notification, Spin, Radio } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import { _complete_user_kyc, _get_profile, _get_profile_from_signin, _save_user_bvn, _send_password_reset_mail } from '../../common/axios_services';
import { encrypted_token } from '../../slices/profileSlice';
import { ReactComponent as ArrowLeftIcon } from "../../assets/images/icons/arrow-left-circle.svg";
import AppRoutes from '../../common/routes';
import TopNav from '../../components/top-nav';
import KYCAddressForm from './kysc_sec';

const CompleteKYCPage = () => {
    const Navigate = useNavigate();
    let params = new URLSearchParams(window?.location?.search);
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false);
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [userData, setUserData] = useState([]);
    const [userGenderOption, setUserGenderOption] = useState("");
    const [userDOB, setUserDOB] = useState("");
    const [userBasicInfo, setUserBasicInfo] = useState({
        dob: "",
        gender: "",
        mobile_number: "",
        email: "",
        bvn: "",
        user_image: "",
        middle_name: "",
    });
    const [currDisplay, setCurrDisplay] = useState("default");

    const resetCheck = yup.object().shape({
        bvn: yup.string().required('Please enter BVN'),
    });


    const { handleSubmit, control, formState: { errors } } = useForm({
        defaultValue: {
            bvn: ""
        },
        resolver: yupResolver(resetCheck)
    });

    const { handleSubmit: handleFinalSubmit, control: controlFinalSubmit, formState: { errors: finalSubmitError } } = useForm({
        // resolver: yupResolver(resetCheck)
    });

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message
        });
    };

    const genderOptions = [
        {
            label: 'Male',
            value: 'male',
        },
        {
            label: 'Female',
            value: 'female',
        }
    ];

    const onChangeGenderOption = ({ target: { value } }) => {
        setUserGenderOption(value);
    };

    const onChangeUserDOB = (date, dateString) => {
        setUserDOB(dateString);
    };

    const fetchUser = async () => {
        try {
            const user_profile = await _get_profile();
            let userData = user_profile?.data.data;
            setUserData(userData);
            setUserGenderOption(userData.gender);
            if (userData.kyc_status === "verified") {
                Navigate(AppRoutes.wallet);
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }
    const jwt = localStorage.konnect_token;
    useEffect(() => {
        if (jwt) {
            fetchUser();
        }
    }, [])

    const saveBasicInfo = e => {
        if (userGenderOption.length && userDOB.length) {
            setUserBasicInfo({
                dob: userDOB,
                gender: userGenderOption,
                bvn: e.bvn,
                user_image: "",
                middle_name: ""
            })
            setCurrDisplay("address");
        } else {
            openNotificationWithIcon("error", "Please enter all fields");
        }
    }

    const SendUserBVN = async e => {
        let kycData = {
            bvn: userBasicInfo.bvn
        }
        try {
            const response = await _save_user_bvn(kycData);
            openNotificationWithIcon('success', response.data.message)
            Navigate(AppRoutes.wallet);
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                setErrorMessage(err.response.data.message);
            } else {
                setErrorMessage(err.message);
            }
        }
    }

    const removeCountryCode = (phoneNumber) => {
        if (phoneNumber.startsWith('234')) return '0' + phoneNumber.substring(3);
        else if (phoneNumber.startsWith('+234')) return '0' + phoneNumber.substring(4)
    };

    const SaveUserBasicData = async e => {
        setErrorMessage('');
        setFetchingData(true);

        console.log(userData)
        console.log(userData?.phone_number)
        // let kycData = {
        //     ...userBasicInfo,
        //     first_name: userData.first_name,
        //     last_name: userData.last_name,
        //     mobile_number: removeCountryCode(userData?.phone_number),
        //     email: userData.email,
        //     city: e.city,
        //     home_address: e.house_address,
        //     country: "NG",
        //     lga: e.lga,
        //     postalCode: e.postal_code,
        //     state: e.state,
        // }
        // try {
        //     const response = await _complete_user_kyc(kycData)
        //     SendUserBVN();
        // } catch (err) {
        //     setFetchingData(false);
        //     if (err.response) {
        //         setErrorMessage(err?.response?.data.message);
        //     } else {
        //         setErrorMessage(err.message);
        //     }
        // }
    }

    const updateCurrentDisplay = () => {
        setCurrDisplay(currDisplay === "default" ? "address" : "default");
    }

    return (
        <div className="signin-page kyc">
            <TopNav title="Complete KYC" />
            <div className="contain auth-content">
                <div className="mt_3">
                    <p className="form-explanation">
                        We require your BVN to verify your identity so we can prevent theft. This is a completely safe process and we do not
                        save your BVN in our database.
                    </p>
                    <div className="error-block">{errorMessage.length ? <p className="form-error">{errorMessage}</p> : ""}</div>
                    {
                        currDisplay === "default" ?
                            <form onSubmit={handleSubmit(saveBasicInfo)}>
                                <div className="form_group">
                                    <label htmlFor="gender">Gender</label>
                                    <Radio.Group
                                        style={{ height: "5rem", display: "block" }}
                                        options={genderOptions}
                                        onChange={onChangeGenderOption}
                                        value={userGenderOption}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </div>
                                <div className="form_group">
                                    <label htmlFor="dob">Date of birth</label>
                                    <Controller name="dob" control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                style={{ height: "5rem", display: "flex", alignItems: "center" }}
                                                onChange={onChangeUserDOB} />
                                        )} />
                                    {/* {basicInfoError.last_name && <p className="form-error">{basicInfoError.last_name.message}</p>} */}
                                </div>
                                {/* <div className="grid-2">
                            <div className="form_group">
                                <label htmlFor="postal_code">Postal Code</label>
                                <Controller name="postal_code" control={control}
                                    render={({ field }) => (
                                        <Input {...field}
                                            style={{ height: "5rem", display: "flex", alignItems: "center" }}
                                        />
                                    )} />
                            </div>
                            <div className="form_group">
                                <label htmlFor="city">City</label>
                                <Controller name="city" control={control}
                                    render={({ field }) => (
                                        <Input {...field}
                                            style={{ height: "5rem", display: "flex", alignItems: "center" }}
                                        />
                                    )} />
                            </div>
                        </div> */}
                                <div className="form_group">
                                    <label htmlFor="bvn">Enter BVN</label>
                                    <Controller name="bvn" control={control}
                                        render={({ field }) => (
                                            <Input name="bvn" {...field} id="bvn"
                                                style={{ height: "5rem" }} />
                                        )} />
                                    {errors.bvn && <p className="form-error">{errors.bvn.message}</p>}
                                </div>
                                <div className="welcome-buttons mt_5">
                                    <div className="welcome-buttons-content">
                                        {
                                            !fetchingData ?
                                                <button className="btn-blue">Continue</button>
                                                :
                                                <button className="btn-blue" disabled><Spin indicator={antIcon} /></button>
                                        }
                                    </div>
                                </div>
                            </form>
                            :
                            <KYCAddressForm formAction={SaveUserBasicData} errorMessage={errorMessage}
                                routeControl={updateCurrentDisplay} loadingData={fetchingData} />
                    }
                </div>
            </div>
        </div>
    )
}

export default CompleteKYCPage;