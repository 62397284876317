import Jwt_decrypt from "./Jwt_decrypt";
import axiosCall from "./axios_call";

const signIn = async (data) => {
    return axiosCall.post('/auth/login', data)
}

export const _send_password_reset_mail = async (data) => {
    return axiosCall.post('/auth/forgot-password', data)
}

export const _sign_user_up = data => {
    return axiosCall.post('/auth/sign-up/user', data)
}

export const _verify_user_passcode_for_password_reset = data => {
    return axiosCall.post('/auth/verify/pass_code', data)
}

export const _reset_user_password = (id, data) => {
    return axiosCall.patch(`/auth/reset-password/${id}`, data)
}

const pay_checkout = async ({ data, token }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post('/transactions/initiate_payments', data, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.post('/transactions/initiate_payments', data, { headers: { "Authorization": "Bearer" + "" } })
    }
}

export const _verify_user_email_signup = async e => {
    return axiosCall.post('/auth/confirm-email/pass_code', e)
}

const _get_profile = async () => {
    const jwt_token = localStorage.konnect_token
    const appRole = "user"
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": "Bearer" + "" } })
    }
}

export const _get_user_wallet = async () => {
    const jwt_token = localStorage.konnect_token;
    const appRole = "user";
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/wallet/user_wallet?app=${appRole}`, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.get(`/wallet/user_wallet?app=${appRole}`, { headers: { "Authorization": "Bearer" + "" } })
    }
}

export const _get_profile_from_signin = async e => {
    const appRole = "user";
    return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": `Bearer ${e}` } })
}

export const _complete_user_kyc = async data => {
    // const jwt_token = localStorage.konnect_token;
    // const token = Jwt_decrypt({ token: jwt_token });
    // return axiosCall.post(`/admin/user/complete_basic_info`, e, { headers: { "Authorization": `Bearer ${token}` } });

    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch('/admin/user/complete_basic_info', data, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.patch('/admin/user/complete_basic_info', data, { headers: { "Authorization": "Bearer" + "" } })
    }
}

export const _save_user_bvn = async e => {
    const jwt_token = localStorage.konnect_token
    const token = Jwt_decrypt({ token: jwt_token })
    return axiosCall.post(`/user-profile/complete_kyc`, e, { headers: { "Authorization": `Bearer ${token}` } })
}

const _get_all_referrals = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/referral/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/referral/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_all_transaction_data = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/transactions/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transactions/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_all_account_details = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/account/details?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/account/details?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _confirm_user_transfer_pin = async pin => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/admin/user/confirm-pin/${pin}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin/user/confirm-pin/${pin}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_user_wallet_history = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_saved_address = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delevery-addresses/user`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delevery-addresses/user`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_delivery_address = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/delevery-addresses`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/delevery-addresses`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_delivery_date_by_payment_option = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-date/available_dates?payment_opt=${e}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-date/available_dates?payment_opt=${e}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _bank_verification = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/banks_account/verification`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/banks_account/verification`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}


export const _checkout_summary = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post('/transactions/checkout_summery', data, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.post('/transactions/checkout_summery', data, { headers: { "Authorization": "Bearer" + "" } })
    }
}
export const _shop_discount = async ({ sub_amount }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post('/rate-settings/shop_discout', { sub_amount }, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.post('/rate-settings/shop_discout', { sub_amount }, { headers: { "Authorization": "Bearer" + "" } })
    }
}

export const _change_order_status = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/orders/${id}/change_status`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/orders/${id}/change_status`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _order_status_change = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/orders/calcel_order/${id}`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/orders/calcel_order/${id}`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _wallet_verification = async ({ ref }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/admin/user/ref_code/${ref}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.get(`/admin/user/ref_code/${ref}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _payout = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/initiat`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/initiat`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _delivery_info = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/delivery-fees/shop_order`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/delivery-fees/shop_order`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _change_password = async ({ current_password, new_password, confirm_new_password }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/change-password`, { current_password, new_password, confirm_new_password }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/change-password`, { current_password, new_password, confirm_new_password }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_profile = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/auth/profile`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/auth/profile`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_2fa_verification = async ({ value }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/change_login_2fa`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/change_login_2fa`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _verify_2fa = async ({ otp }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_trans_pin = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/auth/change-transaction-pin`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/auth/change-transaction-pin`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _calc_commission = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/rate-settings/transaction_fees`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/rate-settings/transaction_fees`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _wise9ja_topup = async (data) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transactions/initiate_payments`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transactions/initiate_payments`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _rate_setting_by_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/rate-settings/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.get(`/rate-settings/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _single_product = async ({ id }) => {
    return axiosCall(`/products/${id}`)
}

const _generate_otp = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/generate_otp`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/generate_otp`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _general_settings_by_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/general-settings/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.get(`/general-settings/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_banks = async ({ code }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/transfer/banks/${code}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transfer/banks/${code}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _verify_otp = async ({ otp }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/confirm-otp-staus/${otp}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_state = async () => {
    return axiosCall(`/states/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
}

const _get_search_product = async ({ page, page_size, search }) => {
    return axiosCall(`/products?page=${page}&per_page=${page_size}&search=${search}`)
}

export const _all_product = async ({ page, per_page, search }) => {
    return axiosCall(`/products/no_pagination?page=${page}&per_page=${per_page}&search=${search}`)
}

const _get_all_cities = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/cities/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/cities/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_pickup_locations = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/locations/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/locations/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_selected_delivery_location = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delevery-addresses/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delevery-addresses/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_selected_pickup_location = async ({ id }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/locations/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/locations/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_invoice = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/invoice/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/invoice/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_all_orders = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/user?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _complete_wise9ja_payment = async e => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/wisenija-subs`, e, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/wisenija-subs`, e, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _fetch_wise9ja_plans = async e => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/wisenija-subs/generate_sub_plan`, e, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/wisenija-subs/generate_sub_plan`, e, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_wise9ja_data = async ({ page_no, page_size }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija-subs/user?page=${page_no}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija-subs/user?page=${page_no}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _fetch_user_sales_report = async ({ page, page_size, search, from, to }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/sa/so_users?page=${page}&per_page=${page_size}&search=${search}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/sa/so_users?page=${page}&per_page=${page_size}&search=${search}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_sales_report_metric = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token });
        return axiosCall(`/sales-reps/user/metrix`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/user/metrix`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_referral_pool = async () => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-profile/referal_pool`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-profile/referal_pool`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_wallet_data = async e => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_wallet_data = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/account_balance`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/account_balance`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_user_account_history = async () => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/loyalty_account`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/loyalty_account`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_dashboard_data = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/account_history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/account_history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_wisenija_history = async e => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_bank_list = async country => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // https://test-api.konnectbd.com/api/v1/beneficiaries/banks
        return axiosCall(`/beneficiaries/banks`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/beneficiaries/banks`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _complete_bank_transfer = async req => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transfer/nip`, req, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/nip`, req, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _complete_wallet_transfer = async req => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // return axiosCall.post(`/transfer/initiate_internal`, req, { headers: { "Authorization": "Bearer " + token } })
        return axiosCall.post(`/transfer/wallet_to_wallet`, req, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transfer/wallet_to_wallet`, req, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _verify_user_bank_account = async (bank_code, account_num) => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // https://test-api.konnectbd.com/api/v1/beneficiaries/verify_account_number/100004/0000000000
        return axiosCall(`/beneficiaries/verify_account_number/${bank_code}/${account_num}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/beneficiaries/verify_account_number/${bank_code}/${account_num}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_beneficiary_list = async () => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // https://test-api.konnectbd.com/api/v1/beneficiaries/verify_account_number/100004/0000000000
        return axiosCall(`/beneficiaries/user`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/beneficiaries/user`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _verify_user_from_ref_code = async e => {
    const jwt_token = localStorage.konnect_token;
    const token = Jwt_decrypt({ token: jwt_token })
    // return axiosCall(`/admin/user/ref_code/${e}`, { headers: { "Authorization": "Bearer " + token } })
    return axiosCall(`/wallet/verify_user_account/${e}`, { headers: { "Authorization": "Bearer " + token } })
}
export const _fetch_amount_trans_fee = async e => {
    const jwt_token = localStorage.konnect_token;
    const token = Jwt_decrypt({ token: jwt_token })
    return axiosCall.post(`/rate-settings/transaction_fees`, e, { headers: { "Authorization": "Bearer " + token } })
}

const _fund_user_wallet = req => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/transactions/found_wallet`, req, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/transactions/found_wallet`, req, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _complete_user_paybill = (action, req) => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/bills/${action}`, req, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/bills/${action}`, req, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _fetch_user_data_options = action => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/bills/products/${action}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/bills/products/${action}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_meter_data = (slug, action) => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/bills/validate_customer/${slug}/${action}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/bills/validate_customer/${slug}/${action}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_bill_options = req => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/bills/providers?category_slug=${req}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/bills/providers?category_slug=${req}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _fetch_user_referral_data = (page, per_page) => {
    const jwt_token = localStorage.konnect_token;
    const token = Jwt_decrypt({ token: jwt_token })
    return axiosCall(`/referral/user?page=${page}&per_page=${per_page}&category=all`, { headers: { "Authorization": "Bearer " + token } })
}

export const _fetch_user_referral_bonus_account = (page, per_page) => {
    const jwt_token = localStorage.konnect_token;
    const token = Jwt_decrypt({ token: jwt_token })
    return axiosCall(`/user-dashboard/bonus_account_history?page=${page}&per_page=${per_page}&category=all`, { headers: { "Authorization": "Bearer " + token } })
}

export const _update_user_type = async ({ status }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/become_ra`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/become_ra`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_invoice = async ({ trxt_ref }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/invoice/transaction_ref/${trxt_ref}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/invoice/transaction_ref/${trxt_ref}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_dates = async ({ type }) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // /delivery-date/type/${type}
        return axiosCall(`/delivery-date/available_dates`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-date/available_dates`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _verify_transactions = async (tx_ref) => {
    const jwt_token = localStorage.konnect_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        // /delivery-date/type/${type}
        return axiosCall(`/transactions/verify_payment_callback/${tx_ref}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transactions/verify_payment_callback/${tx_ref}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_wise9ja = async () => {
    return axiosCall(`/wisenija_plan/user?page=1&per_page=20`)
}

const _get_bills = async ({ searchParams }) => {
    return axiosCall(`/bills/categories/search?search=${searchParams}`)
}

export const verify_user_email = async token => {
    return axiosCall(`/auth/${token}/confirm-email`)
}

export const _user_dashboard_referrals = async token => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/referal`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/referal`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _user_dashboard_shop = async token => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/shop`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/shop`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _user_dashboard_wise9ja = async token => {
    const jwt_token = localStorage.konnect_token;
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-dashboard/wisenija_history`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export {
    _add_delivery_address, _bank_verification, _calc_commission, _change_password, _delivery_info, _generate_otp, _get_all_banks, _get_all_cities, _get_all_dates, _get_all_invoice, _get_all_pickup_locations, _get_all_referrals, _get_all_state, _get_all_wise9ja, _get_bills, _get_invoice, _get_user_wallet_history, _get_profile,
    _get_saved_address, _get_search_product, _get_selected_delivery_location, _get_selected_pickup_location, _payout,
    _rate_setting_by_id, _update_2fa_verification, _update_profile, _update_trans_pin, _verify_2fa, _verify_otp,
    _verify_transactions, _wallet_verification, _wise9ja_topup, pay_checkout, signIn, _fund_user_wallet,
    _confirm_user_transfer_pin
};

