import "../assets/css/mini.css";

import React, { useState, useEffect } from "react";

import { Divider, notification, Spin } from "antd";
import { DateTime } from 'luxon';
import 'swiper/css';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";

import AllAppRoutes from "../common/routes";

import { ReactComponent as ArrowLeftIcon } from "../assets/images/icons/arrow-left.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/mobilecart.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/icons/mobilenotification.svg";
import { ReactComponent as PieChartIcon } from "../assets/images/icons/pie-chart.svg";
import { ReactComponent as GiftIcon } from "../assets/images/icons/gift.svg";

import FolderIcon from "../assets/images/illustrations/12_folder.png";

import { _complete_wise9ja_payment, _confirm_user_transfer_pin, _fetch_user_sales_report, _fetch_user_sales_report_metric, _fetch_user_wallet_data, _fetch_user_wise9ja_data, _fetch_wise9ja_plans, _get_all_account_details, _get_all_wise9ja, _get_profile, _get_user_account_history, _wise9ja_topup } from '../common/axios_services';
import { fetchData, profileData } from '../slices/profileSlice';

import EmptyGIF from "../assets/images/illustrations/no-entry.gif";

import FooterDisplay from "../components/footer";
import { Swiper, SwiperSlide } from "swiper/react";
import SalesReportHistoryPage from "../components/profile/history/sales-report";
import TopNav from "../components/top-nav";

const SalesReportPage = () => {

    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
            placement: "bottomRight"
        });
    };
    const cart = useSelector(state => state.userCart.cart);

    const majorSpinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [majorPageSpinner, setMajorPageSpinner] = useState(true);
    const [salesMetric, setSalesMetric] = useState({});

    const jwt = localStorage.konnect_token;

    // wise9ja process starts

    let jwt_token = localStorage.getItem('konnect_token');

    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            if (profile.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(profile.data.data))
            } else {
                dispatch(fetchData(false))
                dispatch(profileData(false))
            }
        } catch (err) {
            dispatch(fetchData(false))
            dispatch(profileData(false))
        }
    }

    const [page, setPage] = useState(1)
    const [page_size, setPage_size] = useState(10)
    const [userLogInStatus, setUserLogInStatus] = useState(false);
    const [fixedNav, setFixed] = useState(false);
    const [checkingUserLogInStatus, setCheckingUserLogInStatus] = useState(true);
    const [salesReportData, setSalesReportData] = useState([]);
    const [displayMode, setDisplayMode] = useState("content");
    const [historyData, setHistoryData] = useState({});

    // fetch user wise9ja plan
    const fetchSalesReportData = async () => {
        setMajorPageSpinner(true);
        try {
            let userData = await _fetch_user_sales_report({ page, page_size, search: "", from: 1, to: '' });
            setSalesReportData(userData.data.data);
            setMajorPageSpinner(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
            setMajorPageSpinner(false);
        }
    }
    const fetchSalesReportMetricData = async () => {
        setMajorPageSpinner(true);
        try {
            let userData = await _fetch_user_sales_report_metric();
            setSalesMetric(userData.data.data);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    Navigate('/signin');
                }
                openNotificationWithIcon('error', err.response.data.message);
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    React.useEffect(() => {
        if (jwt) {
            getProfile();
        }
    }, [dispatch, token, jwt_token])

    useEffect(() => {
        if (jwt) {
            fetchSalesReportData();
            fetchSalesReportMetricData();
            setUserLogInStatus(true);
            setCheckingUserLogInStatus(false);
        } else {
            setUserLogInStatus(false);
            setCheckingUserLogInStatus(false);
        }
        window.addEventListener('scroll', () => {
            const offset = window.scrollY;
            if (offset > 20) {
                setFixed(true);
            }
            else {
                setFixed(false);
            }
        })
    }, [])

    const setDisplayModeToContent = () => {
        setDisplayMode("content");
    }

    const handleHistoryDisplay = history => {
        setDisplayMode("history");
        setHistoryData(history);
    }

    return (
        <React.Fragment>
            {
                displayMode === "content" ?
                    <div className="wise9ja-content">
                        {
                            checkingUserLogInStatus ?
                                <div>
                                    <TopNav title="Sales Report" />
                                    <div className="center-content">
                                        <div className="center-content-text">
                                            <Spin indicator={majorSpinnerIcon} />
                                        </div>
                                    </div>
                                </div>
                                :
                                userLogInStatus ?
                                    <div>
                                        <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
                                            <div className="homepage wise9ja-disp">
                                                <div className="homepage-history-header">
                                                    <TopNav title="Sales Report" />
                                                    <div className="contain mt_5">
                                                        <div className="grid-2 content-grid-cover">
                                                            <div className="content-summary-box _1">
                                                                <div className="content-summary-info">
                                                                    <h4><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={Math.floor(+salesMetric?.order_income).toFixed(2)} /></h4>
                                                                    <p>My Income(NGN)</p>
                                                                </div>
                                                            </div>
                                                            <div className="content-summary-box _2">
                                                                <div className="content-summary-info">
                                                                    <h4><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={salesMetric.so_no} /></h4>
                                                                    <p>Sales Outlet</p>
                                                                </div>
                                                            </div>
                                                            <div className="content-summary-box _3">
                                                                <div className="content-summary-info">
                                                                    <h4><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={Math.floor(+salesMetric?.so_order_amount).toFixed(2)} /></h4>
                                                                    <p>Order(NGN)</p>
                                                                </div>
                                                            </div>
                                                            <div className="content-summary-box _4">
                                                                <div className="content-summary-info">
                                                                    <h4><NumberFormat thousandSeparator={true} prefix={''}
                                                                        className="numeric" displayType={'text'} value={salesMetric.so_order_no} /></h4>
                                                                    <p>Order</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="hero-sect">
                                                    <div className="contain">
                                                        <div className="extra-data-message mt_3">
                                                            <Link to={AllAppRoutes.start_earning}>
                                                                <div className="extra-data-content">
                                                                    <h3>Refer food vendors</h3>
                                                                    <div>
                                                                        <p>& earn up to NGN 100,000 weekly</p>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <div className="extra-div _1"></div>
                                                            <div className="extra-div _2"></div>
                                                            <div className="extra-div _3"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="content-display-content">
                                                    <div className="contain">
                                                        <div className="wise9ja-action-bar">

                                                        </div>
                                                        <div className="mt_5 history-data-detail">
                                                            <h3 className="tile-header">Food vendors history</h3>
                                                            <Divider style={{ margin: "20px 0px" }} />
                                                            <div className="contai">
                                                                {
                                                                    !majorPageSpinner ?
                                                                        salesReportData.length ?
                                                                            salesReportData.map((history, index) => (
                                                                                <div key={index}
                                                                                    onClick={() => handleHistoryDisplay(history)}
                                                                                    className="first-history-disp current-history-display">
                                                                                    <div className="disp-category">
                                                                                        <h3>{history?.fullname?.split(" ")[0]?.slice(0, 1).toUpperCase()}{history?.fullname?.split(" ")[1]?.slice(0, 1).toUpperCase()}</h3>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h3>{history.fullname}</h3>
                                                                                        <ul>
                                                                                            <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.DATE_HUGE)}</li>
                                                                                            <li>{DateTime.fromISO(history.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE)}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h3 className="history-amount"><span className="currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                            className="numeric" displayType={'text'} value={(+history?.so_order_amount).toFixed(2)} /></h3>
                                                                                        <p className="statu">{history?.so_order_no} {+history?.so_order_no === 1 ? "order" : "orders"}</p>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            :
                                                                            <div>
                                                                                <div className="empty-data">
                                                                                    <div>
                                                                                        <img src={EmptyGIF} alt="failure gif" />
                                                                                        <p>No data found</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Spin>
                                    </div>
                                    :
                                    <div>
                                        <div className="small-page-title contain">
                                            <div className="grid-flex">
                                                <div
                                                    onClick={() => Navigate(-1)}>
                                                    <ArrowLeftIcon />
                                                </div>
                                                <div></div>
                                                <NotificationIcon />
                                            </div>
                                        </div>
                                        <div className="center-content">
                                            <div className="center-content-text">
                                                <img src={FolderIcon} alt="folder" />
                                                <h4>Login Required</h4>
                                                <p>You need to login to access this route. Sign-in and get richer daily with Wise9ja.</p>
                                                <div>
                                                    <div className="">
                                                        <Link className="btn-base" to={AllAppRoutes.signin}>Login now</Link>
                                                    </div>
                                                    {/* <div className="mt_1">
                                            <Link className="btn-blank" to={AllAppRoutes.how_wise9ja_works}>How Wise9ja Works</Link>
                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                        <FooterDisplay />
                    </div>
                    :
                    <SalesReportHistoryPage historyData={historyData} displayModeControl={setDisplayModeToContent} />
            }
        </React.Fragment >
    )
}

export default SalesReportPage; 