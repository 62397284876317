import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin, Input, notification, Select, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const UpdateProfilePic = () => {
  const Navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageUpload, setImageUpload] = useState({});
  const [loading, setLoading] = useState(false);
  const [displayPageLoader, setDisplayPageLoader] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: "",
      description: message,
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    // return isJpgOrPng && isLt2M;
    return false;
  };

  const handleChange = (info) => {
    setImageUpload(info.file);
    const urlPath = URL.createObjectURL(info.file);
    setImageUrl(urlPath);
  };

  const uploadNewBlog = async (e) => {
    setDisplayPageLoader(true);
    try {
      let formBox = new FormData();
      formBox.append("file", imageUpload);
      let addNewData = {};
      //   let addNewData = await _add_new_blog(formBox);
      if (!addNewData?.data?.success) {
        openNotificationWithIcon("error", addNewData?.data?.message);
        setDisplayPageLoader(false);
      } else {
        openNotificationWithIcon("success", "Data saved successfully");
        // Navigate(AppRoutes.blogs);
      }
    } catch (err) {
      setDisplayPageLoader(false);
      openNotificationWithIcon(
        "error",
        "An error occurred while saving data. Please reload page to try again"
      );
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div>
      <div className="long-display">
        <div>
          <Upload
            name="avatar"
            multiple={false}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl?.length ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        <button className="btn btn-main">Update Profile Picture</button>
      </div>
    </div>
  );
};

export default UpdateProfilePic;
